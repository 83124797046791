import { hasAdminAccess, hasOwnerAccess, isAdminAccount } from "../../util/AccountUtils";

class AccountUtilService {
    static userNeedsTosConfirmation = (user: {
        type: String,
        userType: String,
        hasAcceptedCurrentTos: boolean }) : Object => {

        return user
            && !isAdminAccount(user)
            && !hasAdminAccess(user)
            && hasOwnerAccess(user)
            && !user.hasAcceptedCurrentTos
    }
}

export default AccountUtilService
