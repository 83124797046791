import React, {useRef, useCallback, useMemo} from "react";
import { Grid } from '@mui/material'
import { SortFilter } from '@securspace/securspace-ui-kit'
import moment from 'moment'
import { sortByFields } from './data'
import { DateFormats } from '../../constants/securspace-constants';
import {withSnackbar} from "../../hocs/withSnackbar";
import useUrlQueryParams from "../../../hooks/useUrlQueryParams";
import useAllLocationNames from "../../../hooks/components/location/useAllLocationNames";

const SupplierInvoicesFilter = React.memo(({
  setSelectedLocation,
  setStartDate,
  setEndDate,
  setBookingNumber,
  setInvoiceNumber,
  setInvoiceType,
  setStatus,
  setSortBy,
  setSortDir,
  snackbarShowMessage
}) => {

  const locations = useAllLocationNames(snackbarShowMessage);
  const { invoiceNumber, startDate } = useUrlQueryParams();
  const initialRender = useRef(true);

  const handleCallback = useCallback((e) => {
    if (!initialRender.current) {
      setSelectedLocation(e.location);
      setStartDate(e.startDate)
      setEndDate(e.endDate)
      setBookingNumber(e.filters?.find(item => item.name === "bookingNumber")?.value);
      setInvoiceNumber(e.filters?.find(item => item.name === "transactionNumber")?.value);
      setInvoiceType(e.selectFilters?.find(item => item.name === "transactionType")?.value);
      setStatus(e.selectFilters?.find(item => item.name === "status")?.value);
      setSortBy(e.sortBy)
      setSortDir(e.sortDir)
    }
    else {
      initialRender.current = false;
    }
  }, [setSelectedLocation, setStartDate, setEndDate, setBookingNumber, setInvoiceNumber, setInvoiceType, setStatus, setSortBy, setSortDir]);

  const filterByOptions: { label: string, value: string, name: string}[] = useMemo(() => {
    return [
      {
        label: "Booking Number",
        value: "",
        name: "bookingNumber",
      },
      {
        label: "Invoice Number",
        value: invoiceNumber || "",
        name: "transactionNumber",
      }
    ];
  }, [invoiceNumber]);

  const lastMonthDate = moment().add(-1, 'months').format(DateFormats.DAY).toString();
  const todayDate = moment().format(DateFormats.DAY).toString();

  return (
    <Grid container mb={4} flexDirection={'column'}>
      <SortFilter
        filterCallback={handleCallback}
        sortByOption={sortByFields}
        defaultEndDate={todayDate}
        defaultStartDate={startDate || lastMonthDate}
        locations={locations}
        filterByOption={filterByOptions}
        defaultSortBy={'transactionNumber'}
        sortDir={'desc'}
        locationOption={[]}
      />
    </Grid>
  )
})

export default withSnackbar(SupplierInvoicesFilter);
