import React, { useState, useRef } from 'react';
import { Grid, FormControlLabel, Switch, Tooltip, Button, Box } from '@mui/material';
import PlacesAutocompleteInputMui from '../placesAutocomplete/PlacesAutocompleteInputMui';
import InfoIcon from '@mui/icons-material/Info';
import useClickOutsideRef from '../../hooks/useClickOutsideRef';
import PropTypes from "prop-types";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchFilter from '../SearchFilter';
import {useStyles} from "./styles";

const tooltipStyles = {

    tooltip: {
        sx: {
            lineHeight: '1.5em'
        }
    }

}

const SearchForm = (props) => {
    const {
        onSearchSubmit,
        onSearchValueChange,
        onInstantApprovalChange,
        onRequestApprovalChange,
        onLiveLocationsChange,
        searchValue,
        instantApprovalValue,
        requestApprovalValue,
        onlyLiveLocations,
        showAdminOnlyOptions,
        showFilter,
        setShowFilter,
        selectedEquipmentType,
        setSelectedEquipmentType,
        selectedLocationFeatures,
        setSelectedLocationFeatures
    } = props;

    const [selectedPacItem, setSelectedPacItem] = useState({});
    const [showPac, setShowPac] = useState(false);

    const containerRef = useRef(null);
    const classes = useStyles({ showPac: showPac });

    const handleSearchInputChange = (event) => {
        const value = event.target.value;
        onSearchValueChange(value);
    };

    const handleCheckChange = (event) => {
        const { name, checked } = event.target;
        switch (name) {
            case 'instant_approval':
                onInstantApprovalChange(checked);
                break;
            case 'request_approval':
                onRequestApprovalChange(checked);
                break;
            case 'only_live_locations':
                onLiveLocationsChange(checked);
                break;
            default:
                break;
        }
    };

    const handleSelectPac = (pacItem) => {
        setSelectedPacItem(pacItem);
    };

    const handleClickPac = (pacItem) => {
        setShowPac(false);
        onSearchSubmit(pacItem);
    };

    const handleCancelIconClick = () => {
        onSearchValueChange('');
    };

    const handleOnSearchSubmit = (event) => {
        event.preventDefault();
        if (!selectedPacItem?.place_id) return;
        setShowPac(false);
        onSearchSubmit(selectedPacItem);
    };

    const handleOnFocus = () => {
        setShowPac(true);
    };

    const handleKeyUp = (event) => {
        if (event.key === 'Enter') event.target.blur();
    };

    useClickOutsideRef(containerRef, () => setShowPac(false));

    const instantApprovalCaption = "Search by locations that offer immediate access to book spaces.";
    const requestSpaceCaption = "Search by locations that require approval prior to booking. Receive a response within 30 minutes of a request.";
    const liveLocationsCaption = "Search by locations that are currently live and available to book.";

    return (
        <Grid component='form' className={`${showAdminOnlyOptions ? classes.adminFilterContainer : classes.search_container} search_form`} onSubmit={handleOnSearchSubmit} container>
            <Grid item className={`${classes.search_bar_container} ss-hide-border`}>
                <Grid container item ref={containerRef} onFocus={handleOnFocus}>
                    <div className={classes.pac_input_wrapper}>
                        <PlacesAutocompleteInputMui
                            onChange={handleSearchInputChange}
                            name="pacSearch"
                            value={searchValue}
                            onCancelIconClick={handleCancelIconClick}
                            selectedPacItem={selectedPacItem}
                            handleClickPac={handleClickPac}
                            handleSelectPac={handleSelectPac}
                            containerRef={containerRef}
                            showPac={showPac}
                            handleKeyUp={handleKeyUp}
                        />
                    </div>
                </Grid>
            </Grid>
            <Grid item className={classes.filter_container} container alignItems='center' justifyContent='space-between' xs={12} md={6}>
                <Grid container item justifyContent="flex-start" className={classes.filter_options_container}>
                    <Grid item container className={classes.filterBox} alignItems='center'>
                        <FormControlLabel
                            label="Instant Approval"
                            control={<Switch color="secondary" name="instant_approval" checked={instantApprovalValue} onChange={handleCheckChange} className={classes.approvalContainer} />}
                        />
                        <Tooltip componentsProps={tooltipStyles} arrow title={instantApprovalCaption} >
                            <InfoIcon color="secondary" />
                        </Tooltip>
                    </Grid>
                    <Grid item container alignItems='center' className={classes.filterBox}>
                        <FormControlLabel
                            label="Request Approval"
                            control={<Switch color="secondary" name="request_approval" checked={requestApprovalValue} onChange={handleCheckChange} className={classes.approvalContainer} />}
                        />
                        <Tooltip componentsProps={tooltipStyles} arrow title={requestSpaceCaption}>
                            <InfoIcon color="secondary" />
                        </Tooltip>
                    </Grid>
                    {
                        showAdminOnlyOptions && <Grid item container alignItems='center' className={classes.filterBox}>
                            <FormControlLabel
                                label="Only Live Locations"
                                control={<Switch color="secondary" name="only_live_locations" checked={onlyLiveLocations} onChange={handleCheckChange} className={classes.approvalContainer} />}
                            />
                            <Tooltip componentsProps={tooltipStyles} arrow title={liveLocationsCaption}>
                                <InfoIcon color="secondary" />
                            </Tooltip>
                        </Grid>
                    }
                </Grid>
                <Grid item className={classes.filterButtonContainer}>
                    <Button onClick={() => setShowFilter(!showFilter)} className={classes.filterButton} color='secondary' variant='text' startIcon={<FilterAltIcon fontSize='md' color='secondary' />}>
                        {showFilter ? `Hide` : `Show`} Filters
                    </Button>
                </Grid>
            </Grid>
            {
                showFilter && <Box className={classes.filter} component='div'>
                    <SearchFilter selectedEquipmentType={selectedEquipmentType}
                        setSelectedEquipmentType={setSelectedEquipmentType}
                        selectedLocationFeatures={selectedLocationFeatures}
                        setSelectedLocationFeatures={setSelectedLocationFeatures} />
                </Box>
            }
        </Grid>
    );
};

SearchForm.propTypes = {
    onSearchSubmit: PropTypes.func.isRequired,
    onSearchValueChange: PropTypes.func.isRequired,
    onInstantApprovalChange: PropTypes.func.isRequired,
    onRequestApprovalChange: PropTypes.func.isRequired,
    searchValue: PropTypes.string.isRequired,
    instantApprovalValue: PropTypes.bool.isRequired,
    requestApprovalValue: PropTypes.bool.isRequired,
    onlyLiveLocations: PropTypes.bool.isRequired,
    showAdminOnlyOptions: PropTypes.bool.isRequired,
    showFilter: PropTypes.bool.isRequired,
    setShowFilter: PropTypes.func.isRequired,
    selectedEquipmentType: PropTypes.array.isRequired,
    setSelectedEquipmentType: PropTypes.func.isRequired,
    selectedLocationFeatures: PropTypes.array.isRequired,
    setSelectedLocationFeatures: PropTypes.func.isRequired,
};

export default SearchForm;
