import React, {useState} from 'react';
import type {Booking} from "../../types/Booking";
import {Box} from "@mui/material";
import {
  BookingStatus,
  BookingStatusesEligibleForCancellation,
  BookingStatusesEligibleForPaymentMethodUpdate,
  DateFormats
} from "../constants/securspace-constants";
import {cancelBooking, cancelBookingSubscription} from "../../views/requests/booking-requests";
import Busy from "../Busy";
import SimpleConfirmationDialog from "../SimpleConfirmationDialog";
import {withSnackbar} from "../hocs/withSnackbar";
import UpdatePaymentMethodModal from "./modals/UpdatePaymentMethodModal";
import moment from "moment";
import type {Account} from "../../types/Account";
import {getErrorMessageForNonStandardAndStandardResponse} from "../../util/NetworkErrorUtil";
import BookingCardList from "./BookingCardList";
import TextBox from './TextBox';
import { startDateLessThan24Hours } from '../../util/BookingUtil';

const ActiveBookings = (props: { bookings: Booking[], account: Account, onActionSuccess: () => void, snackbarShowMessage: () => void , headerCells:[{id: String, label: String}]}) => {
  const {bookings = [], account, onActionSuccess, snackbarShowMessage, headerCells} = props;
  const [bookingToEdit: Booking, setBookingToEdit] = useState(null);
  const [showCancelBookingConfirmation, setShowCancelBookingConfirmation] = useState(false);
  const [showEndMonthlyBookingConfirmation, setShowEndMonthlyBookingConfirmation] = useState(false);
  const [showUpdatePaymentMethodDialog, setShowUpdatePaymentMethodDialog] = useState(false);

  const handleEndBooking = (booking: Booking) => {
    if (booking.bookingSubscriptionStatus === "ACTIVE" &&
      BookingStatusesEligibleForCancellation.includes(booking.status)) {
      setBookingToEdit(booking);
      setShowEndMonthlyBookingConfirmation(true);
    } else if (booking.status === BookingStatus.PENDING || ((booking.status === BookingStatus.APPROVED || booking.status === BookingStatus.PROCESSING_ACH_PAYMENT) && booking.frequency !== "RECURRING")) {
      if (!startDateLessThan24Hours(booking)) {
        setBookingToEdit(booking);
        setShowCancelBookingConfirmation(true);
      } else {
        snackbarShowMessage("This booking is not eligible for cancellation because it is less than 24 hours before the booking start date.", "warning");
      }
    } else {
      // Remove this when we can programmatically disable the end bookings button
      snackbarShowMessage("This booking is not eligible for cancellation.", "warning");
    }
  };

  const handleCloseCancelBookingConfirmation = () => {
    setShowCancelBookingConfirmation(false);
    setBookingToEdit(null);
  };

  const handleCloseEndMonthlyBookingConfirmation = () => {
    setShowEndMonthlyBookingConfirmation(false);
    setBookingToEdit(null);
  };

  const handleBookingCancellation = () => {
    Busy.set(true);
    cancelBooking({id: bookingToEdit.id}).then((response) => {
      const cancelledBooking = response.body;
      snackbarShowMessage(`Successfully cancelled booking ${cancelledBooking.orderNumber}`, "success");
      handleCloseCancelBookingConfirmation();
      onActionSuccess();
    }).catch((error) => {
      snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(error), "error");
    }).finally(() => {
      Busy.set(false);
    });
  };

  const handleMonthlyBookingCancellation = () => {
    Busy.set(true);
    cancelBookingSubscription({id: bookingToEdit.id}).then((response) => {
      const canceledBooking = response.body;
      snackbarShowMessage(`Subscription for booking ${canceledBooking.orderNumber} will end on ${moment(canceledBooking.endDate).format(DateFormats.DAY)}`, "success");
      handleCloseEndMonthlyBookingConfirmation();
      onActionSuccess();
    }).catch((error) => {
      snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(error), "error");
    }).finally(() => {
      Busy.set(false);
    });
  };

  const handleUpdateBooking = (booking: Booking) => {
    if (booking.active &&
      BookingStatusesEligibleForPaymentMethodUpdate.includes(booking.status)) {
      setBookingToEdit(booking);
      setShowUpdatePaymentMethodDialog(true);
    } else {
      // Remove this when we can programmatically disable the update payment method button
      snackbarShowMessage("This booking is not eligible for payment method update.", "warning");
    }
  };

  const handleCloseUpdatePaymentMethodDialog = () => {
    setShowUpdatePaymentMethodDialog(false);
    setBookingToEdit(null);
  };

  const handleUpdatePaymentMethod = () => {
    handleCloseUpdatePaymentMethodDialog();
    onActionSuccess();
  };

  return (
    <Box>
       <TextBox title="An approved booking that is current or in the future." />
      <BookingCardList bookings={bookings} onEnd={handleEndBooking} onUpdate={handleUpdateBooking} headerCells={headerCells}/>
      <SimpleConfirmationDialog
        onClose={handleCloseCancelBookingConfirmation}
        onConfirm={handleBookingCancellation}
        open={showCancelBookingConfirmation}
        title={"Cancel Booking"}
        body={"Are you sure you want to cancel this booking?"}
      />
      <SimpleConfirmationDialog
        onClose={handleCloseEndMonthlyBookingConfirmation}
        onConfirm={handleMonthlyBookingCancellation}
        open={showEndMonthlyBookingConfirmation}
        title={"End Monthly Booking"}
        body={"Are you sure you want to end this monthly booking?"}
      />
      <UpdatePaymentMethodModal
        open={showUpdatePaymentMethodDialog}
        onClose={handleCloseUpdatePaymentMethodDialog}
        booking={bookingToEdit}
        account={account}
        onSuccess={handleUpdatePaymentMethod}
      />
    </Box>
  );
};

export default withSnackbar(ActiveBookings);
