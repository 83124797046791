import React from 'react';
import PropTypes from 'prop-types';
import {Divider, Grid, Theme, Typography, useMediaQuery} from "@mui/material";
import {makeStyles} from '@mui/styles';
import EditCompanyProfile from "./EditCompanyProfile";
import AdminInfo from "./AdminInfo";
import { hasAdminAccess } from "../../util/AccountUtils";

const useStyles: (theme: Theme) => {
  divider: CSSStyleSheet
} = makeStyles(theme => ({
  divider: {
    // horizontal divider for mobile
    margin: '2.14rem 0',
    width: '100%',
    height: 'auto !important',
    // vertical divider for desktop
    [theme.breakpoints.up('md')]: {
      margin: '0 2.14rem',
      width: '0.07rem'
    },
  },
}));

const CompanyProfile = ({ account, handleLogout, handleAccountUpdated }) => {

  const isMd = useMediaQuery(theme => theme.breakpoints.up('md'));
  const classes = useStyles();

  return (
    <Grid container component={'article'}>
      <Grid item xs={12} md={5}>
        <Typography variant={'h5'} component={'h1'} color={'textPrimary'}>Company Details</Typography>
        {hasAdminAccess(account) &&
          <AdminInfo
            account={account}
            handleLogout={handleLogout}
            handleAccountUpdated={handleAccountUpdated}
          />
        }
      </Grid>
      <Divider orientation={isMd ? 'vertical' : 'horizontal'} flexItem={isMd} className={`ss-divider ${classes.divider}`} />
      {
        account && <Grid item xs={12} md>
          <EditCompanyProfile
            account={account}
            handleLogout={handleLogout}
            handleAccountUpdated={handleAccountUpdated}
          />
        </Grid>
      }
    </Grid>
  );
};

CompanyProfile.propTypes = {
  account: PropTypes.object.isRequired,
  handleLogout: PropTypes.func.isRequired,
  handleAccountUpdated: PropTypes.func.isRequired,
};

export default CompanyProfile;
