import React, {useState} from 'react';
import {InfoCard} from "@securspace/securspace-ui-kit";
import {getInvoiceActionsItems} from "../InvoiceActions";
import {headCells} from './data';
import {formatCurrencyValue} from "../../../../../util/PaymentUtils";
import {
  BookingStatus,
  BookingTransactionStatusTypeLabel, ChargeTypes,
  ServiceFeeTypeLabel,
} from "../../../../constants/securspace-constants";
import {
  BuyerInvoiceStatusGroup,
  SubscriptionType,
  SupplierInvoiceStatusGroup
} from "../../../../constants/booking-transaction-constants"
import type {Account} from "../../../../../types/Account";

type InvoiceCardProps = {
  account: Account,
  invoice: { transactionId: string },
  onInvoiceAction: (payload: any) => void
};

const InvoiceCard: (props: InvoiceCardProps) => JSX.Element = ({
                                                                 account,
                                                                 invoice,
                                                                 onInvoiceAction
                                                               }) => {
  const [expanded, setExpanded] = useState(false);

  const formattedInvoice = {
    ...invoice,
    bookingStatus: BookingStatus[invoice.bookingStatus] || invoice.bookingStatus,
    buyerAmount: formatCurrencyValue(invoice.buyerAmount, true),
    buyerTransactionStatus: BuyerInvoiceStatusGroup[invoice.buyerTransactionStatus] ? BuyerInvoiceStatusGroup[invoice.buyerTransactionStatus].label : invoice.buyerTransactionStatus,
    locationAddress: `${invoice.locationAddressLine1}, ${invoice.locationCity}, ${invoice.locationState} ${invoice.locationZip}`,
    locationIsLive: invoice.locationIsLive ? 'True' : 'False',
    paymentProcessorFees: formatCurrencyValue(invoice.paymentProcessorFees, true),
    securspaceAmount: formatCurrencyValue(invoice.securspaceAmount, true),
    securspaceFees: formatCurrencyValue(invoice.securspaceFees, true),
    serviceFeeType: ServiceFeeTypeLabel[invoice.serviceFeeType] || invoice.serviceFeeType,
    status: BookingTransactionStatusTypeLabel[invoice.status] || invoice.status,
    supplierAmount: formatCurrencyValue(invoice.supplierAmount, true),
    supplierTransactionStatus: SupplierInvoiceStatusGroup[invoice.supplierTransactionStatus] ? SupplierInvoiceStatusGroup[invoice.supplierTransactionStatus].label : invoice.supplierTransactionStatus,
    supplierPayoutOnHold: invoice.supplierPayoutOnHold ? 'True' : 'False',
    supplierSubscriptionType: SubscriptionType[invoice.supplierSubscriptionType]?.label || invoice.supplierSubscriptionType,
    transactionTaxAmount: formatCurrencyValue(invoice.transactionTaxAmount, true),
    transactionType: ChargeTypes[invoice.transactionType] || invoice.transactionType,
  };

  return (
    <InfoCard
      data={formattedInvoice}
      actionItems={getInvoiceActionsItems(account, invoice, onInvoiceAction)}
      headCells={headCells}
      collapse={expanded}
      handleToggle={() => setExpanded(!expanded)}
      links={{bookingNumber: {baseUrl: '/admin-bookings', queryParam: 'bookingNumber'}}}
    />
  );
};

export default InvoiceCard;