import React from "react";
import { AccountType } from "../../components/constants/securspace-constants";
import { Grid } from "@mui/material";
import { SwitchToCustomerButton, SwitchToPartnerButton } from "./switchAccountTypeButton/SwitchButton";
import { PartnerInfo, CustomerInfo } from "./ConditionalInfoColumns";
import './infoColumn.css';
import PropTypes from 'prop-types';
import { isBuyerAccountType } from "../../util/AccountUtils";

const InfoColumn = (props) => {
    const {accountType} = props;

    return (
        <div className="ss-sign-up-info-container">
            {
                isBuyerAccountType(accountType) ?
                    <CustomerInfo />
                    : <PartnerInfo />
            }
            <Grid item>
                {
                    isBuyerAccountType(accountType) ?
                        <SwitchToPartnerButton />
                    :
                        <SwitchToCustomerButton />
                }
            </Grid>
        </div>
    );
};

InfoColumn.propTypes = {
    accountType: PropTypes.oneOf([AccountType.BUYER, AccountType.SUPPLIER]),
};

export default InfoColumn;