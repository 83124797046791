import React, {useEffect, useState, useMemo} from 'react';
import type {Booking} from "../../types/Booking";
import type {Account} from "../../types/Account";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Theme, Grid, FormControl, Link, FormLabel} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {requestPaymentMethods} from "../../paymentMethods/request/payment-method-requests";
import UpdatePaymentMethodSuccessBody from "./UpdatePaymentMethodSuccessBody";
import {getRentalAgreementURL} from "../../../util/BookingUtil";
import {requestCompleteBooking, updatePaymentMethod} from "../../bookedSpaces/request/booked-spaces-requests";
import Busy from "../../Busy";
import type {PaymentMethod} from "../../../types/PaymentMethod";
import {BankAccountStatus} from "../../constants/securspace-constants";
import {withSnackbar} from "../../hocs/withSnackbar";
import {getErrorMessageForNonStandardAndStandardResponse} from "../../../util/NetworkErrorUtil";
import PaymentMethodSelect from "./PaymentMethodSelect";
import { PaymentType } from "../../constants/securspace-constants";
import RentalAgreementCheckbox from "../../RentalAgreementCheckbox";

const useStyles: (theme: Theme) => {link: CSSStyleSheet} = makeStyles((theme) => ({
  link: {
    fontSize: "inherit",
    color: theme.palette.secondary.main,
    textDecoration: 'none',
  }
}));

const UpdatePaymentMethodModal = (props: {
  open: boolean,
  onClose: () => void,
  booking: Booking,
  account: Account,
  onSuccess: () => void,
  snackbarShowMessage: (message: string) => void,
}) => {
  const {open, onClose, booking, account, onSuccess, snackbarShowMessage} = props;
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(null);
  const [agreeToTermsChecked, agreeToTermsCheckedSet] = useState(false);
  const [paymentUpdated, setPaymentUpdated] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (open && account) {
      Busy.set(true);
      const onSuccess = (paymentMethods) => {
        setPaymentMethods(paymentMethods.filter((paymentMethod: PaymentMethod) => {
          return paymentMethod.dwollaStatus !== BankAccountStatus.UNVERIFIED && paymentMethod.stripeStatus !== BankAccountStatus.UNVERIFIED;
        }));
        Busy.set(false);
      };
      const onError = (error) => {
        Busy.set(false);
        snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(error), 'error');
      };
      requestPaymentMethods(account.id, account.userType, onSuccess, onError);
    }
  }, [open, account, snackbarShowMessage]);

  const resetForm = () => {
    setSelectedPaymentMethodId(null);
    agreeToTermsCheckedSet(false);
    setPaymentUpdated(false);
  };

  const onConfirm = () => {
    setPaymentUpdated(false);

    const bookingData = {
      id: booking.id,
      buyerAccountId: account.id,
      paymentMethodId: selectedPaymentMethodId,
      tosDocUrl: getRentalAgreementURL(booking.supplierLegalAgreementFileName),
    };
    Busy.set(true);
    updatePaymentMethod(bookingData).then((response) => {
      const updatedBooking = response.body;
      updatedBooking.buyerAccountId = updatedBooking.buyerAccount.id;
      updatedBooking.supplierAccountId = updatedBooking.supplierAccount.id;
      requestCompleteBooking(updatedBooking).then(() => {
        setPaymentUpdated(true);
        Busy.set(false);
      });
    }).catch(() => {
      Busy.set(false);
    });
  };

  const handleFormChange = (event) => {
    const {name, value, checked} = event.target;
    switch (name) {
      case 'paymentMethod':
        setSelectedPaymentMethodId(value);
        break;
      case 'agreeToTerms':
        agreeToTermsCheckedSet(checked);
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    if (paymentUpdated) {
      onSuccess();
    } else {
      onClose();
    }
    resetForm();
  };

  const displayProcessingFeesWarning = useMemo(() => {
    const selectedPaymentMethod = paymentMethods.find((paymentMethod) => paymentMethod.id === selectedPaymentMethodId);
    return selectedPaymentMethod?.type === PaymentType.CARD;
  }, [paymentMethods, selectedPaymentMethodId]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id={'ss-confirm-title'} variant={'h6'}>
        Update Payment Method
      </DialogTitle>
      <DialogContent id={'ss-confirm-description'}>
        {
          paymentUpdated ?
            <UpdatePaymentMethodSuccessBody/>
          :
            <FormControl className="ss-book-space-form ss-form ss-block no-padding">
              <Grid container className="modal-body">
                <Grid container id="selectPaymentMethod" flexDirection="column">
                  <FormLabel className="ss-summary">
                    1. Select Payment Method
                  </FormLabel>
                    <PaymentMethodSelect
                      paymentMethods={paymentMethods}
                      onChange={handleFormChange}
                      value={selectedPaymentMethodId}
                    />
                  {
                    displayProcessingFeesWarning ?
                      <p className="help-block">
                        <span className="glyphicon glyphicon-info-sign" aria-hidden="true" />
                        Additional credit card processing fee applies
                      </p>
                    :
                      ''
                  }

                  <FormLabel className="ss-summary" id="tosAgreementLabel">
                    2. Accept <Link to={'/terms-of-use'} target={"_blank"} className={classes.link}>Terms of Service</Link> Agreement
                  </FormLabel>
                  <RentalAgreementCheckbox name={"agreeToTerms"} checked={agreeToTermsChecked} onChange={handleFormChange} required />
                </Grid>
              </Grid>
            </FormControl>
        }
      </DialogContent>


      <DialogActions className={classes.backgroundGrey100}>
        <Grid container justifyContent={"center"}>
          {
            paymentUpdated ?
              <Button variant="contained" onClick={handleClose}>
                Done
              </Button>
            :
              <>
                <Button variant="text" onClick={handleClose} >
                  Cancel
                </Button>
                <Button variant="contained" onClick={onConfirm} disabled={!selectedPaymentMethodId || !agreeToTermsChecked}>
                  Next
                </Button>
              </>
          }
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default withSnackbar(UpdatePaymentMethodModal);