import React, { useContext, useEffect } from 'react'
import { Routes, useLocation } from "react-router-dom"
import { StyleRoot } from 'radium'
import { AppContext } from "../context/app-context";
import { requestReadSupplierPendingBooking, requestReadBuyerPendingBooking } from "../components/bookedSpaces/request/booked-spaces-requests";
import Busy from "../components/Busy";
import ToastContainerWrapper from "../components/toast/ToastContainerWrapper";
import AccountContent from "./content-routes/AccountContent"
import AdminContent from "./content-routes/AdminContent"
import BuyerContent from "./content-routes/BuyerContent"
import GateManagementContent from "./content-routes/GateManagementContent"
import SupplierContent from "./content-routes/SupplierContent";
import LandingContent from "./content-routes/LandingContent";
import LoggedInAsHeader from "../components/admin/LoggedInAsHeader";
import Footer from "../components/footer/Footer";
import useHubSpotTracking from '../hooks/useHubSpotTracking';
import './style/secur-space-content.css'
import LocationUserBasedNav from './LocationUserBasedNav';
import {useLDClient} from "launchdarkly-react-client-sdk";
import { inventoryCorrectionCount } from '../components/inventory-correction/requests/inventory-correction-requests';
import { hasOwnerAccess, isBuyerAccount, isSupplierAccount } from "../util/AccountUtils";

export const readSupplierPendingBooking = (user, setPendingApproval) => {
    const { id } = user || {};
    if(id && isSupplierAccount(user) && hasOwnerAccess(user)) {
        requestReadSupplierPendingBooking(id)
            .then((resp) => setPendingApproval(resp.body))
            .catch(() => {})
    }
}

export const readPendingBooking = (user, setPendingAction) => {
    const { id } = user || {};
    if(id && hasOwnerAccess(user)) {
        if (isBuyerAccount(user)) {
            requestReadBuyerPendingBooking(id)
                .then((resp) => setPendingAction(resp.body))
                .catch(() => {})
        } else if (isSupplierAccount(user)) {
            requestReadSupplierPendingBooking(id)
                .then((resp) => setPendingAction(resp.body))
                .catch(() => {})
        }
    }
}

const SecurSpaceContent = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'pageview'
    });
    const appContext = useContext(AppContext);
    const { user, pendingApprovalCount, updatePendingApprovalCount, updatePendingInventoryCount, pendingInventoryCount } = appContext;

    const location = useLocation();
    const ldClient = useLDClient();

    useHubSpotTracking();

    useEffect(() => {
        if (user && location.pathname) {
            readSupplierPendingBooking(user, updatePendingApprovalCount);
            inventoryCorrectionCount(user, updatePendingInventoryCount);
            readPendingBooking(user, updatePendingApprovalCount);
        }
    }, [user, location]);

    useEffect(() => {
        const context = user ? {
            kind: 'user',
            key: user.id,
            email: user.email,
            organization: user.companyName,
            anonymous: false
        } : {kind: 'user', anonymous: true};
        if (ldClient) {
            ldClient.identify(context).catch(error => console.error('Error in LaunchDarkly identify:', error));
        }
    }, [user, ldClient]);

    // This fixes the scroll to Top issue
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        // todo za -- figure out what the purpose of this is?
        <StyleRoot style={{
            position: "relative",
            height: "100%",
            width: "100%"
        }}>
            <div className="secur-space-content">
                <LoggedInAsHeader />
                <LocationUserBasedNav locationPathname={location.pathname} pendingApprovalCount={pendingApprovalCount} pendingInventoryCount={pendingInventoryCount} />
                <div className="secur-space-page-content">
                    <Routes>
                        { AccountContent() }
                        { AdminContent() }
                        { BuyerContent(() => readPendingBooking(user, updatePendingApprovalCount)) }
                        { SupplierContent(() => readSupplierPendingBooking(user, updatePendingApprovalCount)) }
                        { GateManagementContent() }
                        { LandingContent(() => readPendingBooking(user, updatePendingApprovalCount)) }
                    </Routes>
                </div>
                <Footer />
            </div>
            <ToastContainerWrapper />
            <Busy />
        </StyleRoot>
    )
}

export default SecurSpaceContent
