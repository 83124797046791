import React, { useState, useMemo } from 'react';
import { Button, Grid, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Checkbox, FormControlLabel } from "@mui/material";
import { CurrencyInput}  from "../../../../common/CurrencyInput";
import { parseCurrencyValue } from "../../../../../util/PaymentUtils";
import { refundableBookingTransactionStatuses } from "../../../../constants/securspace-constants";
import Busy from "../../../../Busy";
import request from '../../../../../util/SuperagentUtils';
import { CallbackActions } from "../InvoiceActions";

const CreateRequestRefundModal = (props: {
  invoice: {transactionId: string},
  open: boolean,
  onInvoiceAction: (callbackActions: any) => void,
  onClose: (invoice: {transactionId: string}) => void,
}) => {
  const { invoice, open, onInvoiceAction, onClose } = props;
  const [fullRefund, setFullRefund] = useState(true);

  const requestTypeLabels = useMemo(() => {
    return refundableBookingTransactionStatuses.includes(invoice?.status) ? {
      title: "Create a Refund For This Charge",
      submit: "Create a Refund",
      success: "Successfully created refund!  Refund Invoice: "
    } : {
      title: "Request a Refund For This Charge",
      submit: "Request a Refund",
      success: "Successfully submitted refund request! Refund Invoice: "
    };
  } , [invoice?.status]);

  const handleClose = () => {
    onClose();
  };

  const onFullRefundChange = (event) => {
    setFullRefund(event.target.checked);
  }

  const onSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries((formData).entries());

    const url = "api/booking/refund-transaction";
    const payload = {
      transactionNumberToRefund: invoice?.transactionNumber,
      refundAmount: fullRefund ? null : parseCurrencyValue(formJson.refundAmount),
      reasonForRefund: formJson.reason
    };

    Busy.set(true);
    request.post(url).send(payload).then(() => {
      onClose(CallbackActions.SUCCESS, invoice, requestTypeLabels.success + invoice?.transactionNumber);
    }).catch((error) => {
      onInvoiceAction(CallbackActions.ERROR, invoice, `${error}`);
    }).finally(() => {
      Busy.set(false);
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit
      }}
    >
      <DialogTitle>
        {requestTypeLabels.title}
      </DialogTitle>
      <DialogContent>
        <TextField
          required
          margin="dense"
          id="outlined-multiline-static"
          label="Multiline"
          name="reason"
          label="Reason for Refund"
          helperText="Enter the reason this refund is being given."
          multiline
          rows={10}
          fullWidth
          variant="standard"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={fullRefund}
              onChange={onFullRefundChange}
            />
          }
          label="Full Refund?" />

        {
          !fullRefund && <>
            <DialogContentText>
              Enter the refund credit amount and reason for refund
            </DialogContentText>
            <CurrencyInput
              prefix="$"
              autoFocus
              required
              margin="dense"
              name="refundAmount"
              label="Refund Amount"
              helperText="Enter the amount to refund for this transaction."
              fullWidth
              variant="standard"
              maxLength={8}
            />
          </>
        }
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={"center"}>
          <Button variant="text" onClick={handleClose} >Cancel</Button>
          <Button variant="contained" type="submit">{requestTypeLabels.submit}</Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
export default CreateRequestRefundModal;