import { SubscriptionType } from "../components/constants/securspace-constants";
import AccountUtilService from "../services/account/AccountUtilService";
import { hasOwnerAccess, hasGateManagerAccess, hasGateClerkAccess, isAdminAccount, isBuyerAccount, isSupplierAccount } from "../util/AccountUtils";

export const getLandingRedirectPathForUser = (user: { userType: String, accountType: String, subscriptionType: String }) : String => {

    let redirectPath;

    if (isSupplierAccount(user)) {
        redirectPath = getLandingRedirectPathForSupplierSubscriptionType(user);
    } else if (isBuyerAccount(user)) {
        redirectPath = "/search";
    } else if (isAdminAccount(user)) {
        redirectPath = "/login-as-account";
    } else {
        redirectPath = "/";
    }

    if(AccountUtilService.userNeedsTosConfirmation(user)) {
        redirectPath = "/confirm-account";
    }

    return redirectPath;
}

const getLandingRedirectPathForSupplierSubscriptionType = (user: { userType: String, subscriptionType: String }) => {
    let redirectPath;

    switch (user?.subscriptionType) {
        case SubscriptionType.MARKETPLACE_ONLY:
            redirectPath = getLandingRedirectPathForMarketplaceOnly(user);
            break
        case SubscriptionType.GMS_PRO:
        case SubscriptionType.GMS_LITE:
            redirectPath = getLandingRedirectPathForGmsSubscription(user);
            break
        default:
            forceLogoutOnInvalidState();

    }

    return redirectPath;
}

const getLandingRedirectPathForMarketplaceOnly = (user: { userType: String }) : String => {
    let redirectPath;

    if (hasOwnerAccess(user)) {
        redirectPath = "/locations-profile";
    } else if (hasGateClerkAccess(user)) {
        redirectPath = "/gms-upgrade";
    } else {
        forceLogoutOnInvalidState();
    }

    return redirectPath;
}

const getLandingRedirectPathForGmsSubscription = (user: { userType: String }) : String => {

    let redirectPath;

    if (hasOwnerAccess(user)) {
        redirectPath = "/locations-profile";
    } else if (hasGateManagerAccess(user)) {
        redirectPath = "/approvals";
    } else if (hasGateClerkAccess(user)) {
        redirectPath = "/check-in";
    } else {
        forceLogoutOnInvalidState();
    }

    return redirectPath;
}

const forceLogoutOnInvalidState = () => {
    window.location.href = "/logout";
}
