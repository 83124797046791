import type {Account} from "../../../../types/Account";
import {fetchDownloadFile} from "../../../../util/FileUtils";
import {hasAdminAccess, hasSuperAdminAccess} from "../../../../util/AccountUtils";
import Busy from "../../../Busy";
import {getErrorMessageForNonStandardAndStandardResponse} from '../../../../util/NetworkErrorUtil';
import {
  BookingTransactionStatusType,
  invoiceCancelStatuses,
  invoiceStatusesThatCanBeCancelled,
  nonRefundableBookingTransactionTypes,
  refundableBookingTransactionStatuses,
  supplierPaidOutBookingTransactionStatuses,
  excludedFromPayoutBookingTransactionStatuses
} from '../../../constants/securspace-constants';
import {
  requestPatchInvoiceStatus,
  requestPutPayoutOnHold,
  requestRetryPayment,
  requestTakePayoutOffHold,
  requestToggleIncludeRefundInPayout,
  requestCompleteRefundWithDeduction,
  requestCompleteRefundWithoutDeduction,
  requestDenyRefund
} from "../../request/invoice-requests";
import { default as DocumentScanner } from '@mui/icons-material/DocumentScanner';
import { default as History } from '@mui/icons-material/History';
import { default as Flaky } from '@mui/icons-material/Flaky';
import { default as DynamicFeed } from '@mui/icons-material/DynamicFeed';
import { default as FolderCopy } from '@mui/icons-material/FolderCopy';


const InvoiceActionTypes = Object.freeze({
  DOWNLOAD: 'DOWNLOAD',
  OPEN_TAB: 'OPEN_TAB',
  DIALOG: 'DIALOG',
  POST_REQUEST: 'POST_REQUEST',
  HANDLER: 'HANDLER',
});

const InvoiceActionsDefaultIcons = Object.freeze({
  [InvoiceActionTypes.DOWNLOAD]: <DocumentScanner />,
  [InvoiceActionTypes.OPEN_TAB]: <FolderCopy />,
  [InvoiceActionTypes.DIALOG]: <DynamicFeed />,
  [InvoiceActionTypes.POST_REQUEST]: <Flaky />,
  [InvoiceActionTypes.HANDLER]: <History />,
});

const CallbackActions = Object.freeze({
  SUCCESS: 'SUCCESS',
  OPEN_DIALOG: 'OPEN_DIALOG',
  ERROR: 'ERROR',
  LOGOUT: 'LOGOUT',
});

const InvoiceActionDialogs = Object.freeze({
  ADD_REFUND_CREDIT: 'ADD_REFUND_CREDIT',
  CANCEL_OTHER: 'CANCEL_OTHER',
  CREATE_REQUEST_REFUND: 'CREATE_REQUEST_REFUND',
});

const downloadItem = (invoice, actionPayload, onInvoiceAction) => {
  try {
    fetchDownloadFile(actionPayload.endpoint(invoice));
  } catch(error) {
    onInvoiceAction(CallbackActions.ERROR, actionPayload.errorMessage, 'error');
  }
}

const openTab = (invoice, actionPayload, onInvoiceAction) => {
  try {
    window.open(actionPayload.endpoint(invoice));
  } catch(error) {
    onInvoiceAction(CallbackActions.ERROR, actionPayload.errorMessage, 'error');
  }
};

const postRequest = (invoice, actionPayload, onInvoiceAction) => {
  Busy.set(true);
  actionPayload.requester(invoice)
    .then(() => {
      onInvoiceAction(CallbackActions.SUCCESS, invoice, actionPayload.successMessage);
    })
    .catch((err) => {
      if (err.response.status === 401) {
        onInvoiceAction(CallbackActions.LOGOUT);
      } else {
        onInvoiceAction(CallbackActions.ERROR, invoice, getErrorMessageForNonStandardAndStandardResponse(error));
      }
    })
    .finally(() => {
      Busy.set(false);
    });
};

const cancelInvoice = (transaction, transactionStatus, onActionSuccess) => {
  const onCancelSuccess = (response) => {
    Busy.set(false);
    onActionSuccess(CallbackActions.SUCCESS, response,`Successfully Cancelled Invoice ${transaction.transactionNumber}`);
  }
  const onCancelError = (error) => {
    Busy.set(false);
    onActionSuccess(CallbackActions.SUCCESS, transaction, error);
    onInvoiceAction(CallbackActions.ERROR, invoice, error);
  }

  Busy.set(true);
  const id = transaction.transactionId;
  requestPatchInvoiceStatus(id, {status: transactionStatus}, onCancelSuccess, onCancelError);
};



const actionItems = [
  {
    test: (account, invoice) => hasAdminAccess(account) && invoice,
    actionType: InvoiceActionTypes.DOWNLOAD,
    actionPayload: {
      endpoint: (invoice) => "/api/invoices/" + invoice?.transactionId + "/pdf",
      errorMessage: "Unable to generate invoice",
    },
    actionItem: {
      label: 'Download Invoice',
    }
  },
  {
    test: (account, invoice) => hasAdminAccess(account) && invoice,
    actionType: InvoiceActionTypes.OPEN_TAB,
    actionPayload: {
      endpoint: (invoice) => "/invoice/" + invoice.transactionNumber,
      errorMessage: "Unable to generate invoice",
    },
    actionItem: {
      label: 'View Invoice',
    }
  },
  {
    test: (account, invoice) => hasAdminAccess(account) && invoice,
    actionType: InvoiceActionTypes.DOWNLOAD,
    actionPayload: {
      endpoint: (invoice) => "/api/overage-daily-report-by-invoice-number/" + invoice?.transactionNumber,
      errorMessage: "Unable to generate inventory log",
    },
    actionItem: {
      label: 'Download Inventory Log',
    }
  },
  {
    test: (account, invoice) =>
        hasAdminAccess(account) &&
        refundableBookingTransactionStatuses.includes(invoice?.status) &&
        !nonRefundableBookingTransactionTypes.includes(invoice?.transactionType) &&
        !invoice.supplierPayoutOnHold,
    actionType: InvoiceActionTypes.POST_REQUEST,
    actionPayload: {
      requester: requestPutPayoutOnHold,
      successMessage: 'Successfully put payout on hold!',
    },
    actionItem: {
      label: 'Put Payout on Hold',
    }
  },
  {
    test: (account, invoice) =>
        hasAdminAccess(account) &&
        refundableBookingTransactionStatuses.includes(invoice?.status) &&
        !nonRefundableBookingTransactionTypes.includes(invoice?.type) &&
        invoice.supplierPayoutOnHold,
    actionType: InvoiceActionTypes.POST_REQUEST,
    actionPayload: {
      requester: requestTakePayoutOffHold,
      successMessage: 'Successfully took payout off hold!',
    },
    actionItem: {
      label: 'Take Payout Off Hold',
    }
  },
  {
    test: (account, invoice) => hasAdminAccess(account) && invoice?.status === BookingTransactionStatusType.CHARGE_PENDING,
    actionType: InvoiceActionTypes.DIALOG,
    actionPayload: {
      dialog: InvoiceActionDialogs.ADD_REFUND_CREDIT,
    },
    actionItem: {
      label: 'Add Refund Credit',
    }
  },
  {
    test: (account, invoice) => hasAdminAccess(account) && invoice.status === BookingTransactionStatusType.CHARGE_PENDING,
    actionType: InvoiceActionTypes.POST_REQUEST,
    actionPayload: {
      requester: requestRetryPayment,
      successMessage: 'Successfully retried charging failed payment!',
    },
    actionItem: {
      label: 'Charge Invoice',
    }
  },
  {
    test: (account, invoice) => hasAdminAccess(account) && invoice.status === BookingTransactionStatusType.PAYMENT_FAILED,
    actionType: InvoiceActionTypes.POST_REQUEST,
    actionPayload: {
      requester: requestRetryPayment,
      successMessage: 'Successfully retried charging failed payment!',
    },
    actionItem: {
      label: 'Retry Failed Payment',
    }
  },
  {
    test: (account, invoice) =>
        hasSuperAdminAccess(account) &&
        invoice?.transferType === 'CHARGE' &&
        refundableBookingTransactionStatuses.includes(invoice?.status),
    actionType: InvoiceActionTypes.DIALOG,
    actionPayload: {
      dialog: InvoiceActionDialogs.CREATE_REQUEST_REFUND,
    },
    actionItem: {
      label: 'Refund...',
    }
  },
  {
    test: (account, invoice) => hasSuperAdminAccess(account) &&
        hasSuperAdminAccess(account) &&
        invoice?.transferType === 'CHARGE' &&
        supplierPaidOutBookingTransactionStatuses.includes(invoice?.status),
    actionType: InvoiceActionTypes.DIALOG,
    actionPayload: {
      dialog: InvoiceActionDialogs.CREATE_REQUEST_REFUND,
    },
    actionItem: {
      label: 'Request Refund...',
    }
  },
  {
    test: (account, invoice) => hasSuperAdminAccess(account) && invoice?.status === BookingTransactionStatusType.REFUND_REQUESTED,
    actionType: InvoiceActionTypes.POST_REQUEST,
    actionPayload: {
      requester: requestCompleteRefundWithDeduction,
      successMessage: 'Successfully completed refund!',
    },
    actionItem: {
      label: 'Complete Refund (Deduct From Next Payout)',
    }
  },
  {
    test: (account, invoice) => hasSuperAdminAccess(account) && invoice?.status === BookingTransactionStatusType.REFUND_REQUESTED,
    actionType: InvoiceActionTypes.POST_REQUEST,
    actionPayload: {
      requester: requestCompleteRefundWithoutDeduction,
      successMessage: 'Successfully completed refund!',
    },
    actionItem: {
      label: 'Complete Refund (DO NOT Deduct From Next Payout)',
    }
  },
  {
    test: (account, invoice) => hasSuperAdminAccess(account) && invoice.status === BookingTransactionStatusType.REFUND_REQUESTED,
    actionType: InvoiceActionTypes.POST_REQUEST,
    actionPayload: {
      requester: requestDenyRefund,
      successMessage: 'Successfully denied refund!',
    },
    actionItem: {
      label: 'Deny Refund',
    }
  },
  {
    test: (account, invoice) => hasSuperAdminAccess(account) &&
        invoice?.transferType === 'REFUND' &&
        !invoice?.includeRefundInPayout &&
        !excludedFromPayoutBookingTransactionStatuses.includes(invoice?.status),
    actionType: InvoiceActionTypes.POST_REQUEST,
    actionPayload: {
      requester: requestToggleIncludeRefundInPayout,
      successMessage: 'Successfully denied refund!',
    },
    actionItem: {
      label: 'Include Refund In Payout',
    }
  },
  {
    test: (account, invoice) => hasSuperAdminAccess(account) &&
        invoice?.transferType === 'REFUND' &&
        invoice?.includeRefundInPayout &&
        !excludedFromPayoutBookingTransactionStatuses.includes(invoice?.status),
    actionType: InvoiceActionTypes.POST_REQUEST,
    actionPayload: {
      requester: requestToggleIncludeRefundInPayout,
      successMessage: 'Successfully denied refund!',
    },
    actionItem: {
      label: 'Exclude Refund From Payout',
    }
  },
  {
    test: (account, invoice) => hasSuperAdminAccess(account) && invoiceStatusesThatCanBeCancelled.includes(invoice.status),
    actionType: InvoiceActionTypes.HANDLER,
    actionPayload: {
      handleClick: (invoice, onInvoiceAction) =>  cancelInvoice(invoice, invoiceCancelStatuses.UNCOLLECTIBLE, onInvoiceAction),
    },
    actionItem: {
      label: 'Uncollectible',
    }
  },
  {
    test: (account, invoice) => hasSuperAdminAccess(account) && invoiceStatusesThatCanBeCancelled.includes(invoice.status),
    actionType: InvoiceActionTypes.HANDLER,
    actionPayload: {
      handleClick: (invoice, onInvoiceAction) => cancelInvoice(invoice, invoiceCancelStatuses.CANCEL_TERM_CHANGES, onInvoiceAction),
    },
    actionItem: {
      label: 'Cancel - Term Changes',
    }
  },
  {
    test: (account, invoice) => hasSuperAdminAccess(account) && invoiceStatusesThatCanBeCancelled.includes(invoice.status),
    actionType: InvoiceActionTypes.HANDLER,
    actionPayload: {
      handleClick: (invoice, onInvoiceAction) => cancelInvoice(invoice, invoiceCancelStatuses.CANCEL_BOOKING_CANCELLED, onInvoiceAction),
    },
    actionItem: {
      label: 'Cancel - Booking Cancelled',
    }
  },
  {
    test: (account, invoice) => hasSuperAdminAccess(account) && invoiceStatusesThatCanBeCancelled.includes(invoice.status),
    actionType: InvoiceActionTypes.HANDLER,
    actionPayload: {
      handleClick: (invoice, onInvoiceAction) => cancelInvoice(invoice, invoiceCancelStatuses.CANCEL_BILLING_REQUIREMENTS, onInvoiceAction),
    },
    actionItem: {
      label: 'Cancel - Billing Requirements',
    }
  },
  {
    test: (account, invoice) => hasSuperAdminAccess(account) && invoiceStatusesThatCanBeCancelled.includes(invoice.status),
    actionType: InvoiceActionTypes.DIALOG,
    actionPayload: {
      dialog: InvoiceActionDialogs.CANCEL_OTHER,
    },
    actionItem: {
      label: 'Cancel - Other',
    }
  }
];

const getInvoiceActionsItems = (account: Account, invoice, onInvoiceAction) => {
  return actionItems
    .filter(({test}) => test(account, invoice))
    .map(({actionType, actionItem, actionPayload}) => {
      switch (actionType) {
        case InvoiceActionTypes.DOWNLOAD:
          actionItem.onClick = () => downloadItem(invoice, actionPayload, onInvoiceAction);
          break;
        case InvoiceActionTypes.OPEN_TAB:
          actionItem.onClick = () => openTab(invoice, actionPayload, onInvoiceAction);
          break;
        case InvoiceActionTypes.DIALOG:
          actionItem.onClick = () => onInvoiceAction(CallbackActions.OPEN_DIALOG, invoice, actionPayload.dialog);
          break;
        case InvoiceActionTypes.POST_REQUEST:
          actionItem.onClick = () => postRequest(invoice, actionPayload, onInvoiceAction);
          break;
        case InvoiceActionTypes.HANDLER:
          actionItem.onClick = () => actionPayload.handleClick(invoice, onInvoiceAction);
          break;
        default:
          throw new Error(`Unknown action type: ${actionType}`);
      }
      actionItem.icon = actionItem.icon ? actionItem.icon : InvoiceActionsDefaultIcons[actionType];
      return actionItem;
    });
}

export {
  CallbackActions,
  InvoiceActionDialogs,
  getInvoiceActionsItems
}