import React, { useEffect, useState, useCallback, useContext } from 'react'
import { withSnackbar } from "../../components/hocs/withSnackbar";
import { getErrorMessageForNonStandardAndStandardResponse } from '../../util/NetworkErrorUtil';
import {
  getAllBuyerInventoryCorrections,
  inventoryCorrectionCount,
} from '../../components/inventory-correction/requests/inventory-correction-requests';
import Busy from '../../components/Busy';
import moment from 'moment';
import { DateFormats } from '../../components/constants/securspace-constants';
import {
  InventoryCorrectionReasonLabel,
  InventoryCorrectionReasons,
  InventoryCorrectionStatus
} from "../../components/constants/inventory-correction-constants";
import { AppContext } from '../../context/app-context';
import InventoryCorrections from "./InventoryCorrections";
import {getDates} from "./util";

const sortByOptions = [
  { value: "dateOfSubmissionSort", label: "Date of submission" },
  { value: "orderNumberSort", label: "Booking Number" },
  { value: "status", label: "Status" },
  { value: "supplierName", label: "Supplier Name"},
  { value: "locationName", label: "Location Name" },
];

const defaultSearchFilter = {searchFilter: '', sortBy: 'dateOfSubmissionSort', sortDir: 'desc'}

const BuyerInventoryCorrections = ({ account, handleLogout, snackbarShowMessage }) => {
  const { updatePendingInventoryCount } = useContext(AppContext)
  const [inventoryCorrections, setInventoryCorrections] = useState([]);
  const [rawCorrections, setRawCorrections] = useState([]);

  const fetchInventoryCorrections = useCallback(() => {
    Busy.set(true);
    getAllBuyerInventoryCorrections(account.id, {"date" : moment().subtract(3, "months").format(DateFormats.DAY), allPending: true})
      .then((response) => {
        const formattedCorrection = response.body?.map((item) => {

          return {
            ...item,
            checkIn: getDates(item).originalCheckIn,
            checkOut: getDates(item).originalCheckOut,
            dateOfSubmission: moment(item?.creationDate).format(DateFormats.DAY),
            containerNumber: item?.containerNumber,
            chassisNumber: item?.chassisNumber,
            trailerNumber: item?.trailerNumber,
            truckLicensePlateNumber: item?.truckLicensePlateNumber,
            orderNumber: item?.locationInventoryActivityGetVO?.orderNumber,
            reason: InventoryCorrectionReasons[item?.reason] ? InventoryCorrectionReasonLabel[item.reason] : 'N/A',
            locationName: item?.locationName,
            status: item?.status,
            driverFirstName: item?.driverFirstName,
            driverLastName: item?.driverLastName,
            driverLicenseNumber: item?.driverLicenseNumber,
            rejectionReason: item?.status === InventoryCorrectionStatus.REJECTED ? item?.rejectionReason : "",
            dateOfSubmissionSort: moment(item?.creationDate),
            orderNumberSort: parseInt(item?.locationInventoryActivityGetVO?.orderNumber)
          }
        });
        setRawCorrections(response.body);
        setInventoryCorrections(formattedCorrection);
        inventoryCorrectionCount(account, updatePendingInventoryCount);
      })
      .catch((error) => {
        snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(error), 'error');
      }).finally(() => {
      Busy.set(false);
    });
  }, [account, snackbarShowMessage]);

  useEffect(() => {
    if (account.id) {
      fetchInventoryCorrections();
    }
  }, [account, fetchInventoryCorrections]);

  return (
    <InventoryCorrections
      fetchInventoryCorrections={fetchInventoryCorrections}
      inventoryCorrections={inventoryCorrections}
      rawCorrections={rawCorrections}
      sortByOptions={sortByOptions}
      account={account}
      handleLogout={handleLogout}
      defaultSearchFilter = {defaultSearchFilter}
      snackbarShowMessage={snackbarShowMessage}
    />
  )
}

export default withSnackbar(BuyerInventoryCorrections)
