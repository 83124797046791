import React from "react";
import { BookingCard } from "@securspace/securspace-ui-kit";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router";
import { InvalidServiceFeeChargeTypes } from "../constants/securspace-constants";
import { sendBookingConfirmationEmail } from "../../views/requests/booking-requests";
import { toast } from "react-toastify";

const useStyles = makeStyles(() => ({
  mb:{
    marginBottom: '1rem'
  }
}));

const BookingCardItem = ({booking, headerCells}) => {

    const {id} = booking;

    const history = useNavigate();
    const classes = useStyles();
    const buttons = [];

    
    const sendConfirmationEmail = async (booking) => {
        try {
            await sendBookingConfirmationEmail(booking.id);
            toast.success(`Confirmation email sent successfully: ${booking.buyerEmail}`);
        } catch (error) {
            toast.error(`Failed to send confirmation email: ${error}`)
        }
    }

    const navigateToServiceFeeCreation = (booking) => {
        history(`/booking/${booking.id}/charge-service-fee`, { state: { booking }});
    }

    if (booking?.active === 'Active') {
        buttons.push(
            {
                label: 'Resend Confirmation Email',
                onClick: sendConfirmationEmail,
                variant: 'outlined'
            }
        );
    }
    if (!InvalidServiceFeeChargeTypes.includes(booking.status)) {
        buttons.push(
            {
                label: 'Charge Service Fee',
                onClick: navigateToServiceFeeCreation,
                variant: 'outlined'
            }
        );
    }

    return (
      <BookingCard
        headCells={headerCells}
        rootStyle={classes.mb}
        key={id}
        data={booking}
        buttons={buttons}
      />
    );
};

export default BookingCardItem;
