import React from 'react'
import { NoDataMessage, InfoCard } from "@securspace/securspace-ui-kit";
import { Grid } from "@mui/material";
import { styles } from './styles';
import {getHeaderCells} from './data';

const BuyerInventoryTable = ({
    inventoryReports,
    loading,
    locationId,
    driverName,
    equipmentType,
    bookingNumber,
    sortBy,
    handleToggle,
    expandedItems
}) => {
  return (
    <Grid item mt={4} container flexDirection={'column'} sx={{
      ...(!inventoryReports.length && !loading) && styles.container,
  }}>
      {(!inventoryReports.length && !loading) ? (
          <Grid item sx={styles.showMessage}>
              {
                  sortBy ||  locationId || driverName || equipmentType || bookingNumber ? (
                      <NoDataMessage
                          image="/app-images/person-and-box.svg"
                          message="No matching records found."
                      />
                  ) : (
                      <NoDataMessage
                          image="/app-images/no-data-match.png"
                          message="No input selected, To view results kindly select the data above"
                      />
                  )
              }
          </Grid>
      ) : (
          inventoryReports.map((inventory) => (
            <InfoCard
              key={inventory.id}
              headCells={getHeaderCells(inventory.assetType)}
              data={inventory}
              hasAction={false}
              loading={loading}
              handleToggle={handleToggle}
              collapse={expandedItems[inventory.id]}
          />
          ))
      )}
  </Grid>
  )
}

export default BuyerInventoryTable
