import React from 'react'
import { bookingPaymentStyle } from './style'
import { Box, Button, Grid, Typography } from '@mui/material';
import { CreditCard } from "@mui/icons-material";
import SinglePaymentMethod from './SinglePaymentMethod';
import {determinePaymentImage} from "../../util/PaymentUtils";

const BookingPaymentInfo = ({ paymentMethods, showPaymentModal, selectedPaymentMethod, setSelectedPaymentMethod }) => {

    const classes = bookingPaymentStyle();

    return (
        <Box>
            <Grid container justifyContent='space-between' alignItems='center'>
                <Typography variant={'h5'} color={'textPrimary'}>
                    Pay With
                </Typography>
                <Button onClick={showPaymentModal} className={classes.button} variant='contained' color='secondary'>
                    <CreditCard className={classes.payIcon} />
                    ADD PAYMENT
                </Button>
            </Grid>
            {paymentMethods?.length > 0 ? <Box className={classes.paymentSaveInfoContainer}>
                {paymentMethods.map((paymentMethod, index) => {
                    return (
                        <SinglePaymentMethod
                          key={index}
                          img={determinePaymentImage(paymentMethod)}
                          cardInfo={`...` + paymentMethod.lastFour + (paymentMethod.nickName ? ` (${paymentMethod.nickName})` : '')}
                          isSelect={selectedPaymentMethod === index + 1}
                          id={index + 1}
                          setIsSelect={setSelectedPaymentMethod} />
                    )
                })}
            </Box> : <Typography className={classes.noPayment}>
                No payment method added yet
            </Typography>}

        </Box>
    )
}

export default BookingPaymentInfo
