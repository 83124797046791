import React, {useState, useEffect} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import TextFieldWithDirtyValidation from "../../form/TextFieldWithDirtyValidation";
import FormControl from "@mui/material/FormControl";
import classNames from "classnames";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import { UserRoleToTypeName, UserTypeDBFormat } from "../../constants/securspace-constants";
import PropTypes from "prop-types";
import {Theme} from "@mui/material";
import PhoneInput from "react-phone-number-input/input";
import {PhoneNumberTextField} from "../../form/PhoneNumberTextField";
import UserLocationSelect from "./UserLocationSelect";
import {requestLocations} from "../../location/requests/location-requests";
import { isSupplierAccount } from "../../../util/AccountUtils";

const useStyles: (theme: Theme) => {
  colGap10: CSSStyleSheet,
  appInput: CSSStyleSheet,
  backgroundGrey100: CSSStyleSheet,
  phoneInput: CSSStyleSheet,
  rowGap10: CSSStyleSheet,
} = makeStyles((theme) => ({
  colGap10: {
    columnGap: '0.71rem',
  },
  appInput: {
    marginBottom: '0.6rem',
    '& label': {
      fontSize: '1.14em',
      fontWeight: 400,
      fontFamily: 'Inter',
      lineHeight: '0.71em',
      letterSpacing: '.05em',
      color: theme.palette.text.disabled,
    }
  },
  backgroundGrey100: {
    backgroundColor: theme.palette.grey["100"],
  },
  rowGap10: {
    rowGap: '0.71rem',
  }
}));

const CreateEditUser = ({
                          open,
                          onClose,
                          onSubmit,
                          onTextChange,
                          onSelectChange,
                          onPhoneNumberChange,
                          isEdit,
                          user,
                          emailErrorMsg,
                          firstNameErrorMsg,
                          lastNameErrorMsg,
                          phoneNumberErrorMsg,
                          roleErrorMsg,
                          roleBasedOptions,
                          formErrorMsg,
                          adminUsersArray,
                          account,
                          handleLocationIdsChange
                        }) => {
  const classes = useStyles();
  const [userIsAdminOrOwner, setUserIsAdminOrOwner] = useState(false);
  const [selectComponentLabel, setSelectComponentLabel] = useState("");
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    if (open && isSupplierAccount(account)) {
      requestLocations().then((response) => {
        setLocations(response.body);
      });
    }
  }, [open, account]);
  
  useEffect(() => {
    if(adminUsersArray.includes(user)) {
      setUserIsAdminOrOwner(true);
      setSelectComponentLabel("Cannot change role of Admin or Owner");
    } else {
      setUserIsAdminOrOwner(false);
      setSelectComponentLabel("Select Role");
    }
  }, [user, adminUsersArray]);

  const formattedValue = userIsAdminOrOwner ?
    (UserTypeDBFormat.ROLE_USERTYPE_OWNER || UserTypeDBFormat.ROLE_USERTYPE_PRIMARY_OWNER) : user.role;

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby={"ss-create-edit-user"}>
      <DialogTitle id={"ss-create-edit-user"}>{ isEdit ? 'Edit User' : 'Add User' }</DialogTitle>
      <DialogContent>
        <form id={"ss-create-edit-user-form"} onSubmit={onSubmit} noValidate className={"w-100"}>
          <Grid container direction={'column'} className={classes.rowGap10}>
            {
              isEdit ?
                <TextField
                  value={user.username}
                  name={"email"}
                  label={"Email"}
                  placeholder={"Email"}
                  type={"email"}
                  fullWidth
                  disabled
                />
                :
                <TextFieldWithDirtyValidation
                  value={user.username}
                  onChange={onTextChange}
                  name={"email"}
                  label={"Email"}
                  placeholder={"Email"}
                  type={"email"}
                  error={!!emailErrorMsg}
                  helperText={emailErrorMsg}
                  fullWidth
                  required
                />
            }
            <Grid container className={classes.colGap10}>
              <Grid item container md xs={12}>
                <TextFieldWithDirtyValidation
                  value={user.firstName}
                  onChange={onTextChange}
                  name="firstName"
                  label="First Name"
                  placeholder="Enter your first name"
                  error={!!firstNameErrorMsg}
                  helperText={firstNameErrorMsg}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item container md xs={12}>
                <TextFieldWithDirtyValidation
                  value={user.lastName}
                  onChange={onTextChange}
                  name="lastName"
                  label="Last Name"
                  placeholder="Enter your last name"
                  error={!!lastNameErrorMsg}
                  helperText={lastNameErrorMsg}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
            <PhoneInput
              value={user.phoneNumber}
              onChange={onPhoneNumberChange}
              inputComponent={PhoneNumberTextField}
              defaultCountry={"US"}
              className={classes.appInput}
              error={!!phoneNumberErrorMsg}
              helperText={phoneNumberErrorMsg}
              fullWidth
              variant="standard" 
            />
            {user?.role !== UserTypeDBFormat.ROLE_USERTYPE_PRIMARY_OWNER &&
              <FormControl variant="standard" className={classNames("w-100", classes.appInput)} required error={!!roleErrorMsg}>
                <InputLabel htmlFor={"create-edit-user-select-role"}>{selectComponentLabel}</InputLabel>
                <Select
                  value={formattedValue}
                  defaultValue={formattedValue}
                  id={"create-edit-user-select-role"}
                  onChange={onSelectChange}
                  disabled={userIsAdminOrOwner}
                  placeholder={ userIsAdminOrOwner ? "Administrator" : null }
                >
                  {
                    roleBasedOptions.map(role => (
                      <MenuItem value={role} key={role}>
                        {UserRoleToTypeName[role]}
                      </MenuItem>
                    ))
                  }
                </Select>
                {roleErrorMsg && <FormHelperText>{roleErrorMsg}</FormHelperText>}
              </FormControl>
            }
            {
              isSupplierAccount(account) &&
              <UserLocationSelect
                locations={locations}
                selectedLocationIds={user.locationIds}
                handleLocationIdsChange={handleLocationIdsChange}
              />
            }
            {
              formErrorMsg &&
              <Typography variant="body1" component="div" color="error" align="center">{formErrorMsg}</Typography>
            }
          </Grid>
        </form>
      </DialogContent>
      <DialogActions className={classes.backgroundGrey100}>
        <Button color={"primary"} onClick={onClose}>Cancel</Button>
        <Button color={"primary"} type={"submit"} form={"ss-create-edit-user-form"} >{isEdit ? 'Update' : 'Add'}</Button>
      </DialogActions>
    </Dialog>
  );
};

CreateEditUser.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onTextChange: PropTypes.func.isRequired,
  onPhoneNumberChange: PropTypes.func.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  emailErrorMsg: PropTypes.string,
  firstNameErrorMsg: PropTypes.string,
  lastNameErrorMsg: PropTypes.string,
  roleErrorMsg: PropTypes.string,
  roleBasedOptions: PropTypes.arrayOf(
    (propValue, key, componentName, location, propFullName) => {
      if (!UserTypeDBFormat[propValue[key]]) {
        return new Error(
          `Invalid prop \`${propFullName}\` supplied to` +
          ` \`${componentName}\`. Prop must be of type \`${Object.keys(UserTypeDBFormat).join('|| ')}\``
        );
      }
  }).isRequired,
  formErrorMsg: PropTypes.string,
  adminUsersArray: PropTypes.array.isRequired,
  account: PropTypes.object.isRequired,
  handleLocationIdsChange: PropTypes.func.isRequired,
};

export default CreateEditUser;
