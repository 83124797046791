import request from "../util/SuperagentUtils";

const BASE_URL = '/api/admins/report/invoices';

type AdminInvoiceQuery = {
  startDate?: string,
  endDate?: string,
  locationId?: string,
  transactionNumber?: string,
  bookingNumber?: string,
  assetType?: string,
  buyerAccountId: string,
  transactionType?: string,
  bookingStatus?: string,
  supplierTransactionStatus?: string,
  taxStatus?: string,
  paymentProcessor?: string,
  sortBy?: string,
  sortDir?: string,
  page?: number,
  size?: number,
};

const requestAdminInvoices: (query: AdminInvoiceQuery) => Promise = (query) => {
  return request.get(BASE_URL).query(query);
};

const requestAdminInvoicesSummary: (query: {
  startDate?: string,
  endDate?: string,
  locationId?: string,
  transactionNumber?: string,
  bookingNumber?: string,
  assetType?: string,
  buyerAccountId?: string,
  supplierAccountId?: string,
  transactionType?: string,
  bookingStatus?: string,
  supplierTransactionStatus?: string,
  taxStatus?: string,
  paymentProcessor?: string,
}) => Promise = (query) => {
  return request.get('/api/admins/report/invoices/summary').query(query);
};

/**
 * Returns a URLSearchParams object with the given query parameters appended.
 * Params with values of null or undefined will not be included in the appended query params.
 *
 * @param query
 * @returns {URLSearchParams}
 */
const getQueryParams = (query) => {
  const params = new URLSearchParams();
  Object.keys(query).forEach((key) => {
    if (query[key]) {
      params.append(key, query[key]);
    }
  });
  return params;
};

const getAdminInvoiceReportUrl = (query: {
  startDate?: string,
  endDate?: string,
  locationId?: string,
  transactionNumber?: string,
  bookingNumber?: string,
  assetType?: string,
  buyerAccountId: string,
  transactionType?: string,
  bookingStatus?: string,
  supplierTransactionStatus?: string,
  taxStatus?: string,
  paymentProcessor?: string,
  sortBy?: string,
  sortDir?: string,
}) => {
  return `${BASE_URL}/report?${getQueryParams(query)}`;
}

/**
 * Returns the URL for the admin invoice group summary report with the given query parameters appended.
 * Params with values of null or undefined will not be included in the appended query params.
 *
 * @param query
 * @returns {`/api/admins/report/invoices/group-summary-report?${string}`}
 */
const getAdminInvoiceGroupSummaryReportUrl = (query) => {
  return `${BASE_URL}/group-summary-report?${getQueryParams(query)}`;
};

export {
  requestAdminInvoices,
  requestAdminInvoicesSummary,
  getAdminInvoiceReportUrl,
  getAdminInvoiceGroupSummaryReportUrl,
};