import React from 'react';
import {FormControl, FormControlLabel, Radio, RadioGroup, Theme} from "@mui/material";
import type {PaymentMethod} from "../../types/PaymentMethod";
import {PaymentType} from "../../constants/securspace-constants";
import {makeStyles} from "@mui/styles";

const useStyles: (theme: Theme) => {
  panel: CSSStyleSheet,
  link: CSSStyleSheet
} = makeStyles((theme) => ({
  paymentOption: {
    backgroundColor: theme.palette.grey[50],
    borderRadius: '0.57rem',
    paddingLeft: '0.1em',
    paddingRight: '0.3em',
    marginBottom: '0.2em',
  }
}));

const PaymentMethodOption = (props: { paymentMethod: PaymentMethod }) => {
  const {paymentMethod} = props;
  const classes = useStyles();
  return (
    <FormControlLabel
      value={paymentMethod.id}
      className={classes.paymentOption}
      label={
        <>
          {paymentMethod.nickName &&  <><strong>{paymentMethod.nickName}</strong><br/></>}
          <span style={{fontStyle: 'italic'}}>{paymentMethod.type === PaymentType.CARD ? paymentMethod.cardBrand : paymentMethod.bankName}</span>{` ending in ${paymentMethod.lastFour}`}
        </>
      }
      control={<Radio />}
    />
  );
};

const PaymentMethodSelect = (props: {
  paymentMethods: Object[],
  onChange: () => void,
  value: string,
}) => {
  const {paymentMethods, onChange, value} = props;
  return <FormControl>
    <RadioGroup
      name={"paymentMethod"}
      onChange={onChange}
      value={value}
    >
      {paymentMethods.map((paymentMethod: PaymentMethod) => {
        return <PaymentMethodOption
          key={paymentMethod.id}
          paymentMethod={paymentMethod}
        />
      })}
    </RadioGroup>
  </FormControl>
};

export default PaymentMethodSelect;