import React from 'react';
import { Grid } from '@mui/material';
import type { Booking } from '../../types/Booking.js';
import BookingCardItem from './BookingCardItem.js';
import { NoDataMessage } from '@securspace/securspace-ui-kit';

const BookingCardList = (props: {bookings: Booking[], headerCells: [{id: String, label: String}]}) => {
  const {bookings, headerCells, rootStyle} = props;

  const imageURI =  "/app-images/person-and-box.svg";
  const message = "No matching bookings found"

  return (
    bookings.length?
      bookings.map((booking: Booking) => <BookingCardItem key={booking.id} booking={booking} headerCells={headerCells} rootStyle={rootStyle} />)
      : <Grid item marginTop='2em'>
        <NoDataMessage message={message} image={imageURI} />
      </Grid> 
  );
}

export default BookingCardList;