import React from 'react';
import InvoiceCard from "./InvoiceCard/InvoiceCard";
import InfoCardLoadingSkeleton from "../../../skeleton/InfoCardLoadingSkeleton";
import {Grid} from "@mui/material";

type InvoiceListProps = {
  account: Account,
  invoices: {transactionId: string}[],
  loading: boolean,
  onInvoiceAction: (invoice: any) => void,
};

/**
 * Renders a list of invoice cards for the admin invoices report.
 *
 * @param invoices The list of invoices to render.
 * @param loading Determines whether to show a loading skeleton instead of the actual invoice cards.
 * @param onInvoiceAction The callback to be called when an action on an invoice card is successful.
 * @returns InvoiceCard[]
 * @constructor
 */
const InvoiceList: (props: InvoiceListProps) => JSX.Element = ({
  account,
  invoices,
  loading = false,
  onInvoiceAction
}) => {
  return (<Grid item container direction={'column'}>
      {
        loading ? <InfoCardLoadingSkeleton /> : invoices.map((invoice) => (
          <InvoiceCard
            account={account}
            key={invoice.transactionId}
            invoice={invoice}
            onInvoiceAction={onInvoiceAction}
          />
        ))
      }
  </Grid>
  )
};

export default InvoiceList;