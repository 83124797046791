import React from 'react';
import type {Account} from "../../../../../types/Account";
import AddRefundCreditModal from "./AddRefundCreditModal";
import CreateRequestRefundModal from "./CreateRequestRefundModal";
import {CallbackActions, InvoiceActionDialogs} from "../InvoiceActions";
import CancelInvoiceNotesInput from "../../CancelInvoiceNotesInput";
import Busy from "../../../../Busy";

type InvoiceActionsDialogWrapperProps = {
  activeDialog: String,
  account: Account,
  invoice: {transactionId: string, transactionNumber: string},
  onInvoiceAction: (callbackActions: any) => void,
  onClose: (callbackActions: any) => void,
};

const InvoiceActionsDialogWrapper: (props: InvoiceActionsDialogWrapperProps) => JSX.Element = ({
  activeDialog,
  account,
  invoice,
  onInvoiceAction,
  onClose
}) => {

  const onDialogClose = (e) => {
    onClose && onClose(e);
  }

  return (
    <>
      <AddRefundCreditModal open={activeDialog === InvoiceActionDialogs.ADD_REFUND_CREDIT} account={account} invoice={invoice} onInvoiceAction={onInvoiceAction} onClose={onDialogClose} />
      <CreateRequestRefundModal open={activeDialog === InvoiceActionDialogs.CREATE_REQUEST_REFUND} account={account} invoice={invoice} onInvoiceAction={onInvoiceAction} onClose={onDialogClose} />
      <CancelInvoiceNotesInput
        showNotes={invoice && activeDialog === InvoiceActionDialogs.CANCEL_OTHER}
        setShowNotes={onDialogClose}
        transactionId={invoice?.transactionId || ''}
        successCancelInvoice={() => {
          Busy.set(false);
          onDialogClose([CallbackActions.SUCCESS, invoice, `Successfully cancelled invoice ${invoice.transactionNumber}`]);
        }}
        failCancelInvoice={(e) => {
          Busy.set(false);
          onDialogClose([CallbackActions.ERROR, invoice, e]);
        }}
      />
    </>
  );
};

export default InvoiceActionsDialogWrapper;