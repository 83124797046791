import request from '../../../util/SuperagentUtils';
import { isSupplierAccount } from "../../../util/AccountUtils";

const inventoryCorrectionBaseUrl = '/api/inventory-corrections'

const getAllSupplierInventoryCorrections = (
    accountId,
    query,
    onSuccess,
    onFail
) => {
    const result = request
        .get(`${inventoryCorrectionBaseUrl}/supplier/${accountId}`).query(query);

    if (onSuccess && onFail) {
        result
            .then((resp) => onSuccess(resp.body))
            .catch((err) => onFail(err))
    } else {
        return result
    }
};

const getAllBuyerInventoryCorrections = (
  accountId,
  query,
  onSuccess,
  onFail
) => {
    const result = request
      .get(`${inventoryCorrectionBaseUrl}/buyer/${accountId}`).query(query);

    if (onSuccess && onFail) {
        result
          .then((resp) => onSuccess(resp.body))
          .catch((err) => onFail(err))
    } else {
        return result
    }
};

const updateInventoryCorrection = (
    inventoryId,
    data,
    onSuccess,
    onFail
) => {
    const result = request
        .patch(`${inventoryCorrectionBaseUrl}/${inventoryId}`)
        .send(data)

    if (onSuccess && onFail) {
        result
            .then((resp) => onSuccess(resp.body))
            .catch((err) => onFail(err))
    } else {
        return result
    }
};

const inventoryCorrectionCount = (account, onSuccess, onFail) => {
    const accountTypeUrl = isSupplierAccount(account) ? 'supplier' : 'buyer';
    if (account?.id) {
        const result = request
            .get(`${inventoryCorrectionBaseUrl}/${accountTypeUrl}/${account.id}/count`)

        if (onSuccess || onFail) {
            result
                .then((resp) => onSuccess && onSuccess(resp.body))
                .catch((err) => onFail && onFail(err))
        } else {
            return result
        }
    }
};


const submitInventoryCorrection = (correction, onSuccess, onFail) => {
    const result = request
        .post(inventoryCorrectionBaseUrl)
        .send(correction);

    if (onSuccess && onFail) {
        result
            .then((resp) => onSuccess(resp.body))
            .catch((err) => onFail(err))
    } else {
        return result
    }
}

const deleteInventoryCorrection = (correctionId, onSuccess, onFail) => {
    const result = request.delete(`${inventoryCorrectionBaseUrl}/${correctionId}`);

    if (onSuccess && onFail) {
        result
            .then((resp) => onSuccess(resp.body))
            .catch((err) => onFail(err))
    } else {
        return result
    }
};

const getBookingOrderNumber = (bookingId, onSuccess, onFail) => {
    const result = request
        .get(`/api/booking/orders/${bookingId}`)

    if (onSuccess && onFail) {
        result
            .then((resp) => onSuccess(resp.body))
            .catch((err) => onFail(err))
    } else {
        return result
    }

};

const moveAssetToDifferentBooking = (inventoryActivityId, orderNumber, onSuccess, onFail) => {
    const result = request
        .put('/api/inventory-move')
        .send({
            inventoryActivityId,
            orderNumber
        })

    if (onSuccess && onFail) {
        result
            .then((resp) => onSuccess(resp.body))
            .catch((err) => onFail(err))
    } else {
        return result
    }
};

export {
    getAllSupplierInventoryCorrections,
    inventoryCorrectionCount,
    updateInventoryCorrection,
    getBookingOrderNumber,
    moveAssetToDifferentBooking,
    submitInventoryCorrection,
    deleteInventoryCorrection,
    getAllBuyerInventoryCorrections
}
