import { AccountType, SubscriptionType } from "../components/constants/securspace-constants";
import {
  getAdminUserTypes,
  getOwnerUserTypes,
  getBuyerUserTypes,
  getSupplierUserTypes,
  getGateManagerUserTypes
} from "../util/AccountUtils";
import _ from 'underscore'


export default class AccessControlBuilder {
    _userTypes = getAdminUserTypes();
    _accountTypes = [];
    _subscriptionTypes = [];

    build = () => {
        return {
            userTypes: this._userTypes,
            accountTypes: this._accountTypes,
            subscriptionTypes: this._subscriptionTypes
        }
    }

    allowUserType = (userType: String) => {
        if(!_.contains(this._userTypes, userType)) {
            this._userTypes.push(userType);
        }

        return this;
    }

    allowUserTypes = (userTypeArray: Array<String>) => {
        userTypeArray?.forEach(userType => {
            this.allowUserType(userType);
        });

        return this;
    }

    allowAccountType = (accountType: String) => {
        if(!_.contains(this._accountTypes, accountType)) {
            this._accountTypes.push(accountType);
        }

        return this;
    }

    allowSubscriptionType = (subscriptionType: String) => {
        if(!_.contains(this._subscriptionTypes, subscriptionType)) {
            this._subscriptionTypes.push(subscriptionType);
        }

        return this;
    }

    allowAllOwners = () => {
        this.allowUserTypes(getOwnerUserTypes());
        this.allowAccountType(AccountType.SUPPLIER);
        this.allowAccountType(AccountType.BUYER);
        this.allowAllSubscriptionTypes();
        return this;
    }

    allowAllBuyer = () => {
        this.allowUserTypes(getBuyerUserTypes());
        this.allowAccountType(AccountType.BUYER);
        this.allowAllSubscriptionTypes();
        return this;
    }

    allowBuyerOwner = () => {
        this.allowUserTypes(getOwnerUserTypes());
        this.allowAccountType(AccountType.BUYER);
        this.allowAllSubscriptionTypes();
        return this;
    }

    allowAllGmsUsers = () => {
        this.allowUserTypes(getSupplierUserTypes());
        this.allowAccountType(AccountType.SUPPLIER);
        this.allowGmsSubscriptionTypes();
        return this;
    }

    allowAllSupplierOwner = () => {
        this.allowUserTypes(getOwnerUserTypes());
        this.allowAccountType(AccountType.SUPPLIER);
        this.allowAllSubscriptionTypes();
        return this;
    }

    allowGateManager = () => {
        this.allowUserTypes(getGateManagerUserTypes());
        this.allowAccountType(AccountType.SUPPLIER);
        this.allowGmsSubscriptionTypes();
        return this;
    }

    allowMarketplaceOnlySupplier = () => {
        this.allowUserTypes(getOwnerUserTypes());
        this.allowAccountType(AccountType.SUPPLIER);
        this.allowSubscriptionType(SubscriptionType.MARKETPLACE_ONLY);
        return this;
    }

    allowGmsProOwner = () => {
        this.allowUserTypes(getOwnerUserTypes());
        this.allowAccountType(AccountType.SUPPLIER);
        this.allowSubscriptionType(SubscriptionType.GMS_PRO);
        return this;
    }

    allowGmsProGateManagers = () => {
        this.allowUserTypes(getGateManagerUserTypes());
        this.allowUserTypes(getOwnerUserTypes());
        this.allowGmsProOwner();
        return this;
    };

    allowAdmin = () => {
        this.allowUserTypes(getAdminUserTypes());
        this.allowAccountType(AccountType.ADMIN);
        return this;
    }

    allowAllSubscriptionTypes = () => {
        this.allowSubscriptionType(SubscriptionType.MARKETPLACE_ONLY);
        this.allowGmsSubscriptionTypes();
        return this;
    }

    allowGmsSubscriptionTypes = () => {
        this.allowSubscriptionType(SubscriptionType.GMS_LITE);
        this.allowSubscriptionType(SubscriptionType.GMS_PRO);
        return this;
    }

}
