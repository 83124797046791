import React, { useState, useEffect } from 'react'
import {UserTypeName} from "../constants/securspace-constants";
import MultiSelect from "../multiSelect/MultiSelect";
import PropTypes from 'prop-types'
import { formatPhoneNumber } from '../../util/ValidationUtils';
import { isSupplierAccount } from "../../util/AccountUtils";

import SolidButton from "../form/SolidButton";
import OutlinedButton from "../form/OutlinedButton";

import './style/manage-user-item.css'

const displayMap = new Map([
  ['ROLE_USERTYPE_GATECLERK', UserTypeName.GATE_CLERK],
  ['ROLE_USERTYPE_GATEMANAGER', UserTypeName.GATE_MANAGER],
  ['ROLE_USERTYPE_DISPATCHER', UserTypeName.DISPATCHER],
  ['ROLE_USERTYPE_OWNER', UserTypeName.ADMIN],
  ['ROLE_USERTYPE_PRIMARY_OWNER', UserTypeName.ACCOUNT_OWNER],
]);

const ManageUserItem = (props) => {
  const {
    user,
    locationOptions,
    onRemoveUser,
    onUpdateLocations
  } = props

  const [editLocationsOpen, setEditLocationsOpen] = useState(false)
  const [updatedLocations, setUpdatedLocations] = useState(user.locationIds)

  const toggleLocationVisibility = () => {
    setEditLocationsOpen(!editLocationsOpen)

    if(!editLocationsOpen) {
      setUpdatedLocations(user.locationIds)
    }
  }

  const internalSubmit = () => {
    onUpdateLocations(user, updatedLocations)
  }

  useEffect(() => {
    setEditLocationsOpen(false)
    setUpdatedLocations(user.locationIds)
  }, [user.locationIds])

  const isPrimaryOwner = user.role === 'ROLE_USERTYPE_PRIMARY_OWNER'

  return (
    <div className='ss-manage-user-item-parent'>
      <div className="manage-user-item-info-container">
        <div className="ss-manage-user-info-sub-container">
          <div className="manage-user-info-item ss-existing-username">{user.username}</div>
          <div className='manage-user-info-item ss-existing-name'>{`${user.firstName ? user.firstName : ''} ${user.lastName? user.lastName : ''}`}</div>
          <div className="manage-user-info-item ss-existing-role">{displayMap.get(user.role)}</div>
          <div className="manage-user-info-item ss-existing-phone-number">{formatPhoneNumber(user.phoneNumber)}</div>
        </div>
        <div className="manage-user-info-action-item-container">
          {
            (isSupplierAccount(user) && !isPrimaryOwner) &&
            <OutlinedButton
              label="Edit"
              onClick={toggleLocationVisibility}
              className="edit-locations-button"
            />
          }
          {
            !isPrimaryOwner &&
              <SolidButton label="Remove" onClick={() => onRemoveUser(user)} />
          }
        </div>
      </div>
      <div>
        {
          editLocationsOpen &&
            <div className="edit-locations-form-container">
              <form className="ss-form ss-block">
                <MultiSelect
                  value={updatedLocations || []}
                  options={locationOptions}
                  label="LOCATIONS"
                  onChange={setUpdatedLocations}
                />
              </form>
              <div className="edit-locations-form-btn-container">
                <div className="edit-locations-cancel-btn">
                  <OutlinedButton label="Cancel" onClick={toggleLocationVisibility} />
                </div>
                <div>
                  <SolidButton label="Save" onClick={internalSubmit} />
                </div>
              </div>
            </div>
        }
      </div>
    </div>
  )
}

ManageUserItem.propTypes = {
  user: PropTypes.object.isRequired,
  onUpdateLocations: PropTypes.func.isRequired,
  onRemoveUser: PropTypes.func.isRequired,
  locationOptions: PropTypes.array.isRequired
}

export default ManageUserItem
