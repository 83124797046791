import { BookingStatus } from "../constants/securspace-constants";
import { formatCurrencyValue } from "../../util/PaymentUtils";
import { formatFrequency } from "@securspace/securspace-ui-kit";
import moment from "moment";

const assetTypeOption = [
    {
        "value": "",
        "label": "All"
    },
    {
        "value": "CONTAINER_CHASSIS",
        "label": "Container/Chassis"
    },
    {
        "value": "TRUCK_PLUS_CONTAINER_TRAILER",
        "label": "Truck + Container/Trailer"
    },
    {
        "value": "TRUCK_ONLY",
        "label": "Truck Only"
    },
    {
        "value": "TRAILER",
        "label": "Trailer"
    },
    {
        "value": "REEFER",
        "label": "Refrigeration"
    },
    {
        "value": "EQUIPMENT_MOVING_FEES",
        "label": "Equipment Moving Fee"
    },
    {
        "value": "GATE_FEES",
        "label": "Gate Fees"
    },
    {
        "value": "GROUNDED_CONTAINER_FEES",
        "label": "Grounded Container"
    },
    {
        "value": "LIFT_FEES",
        "label": "Lift Fees"
    },
    {
        "value": "OVERAGE_FEES",
        "label": "Overage Spaces"
    },
    {
        "value": "REEFER_FEES",
        "label": "Reefer KW Usage"
    }
];

const sortByFields = [
    {
        label: "Booking Number",
        value: "orderNumber"
    },
    {
        label: "Buyer",
        value: "buyerCompanyName"
    },
    {
        label: "Start Date",
        value: "startDate",
    }
];

const filterBySelectOption = [
    {
        label: "Equipment Type",
        value: "",
        name: "equipmentType",
        option: assetTypeOption
    },
    {
        label: "Frequency",
        value: "",
        name: "frequency",
        option: [
            {label: 'All', value: ''},
            {
                label: 'Daily',
                value: 'ONE_TIME'
            },
            {
                label: 'Monthly',
                value: 'RECURRING'
            }
        ]
    }
]

const headerCells = [
    { id: 'orderNumber', label: 'BOOKING NUMBER' },
    { id: 'buyerCompanyName', label: 'Buyer' },
    { id: 'startDateValue', label: 'Start Date' },
    { id: 'endDateValue', label: 'End Date' },
    { id: 'numberOfSpaces', label: 'Number of Spaces' },
    { id: 'locationName', label: 'Location' },
    { id: 'brokered', label: 'Brokered' },
    { id: 'frequencyValue', label: 'Frequency' },
    { id: 'assetType', label: 'Equipment Type' },
    { id: 'initialSupplierPayoutAmount', label: 'Initial/Total Charge' },
    { id: 'recurringSupplierPayoutAmount', label: 'Recurring Charge' },
    { id: 'rate', label: 'Booking Rate' },
    { id: 'status', label: 'STATUS' },
    { id: 'active', label: 'Active' },
    { id: 'createdOn', label: 'Created On' },
];

const formatDataForCSVDownload = (reportData) => {
    const formattedData =  reportData?.map(item => {
        return {
            location: item.locationName,
            buyer: item.buyerCompanyName,
            type: item.transactionType,
            spaces: item.numberOfSpaces,
            amount: item.supplierAmount,
            status: item.status,
            startDate: item.startDate,
            endDate: item.endDate,
            invoiceCreated: item.createdOn,
            paymentInitiated: item.paymentCreatedOn,
            processCompleted: item.paymentCompletedOn,
            payoutInitiated: item.payoutCreatedOn,
            payoutCompleted: item.payoutCompletedOn,
            invoiceNumber: item.transactionNumber,
            paymentType: item.paymentType,
            chargedDate: item.createdOn,
            booking: item.bookingNumber,
            equipmentType: item.assetType,
            notes: item.notes
        }
    });
    return formattedData
}

const shouldShowEndDate = (data, bookingStatusLabel) => {
    return (
        data.frequency === 'RECURRING' &&
        data.endDateAdjusted !== true &&
        (data.active === true || data.status === bookingStatusLabel.PENDING || data.status === bookingStatusLabel.INCOMPLETE)
    );
};

function transformBooking(booking) {
    const commonProperties = {
        initialSupplierPayoutAmount: formatCurrencyValue(booking.initialCharge, true),
        recurringSupplierPayoutAmount: formatCurrencyValue(booking.recurringSupplierPayoutAmount, true),
        frequencyValue: formatFrequency(booking.frequency, booking.durationType),
        startDateValue: moment(booking.startDate).format("L h:mm a").toUpperCase(),
        createdOn: moment(booking.createdOn).format("L h:mm a").toUpperCase(),
        supplierCompanyName: booking.supplierCompanyName ? booking.supplierCompanyName : 'N/A',
        active: booking.active ? 'Active' : 'Not Active',
        brokered: booking.brokered ? 'True' : 'False',
        endDateValue: shouldShowEndDate(booking, BookingStatus) ? 'Until cancelled' : `${moment(booking.endDate).format("L h:mm a").toUpperCase() || "N/A"}`,
        rate: booking.brokered ? 'N/A' : formatCurrencyValue(booking.rate) + (booking.durationType === 'WEEKLY' ? ' /week' : booking.durationType === 'MONTHLY' ? ' /month' : ' /day'),
    };

    return {
        ...booking,
        ...commonProperties
    };
};

export {
    sortByFields,
    headerCells,
    filterBySelectOption,
    transformBooking,
    formatDataForCSVDownload
}