import { Grid, Typography, Button } from '@mui/material'
import { CSVButton } from '@securspace/securspace-ui-kit'
import React from 'react'
import DownloadCsvIcon from "../icons/DownloadCsvIcon";

const SupplierBookingTitle = ({
    count,
    title,
    data,
    setExpandedItems,
    setExpandedAll,
    isShowCollapseButton = false,
    handleExpandAll,
    reportName = 'Active_Booking',
    expandedItems,
    reportFormatter
}) => {
    return (
        <Grid item mt={4} mb={4} sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '1rem'
        }}>
            <Grid item sx={{
                display: 'flex',
                alignItems: 'center',
                gap: "1rem",
                flexWrap: 'wrap'
            }}>
                <Typography variant='h6' sx={{
                    textTransform: 'uppercase',
                }}>{title} (SHOWING ALL {count} RECORDS IN GROUP)</Typography>
                {isShowCollapseButton &&
                    <Button onClick={Object.keys(expandedItems).length > 0 ? () => {
                        setExpandedItems({})
                        setExpandedAll(false)
                    } : handleExpandAll} variant='text'>
                        {Object.keys(expandedItems).length > 0 ? 'Collapse All' : 'Expand All'}
                    </Button>
                }
            </Grid> 
            <CSVButton
                reportName={`${reportName}_${new Date().toLocaleDateString()}`}
                reportData={reportFormatter(data)}
                title="Download Report"
                startIcon={<DownloadCsvIcon />}
            />
        </Grid>
    )
}

export default SupplierBookingTitle
