import * as React from 'react';
import { Button, Grid, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { CurrencyInput}  from "../../../../common/CurrencyInput";
import { parseCurrencyValue } from "../../../../../util/PaymentUtils";
import Busy from "../../../../Busy";
import request from '../../../../../util/SuperagentUtils';
import { CallbackActions } from "../InvoiceActions";

const AddRefundCreditModal = (props: {
  invoice: {transactionId: string},
  open: boolean,
  onInvoiceAction: (callbackActions: any) => void,
  onClose: (callbackActions: any) => void,
}) => {
  const { invoice, open, onInvoiceAction, onClose } = props;

  const handleClose = () => {
    onClose();
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries((formData).entries());

    const url = "api/invoices/add-refund-credit";
    const payload = {
      bookingTransactionId: invoice.transactionId,
      refundCreditAmount: parseCurrencyValue(formJson.refundCreditAmount),
      reasonForRefund: formJson.reason
    };

    Busy.set(true);
    request.post(url).send(payload).then(() => {
      onClose(CallbackActions.SUCCESS, invoice, "Successfully retried charging failed payment!");
    }).catch((error) => {
      onInvoiceAction(CallbackActions.ERROR, invoice, `${error}`);
    }).finally(() => {
      Busy.set(false);
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit
      }}
    >
      <DialogTitle>Add Refund Credit</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter the refund credit amount and reason for refund
        </DialogContentText>
        <CurrencyInput
          prefix="$"
          autoFocus
          required
          margin="dense"
          name="refundCreditAmount"
          label="Refund Credit Amount"
          helperText="Enter the amount to refund to this invoice."
          fullWidth
          variant="standard"
          maxLength={8}
        /><br/><br/>
        <TextField
          required
          margin="dense"
          id="outlined-multiline-static"
          name="reason"
          label="Reason for Refund"
          helperText="Enter the reason this refund is being given."
          multiline
          rows={10}
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={"center"}>
          <Button variant="text" onClick={handleClose} >Cancel</Button>
          <Button variant="contained" type="submit">Add Refund Credit</Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
export default AddRefundCreditModal;