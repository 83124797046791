export const BuyerInvoiceStatusGroup = Object.freeze({
  BOOKING_DECLINED: {value: "BOOKING_DECLINED", label: "Booking Declined"},
  CHARGE_PENDING: {value: "CHARGE_PENDING", label: "Charge Pending"},
  INCOMPLETE: {value: "INCOMPLETE", label: "Booking Incomplete"},
  NEEDS_CUSTOMER_APPROVAL: {value: "NEEDS_CUSTOMER_APPROVAL", label: "Awaiting Customer Approval"},
  NOT_APPROVED: {value: "NOT_APPROVED", label: "Approval Pending"},
  REFUND_REQUESTED: {value: "REFUND_REQUESTED", label: "Refund Requested"},
  REFUND_DENIED: {value: "REFUND_DENIED", label: "Refund Denied"},
  MANUAL_PAYMENT: {value: "MANUAL_PAYMENT", label: "Manual Payment"},
  PAYMENT_FAILED: {value: "PAYMENT_FAILED", label: "Payment Failed"},
  PAYMENT_PENDING: {value: "PAYMENT_PENDING", label: "Payment Pending"},
  PAYMENT_SUCCEEDED: {value: "PAYMENT_SUCCEEDED", label: "Complete"},
  UNCOLLECTIBLE: {value: "UNCOLLECTIBLE", label: "Uncollectible"},
  CANCELLED: {value: "CANCELLED", label: "Cancelled"},
  DECLINED: {value: "DECLINED", label: "Declined"},
});

export const SupplierInvoiceStatusGroup = Object.freeze({
  BOOKING_DECLINED: {value: "BOOKING_DECLINED", label: "Booking Declined"},
  CHARGE_ON_HOLD: {value: "CHARGE_ON_HOLD", label: "Charge On Hold"},
  CHARGE_PENDING: {value: "CHARGE_PENDING", label: "Charge Pending"},
  INCOMPLETE: {value: "INCOMPLETE", label: "Incomplete"},
  REFUND_REQUESTED: {value: "REFUND_REQUESTED", label: "Refund Requested"},
  REFUND_DENIED: {value: "REFUND_DENIED", label: "Refund Denied"},
  MANUAL_PAYMENT: {value: "MANUAL_PAYMENT", label: "Manual Payment"},
  NOT_APPROVED: {value: "NOT_APPROVED", label: "Approval Pending"},
  PAYMENT_FAILED: {value: "PAYMENT_FAILED", label: "Payment Failed"},
  PAYMENT_PENDING: {value: "PAYMENT_PENDING", label: "Payment Pending"},
  PAYOUT_FAILED: {value: "PAYOUT_FAILED", label: "Payout Failed"},
  PAYOUT_ON_HOLD: {value: "PAYOUT_ON_HOLD", label: "Payout On Hold"},
  PAYOUT_PENDING: {value: "PAYOUT_PENDING", label: "Payout Pending"},
  PAYOUT_READY: {value: "PAYOUT_READY", label: "Payout Ready"},
  PAYOUT_SUCCEEDED: {value: "PAYOUT_SUCCEEDED", label: "Complete"},
  UNCOLLECTIBLE: {value: "UNCOLLECTIBLE", label: "Uncollectible"},
  CANCELLED: {value: "CANCELLED", label: "Cancelled"},
  DECLINED: {value: "DECLINED", label: "Declined"},
});

export const BookingTransactionStatus = Object.freeze({
  BOOKING_INCOMPLETE: {
    value: "BOOKING_INCOMPLETE",
    label: "Booking Incomplete",
    description: "The Booking for this Booking Transaction has not been completed by the Buyer",
    transactionOpen: true,
    transactionInitiated: false,
    transactionSettledForSupplier: false,
    transactionSettledForSecurSpace: false,
    buyerInvoiceStatusGroup: BuyerInvoiceStatusGroup.INCOMPLETE,
    supplierInvoiceStatusGroup: SupplierInvoiceStatusGroup.INCOMPLETE
  },
  CHARGE_PENDING: {
    value: "CHARGE_PENDING",
    label: "Charge Pending",
    description: "The charge for this Booking Transaction has not been initiated by SecurSpace",
    transactionOpen: true,
    transactionInitiated: false,
    transactionSettledForSupplier: false,
    transactionSettledForSecurSpace: false,
    buyerInvoiceStatusGroup: BuyerInvoiceStatusGroup.CHARGE_PENDING,
    supplierInvoiceStatusGroup: SupplierInvoiceStatusGroup.CHARGE_PENDING
  },
  CHARGE_ON_HOLD: {
    value: "CHARGE_ON_HOLD",
    label: "Charge On Hold",
    description: "The charge for this Booking Transaction on hold and will not be charged",
    transactionOpen: true,
    transactionInitiated: false,
    transactionSettledForSupplier: false,
    transactionSettledForSecurSpace: false,
    buyerInvoiceStatusGroup: BuyerInvoiceStatusGroup.NEEDS_CUSTOMER_APPROVAL,
    supplierInvoiceStatusGroup: SupplierInvoiceStatusGroup.CHARGE_ON_HOLD
  },
  PAYMENT_NOT_SUBMITTED: {
    value: "PAYMENT_NOT_SUBMITTED",
    label: "Payment Not Submitted",
    description: "The payment has not been submitted to the payment processor.",
    transactionOpen: true,
    transactionInitiated: false,
    transactionSettledForSupplier: false,
    transactionSettledForSecurSpace: false,
    buyerInvoiceStatusGroup: BuyerInvoiceStatusGroup.NOT_APPROVED,
    supplierInvoiceStatusGroup: SupplierInvoiceStatusGroup.NOT_APPROVED
  },
  REFUND_REQUESTED: {
    value: "REFUND_REQUESTED",
    label: "Refund has been requested.",
    description: "This refund is being reviewed and can only be completed by Lance.",
    transactionOpen: true,
    transactionInitiated: false,
    transactionSettledForSupplier: false,
    transactionSettledForSecurSpace: false,
    buyerInvoiceStatusGroup: BuyerInvoiceStatusGroup.REFUND_REQUESTED,
    supplierInvoiceStatusGroup: SupplierInvoiceStatusGroup.REFUND_REQUESTED
  },
  REFUND_DENIED: {
    value: "REFUND_DENIED",
    label: "Refund request has been denied.",
    description: "This refund request has been denied.",
    transactionOpen: true,
    transactionInitiated: false,
    transactionSettledForSupplier: false,
    transactionSettledForSecurSpace: false,
    buyerInvoiceStatusGroup: BuyerInvoiceStatusGroup.REFUND_DENIED,
    supplierInvoiceStatusGroup: SupplierInvoiceStatusGroup.REFUND_DENIED
  },
  MANUAL_PAYMENT: {
    value: "MANUAL_PAYMENT",
    label: "Payment Is Paid Manually",
    description: "The payment for this transaction is collected outside of the SecurSpace system.",
    transactionOpen: false,
    transactionInitiated: true,
    transactionSettledForSupplier: true,
    transactionSettledForSecurSpace: true,
    buyerInvoiceStatusGroup: BuyerInvoiceStatusGroup.MANUAL_PAYMENT,
    supplierInvoiceStatusGroup: SupplierInvoiceStatusGroup.MANUAL_PAYMENT
  },
  PAYMENT_AUTHORIZED: {
    value: "PAYMENT_AUTHORIZED",
    label: "Payment Authorized",
    description: "The payment processor has verified this charge will succeed, but no charge has been made.",
    transactionOpen: true,
    transactionInitiated: false,
    transactionSettledForSupplier: false,
    transactionSettledForSecurSpace: false,
    buyerInvoiceStatusGroup: BuyerInvoiceStatusGroup.NOT_APPROVED,
    supplierInvoiceStatusGroup: SupplierInvoiceStatusGroup.NOT_APPROVED
  },
  PAYMENT_CANCELLED: {
    value: "PAYMENT_CANCELLED",
    label: "Payment Cancelled",
    description: "The payment was authorized, then cancelled before an actual charge was made.",
    transactionOpen: false,
    transactionInitiated: false,
    transactionSettledForSupplier: true,
    transactionSettledForSecurSpace: true,
    buyerInvoiceStatusGroup: BuyerInvoiceStatusGroup.BOOKING_DECLINED,
    supplierInvoiceStatusGroup: SupplierInvoiceStatusGroup.BOOKING_DECLINED
  },
  PAYMENT_PENDING: {
    value: "PAYMENT_PENDING",
    label: "Payment Pending",
    description: "This is an ACH transaction. The ACH transfer has been initiated and is currently being processed by the banks involved in the transfer.",
    transactionOpen: true,
    transactionInitiated: true,
    transactionSettledForSupplier: false,
    transactionSettledForSecurSpace: false,
    buyerInvoiceStatusGroup: BuyerInvoiceStatusGroup.PAYMENT_PENDING,
    supplierInvoiceStatusGroup: SupplierInvoiceStatusGroup.PAYMENT_PENDING
  },
  PAYMENT_SUCCEEDED: {
    value: "PAYMENT_SUCCEEDED",
    label: "Payment Succeeded",
    description: "Payment for this transaction completed successfully and the Buyer's account has been debited/credited the transaction amount.",
    transactionOpen: true,
    transactionInitiated: true,
    transactionSettledForSupplier: false,
    transactionSettledForSecurSpace: false,
    buyerInvoiceStatusGroup: BuyerInvoiceStatusGroup.PAYMENT_SUCCEEDED,
    supplierInvoiceStatusGroup: SupplierInvoiceStatusGroup.PAYMENT_PENDING
  },
  PAYMENT_FAILED: {
    value: "PAYMENT_FAILED",
    label: "Payment Failed",
    description: "The payment or refund failed to process successfully.",
    transactionOpen: false,
    transactionInitiated: false,
    transactionSettledForSupplier: false,
    transactionSettledForSecurSpace: false,
    buyerInvoiceStatusGroup: BuyerInvoiceStatusGroup.PAYMENT_FAILED,
    supplierInvoiceStatusGroup: SupplierInvoiceStatusGroup.PAYMENT_FAILED
  },
  SECURSPACE_PAYOUT_PENDING: {
    value: "SECURSPACE_PAYOUT_PENDING",
    label: "SecurSpace Payout Pending",
    description: "A payout has been created to transfer the funds for this Booking Transaction from the payment processor to SecurSpace, but is not complete yet.",
    transactionOpen: true,
    transactionInitiated: true,
    transactionSettledForSupplier: false,
    transactionSettledForSecurSpace: false,
    buyerInvoiceStatusGroup: BuyerInvoiceStatusGroup.PAYMENT_SUCCEEDED,
    supplierInvoiceStatusGroup: SupplierInvoiceStatusGroup.PAYMENT_PENDING
  },
  SECURSPACE_PAYOUT_SUCCEEDED: {
    value: "SECURSPACE_PAYOUT_SUCCEEDED",
    label: "SecurSpace Payout Succeeded",
    description: "The funds for this Booking Transaction have been successfully transferred from the payment processor to SecurSpace.",
    transactionOpen: true,
    transactionInitiated: true,
    transactionSettledForSupplier: false,
    transactionSettledForSecurSpace: false,
    buyerInvoiceStatusGroup: BuyerInvoiceStatusGroup.PAYMENT_SUCCEEDED,
    supplierInvoiceStatusGroup: SupplierInvoiceStatusGroup.PAYOUT_READY
  },
  SECURSPACE_PAYOUT_FAILED: {
    value: "SECURSPACE_PAYOUT_FAILED",
    label: "SecurSpace Payout Failed",
    description: "The funds for this Booking Transaction have failed to transfer from the payment processor to SecurSpace.",
    transactionOpen: false,
    transactionInitiated: true,
    transactionSettledForSupplier: false,
    transactionSettledForSecurSpace: false,
    buyerInvoiceStatusGroup: BuyerInvoiceStatusGroup.PAYMENT_SUCCEEDED,
    supplierInvoiceStatusGroup: SupplierInvoiceStatusGroup.PAYMENT_FAILED
  },
  SECURSPACE_PAYOUT_CANCELED: {
    value: "SECURSPACE_PAYOUT_CANCELED",
    label: "SecurSpace Payout Canceled",
    description: "The transfer from the payment processor to SecurSpace has been cancelled.",
    transactionOpen: false,
    transactionInitiated: true,
    transactionSettledForSupplier: false,
    transactionSettledForSecurSpace: false,
    buyerInvoiceStatusGroup: BuyerInvoiceStatusGroup.PAYMENT_SUCCEEDED,
    supplierInvoiceStatusGroup: SupplierInvoiceStatusGroup.PAYMENT_FAILED
  },
  SUPPLIER_PAYOUT_INITIATING: {
    value: "SUPPLIER_PAYOUT_INITIATING",
    label: "Supplier Payout Initiating",
    description: "The SecurSpace Fee and Supplier Payout ACH transfers are in the process of being created, but are not yet created.",
    transactionOpen: true,
    transactionInitiated: true,
    transactionSettledForSupplier: false,
    transactionSettledForSecurSpace: false,
    buyerInvoiceStatusGroup: BuyerInvoiceStatusGroup.PAYMENT_SUCCEEDED,
    supplierInvoiceStatusGroup: SupplierInvoiceStatusGroup.PAYOUT_PENDING
  },
  SUPPLIER_PAYOUT_PENDING: {
    value: "SUPPLIER_PAYOUT_PENDING",
    label: "Supplier Payout Pending",
    description: "The SecurSpace Fee and Supplier Payout ACH transfers have been created, but are not yet complete.",
    transactionOpen: true,
    transactionInitiated: true,
    transactionSettledForSupplier: false,
    transactionSettledForSecurSpace: false,
    buyerInvoiceStatusGroup: BuyerInvoiceStatusGroup.PAYMENT_SUCCEEDED,
    supplierInvoiceStatusGroup: SupplierInvoiceStatusGroup.PAYOUT_PENDING
  },
  SUPPLIER_PAYOUT_ON_HOLD: {
    value: "SUPPLIER_PAYOUT_ON_HOLD",
    label: "Supplier Payout On Hold",
    description: "The payout is being held due to an active dispute.",
    transactionOpen: true,
    transactionInitiated: true,
    transactionSettledForSupplier: false,
    transactionSettledForSecurSpace: false,
    buyerInvoiceStatusGroup: BuyerInvoiceStatusGroup.PAYMENT_SUCCEEDED,
    supplierInvoiceStatusGroup: SupplierInvoiceStatusGroup.PAYOUT_ON_HOLD
  },
  SUPPLIER_PAYOUT_SUCCEEDED: {
    value: "SUPPLIER_PAYOUT_SUCCEEDED",
    label: "Supplier Payout Succeeded",
    description: "The SecurSpace Fee and Supplier Payout ACH transfers both completed successfully.",
    transactionOpen: false,
    transactionInitiated: true,
    transactionSettledForSupplier: true,
    transactionSettledForSecurSpace: true,
    buyerInvoiceStatusGroup: BuyerInvoiceStatusGroup.PAYMENT_SUCCEEDED,
    supplierInvoiceStatusGroup: SupplierInvoiceStatusGroup.PAYOUT_SUCCEEDED
  },
  SUPPLIER_PAYOUT_FAILED_SECURSPACE_FEE_PAYOUT_SUCCEEDED: {
    value: "SUPPLIER_PAYOUT_FAILED_SECURSPACE_FEE_PAYOUT_SUCCEEDED",
    label: "Supplier Payout Failed, SecurSpace Fee Payout Succeeded",
    description: "The Supplier Payout ACH transfer failed but the SecurSpace Fee ACH transfer succeeded.",
    transactionOpen: false,
    transactionInitiated: true,
    transactionSettledForSupplier: false,
    transactionSettledForSecurSpace: true,
    buyerInvoiceStatusGroup: BuyerInvoiceStatusGroup.PAYMENT_SUCCEEDED,
    supplierInvoiceStatusGroup: SupplierInvoiceStatusGroup.PAYOUT_FAILED
  },
  SUPPLIER_PAYOUT_SUCCEEDED_SECURSPACE_FEE_PAYOUT_FAILED: {
    value: "SUPPLIER_PAYOUT_SUCCEEDED_SECURSPACE_FEE_PAYOUT_FAILED",
    label: "Supplier Payout Succeeded, SecurSpace Fee Payout Failed",
    description: "The Supplier Payout ACH transfer succeeded but the SecurSpace Fee ACH transfer failed.",
    transactionOpen: false,
    transactionInitiated: true,
    transactionSettledForSupplier: true,
    transactionSettledForSecurSpace: false,
    buyerInvoiceStatusGroup: BuyerInvoiceStatusGroup.PAYMENT_SUCCEEDED,
    supplierInvoiceStatusGroup: SupplierInvoiceStatusGroup.PAYOUT_SUCCEEDED
  },
  SUPPLIER_PAYOUT_FAILED: {
    value: "SUPPLIER_PAYOUT_FAILED",
    label: "Supplier Payout Failed",
    description: "The SecurSpace Fee and Supplier Payout ACH transfers both failed.",
    transactionOpen: false,
    transactionInitiated: true,
    transactionSettledForSupplier: false,
    transactionSettledForSecurSpace: false,
    buyerInvoiceStatusGroup: BuyerInvoiceStatusGroup.PAYMENT_SUCCEEDED,
    supplierInvoiceStatusGroup: SupplierInvoiceStatusGroup.PAYOUT_FAILED
  },
  UNCOLLECTIBLE: {
    value: "UNCOLLECTIBLE",
    label: "Supplier Payout is Uncollectible",
    description: "The Payout has been marked uncollectible by the Super Admin",
    transactionOpen: false,
    transactionInitiated: true,
    transactionSettledForSupplier: false,
    transactionSettledForSecurSpace: false,
    buyerInvoiceStatusGroup: BuyerInvoiceStatusGroup.UNCOLLECTIBLE,
    supplierInvoiceStatusGroup: SupplierInvoiceStatusGroup.UNCOLLECTIBLE
  },
  CANCEL_TERM_CHANGES: {
    value: "CANCEL_TERM_CHANGES",
    label: "Supplier Payout Cancelled",
    description: "The Payout has been cancelled due to term changes",
    transactionOpen: false,
    transactionInitiated: true,
    transactionSettledForSupplier: false,
    transactionSettledForSecurSpace: false,
    buyerInvoiceStatusGroup: BuyerInvoiceStatusGroup.CANCELLED,
    supplierInvoiceStatusGroup: SupplierInvoiceStatusGroup.CANCELLED
  },
  CANCEL_BOOKING_CANCELLED: {
    value: "CANCEL_BOOKING_CANCELLED",
    label: "Supplier Payout is Cancelled",
    description: "The Booking and Payout have been cancelled",
    transactionOpen: false,
    transactionInitiated: true,
    transactionSettledForSupplier: false,
    transactionSettledForSecurSpace: false,
    buyerInvoiceStatusGroup: BuyerInvoiceStatusGroup.CANCELLED,
    supplierInvoiceStatusGroup: SupplierInvoiceStatusGroup.CANCELLED
  },
  CANCEL_BILLING_REQUIREMENTS: {
    value: "CANCEL_BILLING_REQUIREMENTS",
    label: "Supplier Payout is Cancelled",
    description: "The Payout has been cancelled due to billing requirements",
    transactionOpen: false,
    transactionInitiated: true,
    transactionSettledForSupplier: false,
    transactionSettledForSecurSpace: false,
    buyerInvoiceStatusGroup: BuyerInvoiceStatusGroup.CANCELLED,
    supplierInvoiceStatusGroup: SupplierInvoiceStatusGroup.CANCELLED
  },
  CANCEL_OTHER: {
    value: "CANCEL_OTHER",
    label: "Supplier Payout is Cancelled",
    description: "The Payout has been cancelled",
    transactionOpen: false,
    transactionInitiated: true,
    transactionSettledForSupplier: false,
    transactionSettledForSecurSpace: false,
    buyerInvoiceStatusGroup: BuyerInvoiceStatusGroup.CANCELLED,
    supplierInvoiceStatusGroup: SupplierInvoiceStatusGroup.CANCELLED
  },
  DECLINED: {
    value: "DECLINED",
    label: "Charge is Declined",
    description: "Charge has been declined and must be resolved manually",
    transactionOpen: false,
    transactionInitiated: false,
    transactionSettledForSupplier: false,
    transactionSettledForSecurSpace: false,
    buyerInvoiceStatusGroup: BuyerInvoiceStatusGroup.DECLINED,
    supplierInvoiceStatusGroup: SupplierInvoiceStatusGroup.DECLINED
  },
});

export const DwollaTransferStatus = Object.freeze({
  none: {label: "none", description: "Not an actual Dwolla Transfer status, but represents the state of no Dwolla Transfer existing."},
  pending: {label: "pending", description: "The Dwolla Transfer has been created, submitted, and the money has yet to leave the funding source or it is en route to its destination."},
  processed: {label: "processed", description: "The ACH transfer has cleared successfully.  A processed transfer represents money that has reached its destination account."},
  failed: {label: "failed", description: "The ACH transfer failed to clear successfully.  No money has been transferred."},
  cancelled: {label: "cancelled", description: "The ACH transfer was cancelled within the available cancellation window."},
});

export const PaymentProcessor = Object.freeze({
  STRIPE: {value: "STRIPE", label: "Stripe"},
  DWOLLA: {value: "DWOLLA", label: "Dwolla"},
});

export const SubscriptionType = Object.freeze({
  MARKETPLACE_ONLY: {value: "MARKETPLACE_ONLY", label: "Marketplace Only"},
  GMS_LITE: {value: "GMS_LITE", label: "GMS Lite"},
  GMS_PRO: {value: "GMS_PRO", label: "GMS Pro"}
});