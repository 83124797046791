import React from 'react';
import {Box, Grid} from "@mui/material";
import { BookingLoadingSkeleton, Pagination } from '@securspace/securspace-ui-kit';
import BookingCardList from './BookingCardList';
import TextBox from '../bookingsOverview/TextBox';
import { withSnackbar } from '../hocs/withSnackbar';
import { headerCells } from './data';
import type { Booking } from '../../types/Booking';

const BookingTab = (props: { bookings: Array<Booking>, loading: boolean, pageable: {page: Number, size: Number},  setPageable: () => {}, count: Number}) => {
    const {bookings, loading, pageable, setPageable, count} = props;

  const handlePageChange: (event, value: number) => void = (_, selectedPage) => {
    if(selectedPage) {
      setPageable((prevState) => ({ ...prevState, page: selectedPage }))
    }
  };

  const handleSizeChange = (event) => {
      if(event) {
        setPageable((prevState) => ({ ...prevState, size: event.target?.value }))
      }
  };

  return (
    <Box>
        {
            loading ? 
            <BookingLoadingSkeleton/>
            :
              <Box>
                  <TextBox title="An approved booking that is current or in the future." />
                  <BookingCardList bookings={bookings} headerCells={headerCells}/>
              </Box>
        }
        {
                    bookings?.length > 0 && <Grid item>
                        <Pagination
                            count={count}
                            rowsPerPage={pageable?.size}
                            page={pageable?.page}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleSizeChange}
                            variant="tablePagination"
                        />
                    </Grid>
                }
    </Box>
  );
};

export default withSnackbar(BookingTab);
