import { AccountType, UserType } from "../components/constants/securspace-constants";

const hasUserType = (user, ...userTypeSets) => {
  return userTypeSets?.reduce((accumulator, userTypeSet) => accumulator || userTypeSet?.includes(user?.userType), false );
}

const getAdminUserTypes = () => {
  return [
    UserType.ADMIN,
    UserType.SUPER_ADMIN
  ];
}

const getOwnerUserTypes = () => {
  return [
    UserType.OWNER,
    UserType.PRIMARY_OWNER
  ];
}

const getBuyerUserTypes = () => {
  return [
    UserType.OWNER,
    UserType.PRIMARY_OWNER,
    UserType.DISPATCHER
  ];
}

const getSupplierUserTypes = () => {
  return [
    UserType.OWNER,
    UserType.PRIMARY_OWNER,
    UserType.GATE_MANAGER,
    UserType.GATE_CLERK
  ];
}

const getGateManagerUserTypes = () => {
  return [
    UserType.GATE_MANAGER
  ];
}

const getGateOperatorUserTypes = () => {
  return [
    UserType.GATE_MANAGER,
    UserType.GATE_CLERK
  ];
}

const isBuyerAccountType = (accountType) => {
  return accountType === AccountType.BUYER;
}

const isSupplierAccountType = (accountType) => {
  return accountType === AccountType.SUPPLIER;
}

const isAdminAccountType = (accountType) => {
  return accountType === AccountType.ADMIN;
}

const isBuyerAccount = (user) => {
  return isBuyerAccountType(user?.type);
}

const isSupplierAccount = (user) => {
  return isSupplierAccountType(user?.type);
}

const isAdminAccount = (user) => {
  return isAdminAccountType(user?.type);
}

const hasSuperAdminAccess = (user) => {
  return (user?.userType === UserType.SUPER_ADMIN);
}

const hasAdminAccess = (user) => {
  return hasUserType(user, getAdminUserTypes());
}

const hasPrimaryOwnerAccess = (user) => {
  return (user?.userType === UserType.PRIMARY_OWNER) || hasAdminAccess(user);
}

const hasOwnerAccess = (user) => {
  return hasUserType(user, getOwnerUserTypes(), getAdminUserTypes());
}

const hasGateManagerAccess = (user) => {
  return hasUserType(user, getGateManagerUserTypes(), getOwnerUserTypes(), getAdminUserTypes());
}

const hasGateClerkAccess = (user) => {
  return hasUserType(user, getGateOperatorUserTypes(), getOwnerUserTypes(), getAdminUserTypes());
}

const hasDispatcherAccess = (user) => {
  return hasUserType(user, getBuyerUserTypes(), getAdminUserTypes());
}

const hasValidUserType = (user) => {
  return user?.userType && Object.values(UserType).includes(user.userType);
}

export {
  getAdminUserTypes,
  getOwnerUserTypes,
  getBuyerUserTypes,
  getSupplierUserTypes,
  getGateManagerUserTypes,
  getGateOperatorUserTypes,

  hasSuperAdminAccess,
  hasAdminAccess,
  hasPrimaryOwnerAccess,
  hasOwnerAccess,
  hasGateManagerAccess,
  hasGateClerkAccess,
  hasDispatcherAccess,
  hasValidUserType,

  isBuyerAccountType,
  isSupplierAccountType,
  isAdminAccountType,
  isBuyerAccount,
  isSupplierAccount,
  isAdminAccount
};
