import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { CSVButton } from '@securspace/securspace-ui-kit';
import PartnerSubscriptionsMap from '../components/partner-subscriptions/PartnerSubscriptionsMap';
import { getAllPartnerSubscriptions } from '../components/partner-subscriptions/request/partner-subscription-requests';
import useStyles from '../components/partner-subscriptions/styles';
import { formatSubscriptionItem, formatSubscriptionItemsForCSV } from '../components/partner-subscriptions/formatSubscriptions';
import SecurSpaceContainer from '../components/common/SecurSpaceContainer';
import moment from 'moment';

const PartnerSubscriptions = ({account}) => {
    const classes = useStyles();

    const [subscriptions, setSubscriptions] = useState([]);

    useEffect(() => {
        getAllPartnerSubscriptions(account)
            .then(({body}) => {
                const formattedItems = body.map(item => formatSubscriptionItem(item));
                setSubscriptions(formattedItems)
            })
    }, [account])


    const reportName = `Supplier Subscriptions ${moment().format("MM/DD/YYYY hh:mm A")}`;

    return (
        <SecurSpaceContainer>
            <Box component='section' className={classes.mainContainer}>
                <Box mt={4} className={classes.header}>
                <Typography variant={'h5'} component={'h1'}>Supplier Subscriptions</Typography>
                    <CSVButton
                        reportData={formatSubscriptionItemsForCSV(subscriptions)}
                        reportName={reportName}
                        title="Download Report"
                    />
                </Box>
                <PartnerSubscriptionsMap
                    subscriptions={subscriptions}
                />
            </Box>
        </SecurSpaceContainer>
    );
};

export default PartnerSubscriptions;