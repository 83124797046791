const headerCells = [
  {
    id: 'transactionNumber',
    label: 'Invoice Number'
  },
  {
    id: 'supplierAmount',
    label: 'Amount'
  },
  {
    id: 'transactionType',
    label: 'Invoice Type'
  },
  {
    id: 'serviceDates',
    label: 'Service Dates'
  },
  {
    id: 'locationName',
    label: 'Location'
  },
  {
    id: 'buyerCompanyName',
    label: 'Buyer'
  },
  {
    id: 'transferType',
    label: 'Type of Charge'
  },
  {
    id: 'numberOfSpaces',
    label: 'Spaces'
  },
  {
    id: 'status',
    label: 'Status'
  },
  {
    id: 'createdOn',
    label: 'Invoice Created Date'
  },
  {
    id: 'paymentCreatedOn',
    label: 'Payment Initiated Date'
  },
  {
    id: 'paymentCompletedOn',
    label: 'Process Completed Date'
  },
  {
    id: 'payoutCreatedOn',
    label: 'Payout Initiated'
  },
  {
    id: 'payoutCompletedOn',
    label: 'Payout Completed'
  },
  {
    id: 'paymentType',
    label: 'Payment Type'
  },
  {
    id: 'createdOn',
    label: 'Charged Date'
  },
  {
    id: 'bookingNumber',
    label: 'Booking'
  },
  {
    id: 'assetType',
    label: 'Equipment Type'
  },
  {
    id: 'serviceFeeType',
    label: 'Service Charge Type'
  },
];
const sortByFields = [
  { label: "Created On", value: "createdOn" },
  { label: "Invoice Number", value: "transactionNumber" },
  { label: "Invoice Type", value: "transactionType" },
  { label: "Amount", value: "supplierAmount" },
  { label: "Location", value: "locationName" },
  { label: "Buyer", value: "buyerCompanyName" },
  { label: "Asset Type", value: "assetType" },
  { label: "Booking", value: "bookingNumber" }
];


const formatDataForCSVDownload = (reportData) => {
    const formattedData =  reportData?.map(item => {
        return {
            location: item.locationName,
            buyer: item.buyerCompanyName,
            type: item.transactionType,
            spaces: item.numberOfSpaces,
            amount: item.supplierAmount,
            status: item.status,
            serviceStartDate: item.serviceStartDate,
            serviceEndDate: item.serviceEndDate,
            invoiceCreated: item.createdOn,
            paymentInitiated: item.paymentCreatedOn,
            processCompleted: item.paymentCompletedOn,
            payoutInitiated: item.payoutCreatedOn,
            payoutCompleted: item.payoutCompletedOn,
            invoiceNumber: item.transactionNumber,
            paymentType: item.paymentType,
            chargedDate: item.createdOn,
            booking: item.bookingNumber,
            equipmentType: item.assetType,
            notes: item.notes
        }
    });
    return formattedData
}

export {
    headerCells,
    sortByFields,
    formatDataForCSVDownload
}