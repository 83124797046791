import React, {useState} from 'react';
import {FormControl, InputLabel, MenuItem, Select, TextField, Theme} from "@mui/material";
import {DwollaBankAccountType} from "./constants/securspace-constants";
import {SyntheticEvent} from "react";
import { makeStyles } from '@mui/styles';

const useStyles: (theme: Theme) => {
  form: CSSStyleSheet,
} = makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1.43rem',
    padding: '0.57rem 0rem',
  }
}));

const AddFundingSourceForm = (props: {
  formId: string,
  onChange: () => void,
  onSubmit: () => void,
  accountType: DwollaBankAccountType,
  routingNumber: string,
  accountNumber: string,
  confirmAccountNumber: string,
  nickName: string
}) => {
  const {formId, onChange, onSubmit, accountType, routingNumber, accountNumber, confirmAccountNumber, nickName} = props;
  const [confirmAccountNumberError, setConfirmAccountNumberError] = useState('');

  const handleConfirmAccountNumberChange = (event: SyntheticEvent) => {
    const {name, value} = event.target;
    if (name === 'accountNumber' && confirmAccountNumber && value !== confirmAccountNumber) {
      setConfirmAccountNumberError('Account numbers do not match');
    } else if (name === 'confirmAccountNumber' && accountNumber && value !== accountNumber) {
      setConfirmAccountNumberError('Account numbers do not match');
    } else {
      setConfirmAccountNumberError('');
    }
    onChange(event);
  }

  const classes = useStyles();

  return (
    <form id={formId} onSubmit={onSubmit} noValidate className={classes.form}>
      <FormControl variant='standard' fullWidth required>
        <InputLabel id={'add-funding-source-select-account-label'}>Account Type</InputLabel>
        <Select
          labelId={'add-funding-source-select-account-label'}
          name={'accountType'}
          value={accountType}
          onChange={onChange}
        >
          <MenuItem value={""} disabled>Choose</MenuItem>
          <MenuItem value={DwollaBankAccountType.CHECKING}>Checking</MenuItem>
          <MenuItem value={DwollaBankAccountType.SAVINGS}>Saving</MenuItem>
        </Select>
      </FormControl>
      <TextField
        name={'routingNumber'}
        label={'Routing Number'}
        placeholder={"Enter your bank's routing number"}
        value={routingNumber}
        onChange={onChange}
        required
        fullWidth
        inputMode={'numeric'}
        autoComplete={'off'}
        variant='standard'
      />
      <TextField
        name={'accountNumber'}
        label={'Account Number'}
        placeholder={"Enter your bank account number"}
        value={accountNumber}
        onChange={handleConfirmAccountNumberChange}
        required
        fullWidth
        inputMode={'numeric'}
        variant='standard'
        autoComplete={'off'}
      />
      <TextField
        name={'confirmAccountNumber'}
        label={'Confirm Account Number'}
        placeholder={"Re-enter your bank account number"}
        value={confirmAccountNumber}
        onChange={handleConfirmAccountNumberChange}
        required
        fullWidth
        helperText={confirmAccountNumberError}
        error={confirmAccountNumberError !== ''}
        inputMode={'numeric'}
        autoComplete={'off'}
        variant='standard'
      />
      <TextField
        name={'nickName'}
        label={'Nickname'}
        placeholder={"Enter a nickname for this bank account"}
        value={nickName}
        onChange={onChange}
        required
        fullWidth
        variant='standard'
      />
    </form>
  );
};

export default AddFundingSourceForm;