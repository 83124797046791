import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import {SortFilter} from "@securspace/securspace-ui-kit";
import {filterByOptions, filterBySelectOptions, sortByOptions} from "./data";
import {Grid} from "@mui/material";
import {DateFormats} from "../../../../constants/securspace-constants";
import {getAssetTypeGroupOptionsTypes, getTaxStatusTypes} from "../../../../../requests/type-requests";
import {withSnackbar} from "../../../../hocs/withSnackbar";
import {getErrorMessageForNonStandardAndStandardResponse} from "../../../../../util/NetworkErrorUtil";
import moment from "moment/moment";
import type {AdminInvoicesQueryType} from "../AdminInvoicesQueryType";

type InvoiceSortFilterProps = {
  setQuery: (query: any) => void,
  initialQuery: AdminInvoicesQueryType,
  snackbarShowMessage: (message: Object, severity: String, duration: Number, position?: {}) => void,
};

const InvoiceSortFilter: (props: InvoiceSortFilterProps) => JSX.Element = memo(({
  setQuery,
  initialQuery,
  snackbarShowMessage
}) => {
  const initialRender = useRef(true);
  const [filterBySelectOption, setFilterBySelectOption] = useState(filterBySelectOptions);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [assetTypeGroupOptionsResponse, taxStatusResponse] = await Promise.all([
          getAssetTypeGroupOptionsTypes(),
          getTaxStatusTypes(),
        ]);

        let assetTypeGroupOptions = [{label: 'All', value: ''}];
        assetTypeGroupOptions = assetTypeGroupOptions.concat(assetTypeGroupOptionsResponse.body.map(assetTypeGroupOption => ({
          label: assetTypeGroupOption.label,
          value: assetTypeGroupOption.value,
        })));

        let taxStatuses = [{label: 'All', value: ''}];
        taxStatuses = taxStatuses.concat(taxStatusResponse.body.map(taxStatus => ({
          label: taxStatus.label,
          value: taxStatus.value,
        })));

        setFilterBySelectOption(prevState => [
          ...prevState,
          {
            name: 'assetTypeGroup',
            value: '',
            label: 'Asset Type',
            option: assetTypeGroupOptions
          },
          {
            name: 'taxStatus',
            value: '',
            label: 'Tax Status',
            option: taxStatuses
          },
        ].map(filter => {
          return {
            ...filter,
            value: initialQuery[filter.name] ? initialQuery[filter.name] : filter.value
          }
        }));

      } catch (error) {
        snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(error), 'error');
      }
    };

    fetchData();
  }, [initialQuery, snackbarShowMessage]);

  const handleCallback = useCallback((e) => {
    if (!initialRender.current) {
      setQuery(prevState => {
        return {
          ...prevState,
          bookingNumber: e.filters.find(filter => filter.name === 'bookingNumber')?.value || '',
          buyerCompanyName: e.filters.find(filter => filter.name === 'buyerCompanyName')?.value || '',
          endDate: moment(e.endDate).format(DateFormats.DAY),
          startDate: moment(e.startDate).format(DateFormats.DAY),
          supplierCompanyName: e.filters.find(filter => filter.name === 'supplierCompanyName')?.value || '',
          transactionNumber: e.filters.find(filter => filter.name === 'transactionNumber')?.value || '',
          // Select Filters
          assetTypeGroup: e.selectFilters.find(filter => filter.name === 'assetTypeGroup')?.value || '',
          bookingStatus: e.selectFilters.find(filter => filter.name === 'bookingStatus')?.value || '',
          paymentProcessor: e.selectFilters.find(filter => filter.name === 'paymentProcessor')?.value || '',
          supplierTransactionStatus: e.selectFilters.find(filter => filter.name === 'supplierTransactionStatus')?.value || '',
          taxStatus: e.selectFilters.find(filter => filter.name === 'taxStatus')?.value || '',
          transactionType: e.selectFilters.find(filter => filter.name === 'transactionType')?.value || '',
          sortBy: e.sortBy,
          sortDir: e.sortDir,
        }
      })
    } else {
      initialRender.current = false;
    }
  }, []);

  return (
    <Grid item container direction={'column'} component={'section'}>
      <SortFilter
        sortByOption={sortByOptions}
        filterByOption={
          filterByOptions.map(filter => {
            return {
              ...filter,
              value: initialQuery[filter.name] ? initialQuery[filter.name] : filter.value
          }})
        }
        filterBySelectOption={filterBySelectOption}
        filterCallback={handleCallback}
        defaultStartDate={initialQuery.startDate}
        defaultEndDate={initialQuery.endDate}
        defaultSortBy={initialQuery.sortBy}
        sortDir={initialQuery.sortDir}
      />
    </Grid>
  );
});

export default withSnackbar(InvoiceSortFilter);