import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import useScrollToTop from '../../../hooks/useScrollToTop';
import { Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import LocationImageGallery from '../../../components/location/profile/LocationImageGallery';
import LocationProfileDescription from '../../../components/location/profile/LocationProfileDescription';
import LocationProfileMapPreview from '../../../components/location/profile/LocationProfileMapPreview';
import LocationProfileFeatures from '../../../components/location/profile/features/LocationProfileFeatures';
import LocationProfilePriceBreakdown from '../../../components/location/profile/LocationProfilePriceBreakdown';
import LocationProfileBookSpaceForm from '../../../components/location/profile/LocationProfileBookSpaceForm';
import LocationProfileBeforeYouBook from '../../../components/location/profile/LocationProfileBeforeYouBook';
import LocationProfileSimilarYardsNearby from '../../../components/location/profile/LocationProfileSimilarYardsNearby';
import LocationProfileHeader from '../../../components/location/profile/LocationProfileHeader';
import { getLocationByIdWithDates, getLocationHasRequestedCapacityById } from '../../../components/location/requests/location-requests';
import moment from 'moment';
import SecurSpaceContainer from '../../../components/common/SecurSpaceContainer';
import './style.css'
import { DateFormats } from '../../../components/constants/securspace-constants';
import { Theme } from "@mui/material";
import {DAILY, MONTHLY} from "../../../util/BookingUtil";
import { DEFAULT_DAILY_BOOKING_DAYS_AHEAD, DEFAULT_NUM_SPACES } from "../../../components/constants/book-space-constants";
import { withSnackbar } from "../../../components/hocs/withSnackbar";
import { getErrorMessageForNonStandardAndStandardResponse } from "../../../util/NetworkErrorUtil";
import OverageRatePrice from "../../../components/location/profile/OverageRatePrice";
import {AppContext} from "../../../context/app-context";
import { isSupplierAccount } from "../../../util/AccountUtils";

const useStyles: (theme: Theme) => {
  lpMapText: CSSStyleSheet,
  lpHeaderDivider: CSSStyleSheet,
  lpSectionDivider: CSSStyleSheet,
  lpBody: CSSStyleSheet,
  lpBeforeYouBookContainer: CSSStyleSheet,
  mainBg: CSSStyleSheet,
  lpListingInfoSection: CSSStyleSheet,
} = makeStyles((theme) => ({
  lpMapText: {
    fontSize: '.8571428em',
    lineHeight: '166%',
    marginTop: '1.2em',
  },
  lpHeaderDivider: {
    height: '0.07rem',
    color: '#e0e0e09d',
  },
  lpSectionDivider: {
    height: '0.08rem',
    marginTop: '1.5rem',
    marginBottom: '1.5rem',
    backgroundColor: '#e0e0e09d',
  },
  lpBody: {
    marginTop: '1.5rem',
  },
  lpBeforeYouBookContainer: {
    marginTop: '2.14rem',
  },
  mainBg: {
    backgroundColor: '#fff'
  },
  lpListingInfoSection: {
    rowGap: '1.07rem',
    [theme.breakpoints.between('md', 'lg')]: {
      width: '57.14rem',
    }
  },
  divider:{
    borderBottom: `1px solid #e0e0e09d`,
    paddingBottom:'0.1em'
  }
}));

const LocationProfile = ({ snackbarShowMessage }) => {
  const { locationId } = useParams();
  const [location: Supplier, setLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [capacityRequest, setCapacityRequest] = useState({
    durationType: DAILY,
    startDate: moment().format(DateFormats.DAY),
    endDate: moment().add(DEFAULT_DAILY_BOOKING_DAYS_AHEAD, 'days').format(DateFormats.DAY),
    numberOfSpaces: DEFAULT_NUM_SPACES,
  });
  const [locationHasRequestedCapacity, setLocationHasRequestedCapacity] = useState(true);
  const classes = useStyles();

  const { user }  = useContext(AppContext);

  useScrollToTop();

  useEffect(() => {
    if (locationId) {
      setLoading(true)

      const startDate = moment().format(DateFormats.DAY);
      const endDate = moment().add(1, 'days').format(DateFormats.DAY);

      getLocationByIdWithDates(locationId, startDate, endDate).then((response) => {
        setLocation(response.body);
        setCapacityRequest(capacityRequest => ({
          ...capacityRequest,
          numberOfSpaces: response.body.minNumberOfSpaces || DEFAULT_NUM_SPACES,
        }));
      }).catch((error) => {
        snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(error), 'error', 15000);
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [locationId, snackbarShowMessage]);

  useEffect(() => {
    if (capacityRequest.durationType === MONTHLY) {
      setLocationHasRequestedCapacity(false);
      return;
    }
    let ignore = false;
    getLocationHasRequestedCapacityById(
      locationId,
      capacityRequest.numberOfSpaces,
      capacityRequest.startDate,
      capacityRequest.endDate
    ).then((response) => {
      if (!ignore) {
        setLocationHasRequestedCapacity(response.body.hasRequestedCapacity);
      }
    }).catch(error => {
      snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(error), 'error', 15000);
    });
    return () => {
      ignore = true;
    }
  }, [locationId, capacityRequest, snackbarShowMessage]);

  const handleCapacityRequestChange = useCallback(function handleCapacityRequestChange(value) {
    setCapacityRequest(capacityRequest => ({
      ...capacityRequest,
      ...value,
    }));
  }, []);

  const locationGallery = useMemo(() => {
    if (!location) {
      return [];
    }
    return location.gallery ? [...new Set(location.gallery)] : [];
  }, [location]);

  return (
    <>
      <Helmet>
          <title>{location ? location.locationName : 'SecurSpace | Nationwide Truck Parking & Storage'}</title>
      </Helmet>
      <section className={classes.mainBg}>
        <SecurSpaceContainer >
          <Grid container direction={'column'}>
            <Grid className={classes.divider} item container xs>
              <LocationProfileHeader
                locationName={location?.locationName}
                city={location?.city}
                state={location?.state}
                hasRequestedCapacity={locationHasRequestedCapacity}
              />
            </Grid>
            <Divider variant='fullWidth' className={classes.lpHeaderDivider} />
            <Grid container spacing={4} className={classes.lpBody}>
              <Grid item className={classes.lpListingInfoSection} xs={12} md>
                <Grid item>
                  <LocationImageGallery locationId={locationId} listingImage={location?.listingImageFileName} gallery={locationGallery} />
                </Grid>
                <Grid item>
                  <LocationProfileDescription loading={loading} desc={location?.locationDescription} />
                </Grid>
                <Grid item>
                  <LocationProfileMapPreview loading={loading} lat={location?.addressLatitude} lng={location?.addressLongitude} />
                </Grid>
                <Grid item>
                  <Typography className={classes.lpMapText}>
                    For Security Reasons, addresses will not be sent before a booking is completed.
                    You will receive facility instructions and address upon completing.
                    This map represents the zip code of the facility.
                  </Typography>
                </Grid>
                <Divider variant='fullWidth' className={classes.lpSectionDivider} />
                <Grid item>
                  <LocationProfileFeatures loading={loading} features={location?.features ? location.features : []} />
                </Grid>
                <Divider variant='fullWidth' className={classes.lpSectionDivider} />
                <Grid item>
                  <LocationProfilePriceBreakdown
                    equipmentTypeArray={location?.equipmentTypesAll ? location.equipmentTypesAll : []}
                    defaultPricePerDay={location?.pricePerDay}
                    defaultPricePerMonth={location?.pricePerMonth}
                    loading={loading}
                  />
                </Grid>
                {location?.chargeOverages && <Divider variant='fullWidth' className={classes.lpSectionDivider}/>}
                {
                  location?.chargeOverages && <Grid item>
                    <OverageRatePrice overageRateInCents={location?.overageRate}/>
                  </Grid>
                }
              </Grid>
              {
                !isSupplierAccount(user) && <Grid item xs={12} md={4}>
                  <Grid container direction={'column'}>
                    <Grid item>
                      <LocationProfileBookSpaceForm
                        location={location}
                        capacityRequest={capacityRequest}
                        onCapacityRequestChange={handleCapacityRequestChange}
                        instantApproval={locationHasRequestedCapacity}
                      />
                    </Grid>
                    <Grid item className={classes.lpBeforeYouBookContainer}>
                      <LocationProfileBeforeYouBook rentalAgreementFileName={location?.supplierLegalAgreementFileName}/>
                    </Grid>
                  </Grid>
              </Grid>
              }
            </Grid>
          </Grid>
        </SecurSpaceContainer>
      </section>
      {
        location && <SecurSpaceContainer>
          <Grid container item xs>
            <LocationProfileSimilarYardsNearby location={location} />
          </Grid>
        </SecurSpaceContainer>
      }
    </>
  )
}

export default withSnackbar(LocationProfile);
