import React from 'react';
import {Box, Typography} from "@mui/material";
import {useStyles} from '../../views/payment/styles/bankInfoStyles';


const bankArray = [
  'You are one step away from completing the process of adding this payment method.',
  'You must now verify your  bank account by confirming 2 micro deposit amounts.',
  'Two small deposits will be made into your bank account within 1-2 days.',
  'The deposit amount will be less than $0.10.',
  'Once you see the deposits, come back to this page and click the “Verify” button.',
  'Follow the remaining steps to verify and you’re all done!'
];

const MicrodepositVerificationSteps = () => {
  const classes = useStyles();
  return bankArray.map((item, index) => (
    <Box display='flex' alignItems='center' key={index}>
      <Box className={classes.bankBullet}/>
      <Typography variant='body1' className={classes.bankText}>{item}</Typography>
    </Box>
  ));
}

export default MicrodepositVerificationSteps;