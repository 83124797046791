import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";

export const useStyles: (theme: Theme) => {
  search_container: CSSStyleSheet,
  search_bar_container: CSSStyleSheet,
  filter_options_container: CSSStyleSheet,
  filterBox: CSSStyleSheet,
  pac_input_wrapper: CSSStyleSheet,
  filter_container: CSSStyleSheet,
  adminFilterContainer: CSSStyleSheet,
  filterButtonContainer: CSSStyleSheet,
  filter: CSSStyleSheet,
} = makeStyles(theme => ({
  search_container: {
    width: "100%",
    boxShadow: "0em 0.14em 0.07em -0.07em rgba(0, 0, 0, 0.2), 0em 0.07em 0.07em rgba(0, 0, 0, 0.14), 0em 0.14em 0.21em rgba(0, 0, 0, 0.12)",
    '@media (max-width:91.24em)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    }
  },
  search_bar_container: {
    borderRight: "0.07em solid #E0E0E0",
    boxShadow: theme.showPac ? "0px 4px 4px rgba(0, 0, 0, 0.25);" : "none",
    width: "100%",
    [theme.breakpoints.up('md')]: {
      maxWidth: "45.14em",
      width: "45.14em",
    },
    '@media (max-width:91.24em)': {
      borderRight: "none",
    }
  },
  filter_options_container: {
    padding: ".36em 1.22em",
    backgroundColor: '#FBFBFF',
    flex: 1,
    gap: '1.5em',
    '@media (max-width:91.24em)': {
      gap: '1em',
    }
  },
  filterBox: {
    width: 'fit-content',
  },
  pac_input_wrapper: {
    margin: "0.86em 2.17em",
    width: "100%",
  },
  filter_container: {
    flex: 1,
    maxWidth: '100% !important',
    '@media (max-width:39.28em)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    }
  },
  adminFilterContainer: {
    width: "100%",
    boxShadow: "0em 0.14em 0.07em -0.07em rgba(0, 0, 0, 0.2), 0em 0.07em 0.07em rgba(0, 0, 0, 0.14), 0em 0.14em 0.21em rgba(0, 0, 0, 0.12)",
    '@media (max-width:109.71em)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& .ss-hide-border': {
        borderRight: "none",
      }
    }
  },
  filterButtonContainer: {
    '@media (max-width:91.24em)': {
      padding: ".36em 1.22em",
    }
  },
  filter: {
    display: 'none',
    width: '100%',
    '@media (max-width:91.24em)': {
      display: 'block',
    }
  }
}));