import React, {useEffect, useState} from 'react';
import {Button, Grid, Typography} from "@mui/material";
import NotificationsTable from "./NotificationsTable";
import {getNotificationsSettingsRequest, saveNotificationSettingsRequest} from "./notificationsRequests";
import Busy from "../Busy";
import {createLogoutOnFailureHandler} from "../../util/LogoutUtil";
import {withSnackbar} from "../hocs/withSnackbar";
import {getErrorMessageForNonStandardAndStandardResponse} from "../../util/NetworkErrorUtil";
import { isSupplierAccount } from "../../util/AccountUtils";

const OWNER_ROLE = "ACCOUNTOWNER";
const NOTIFICATIONS_TYPES_SUPPLIER = {Administrative: [OWNER_ROLE], Partner_Yard_Management: [OWNER_ROLE]};
const NOTIFICATIONS_TYPES_BUYER = {Administrative: [OWNER_ROLE], Fleet_Management: [OWNER_ROLE]}

const Notifications = (props) => {
  const {account, handleLogout, snackbarShowMessage} = props;
  const defaultNotificationsTypes = isSupplierAccount(account) ?
    NOTIFICATIONS_TYPES_SUPPLIER
    : NOTIFICATIONS_TYPES_BUYER;

  const [notificationSettings, setNotificationSettings] = useState(null);
  const [useLocationRestrictions, setUseLocationRestrictions] = useState(false);

  useEffect(() => {
    const createEmptyNotificationsSettings = () => {
      const defaultNotificationsSettings = {
        accountId: account.id,
        values: defaultNotificationsTypes,
        useLocationRestriction: false
      };
      setNotificationSettings(defaultNotificationsSettings);
    }

    getNotificationsSettingsRequest(account.id)
      .then((resp) => {
        const data = JSON.parse(resp.text);
        data.values = JSON.parse(data.values);
        if (data && data.id) {
          setNotificationSettings(data);
        } else {
          createEmptyNotificationsSettings();
        }
      })
  }, [account.id, defaultNotificationsTypes]);

  const onSubmit = () => {
    Busy.set(true);
    const data = {
      ...notificationSettings,
      ...useLocationRestrictions,
      ...{values: JSON.stringify(notificationSettings.values)}
    };

    saveNotificationSettingsRequest(data)
      .then(() => {
        Busy.set(false);
        snackbarShowMessage('Notifications settings saved successfully!', 'success', 8000);
      }).catch(error => {
      Busy.set(false);
      if (error.response.status === 401) {
        createLogoutOnFailureHandler(handleLogout);
      }
      snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(error), 'error', 15000);
    });
  }

  return <>
    <Grid component={'article'} container>
      <Grid item container justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant={'h5'} component={'h1'} color={'textPrimary'}>Notifications</Typography>
        <Button variant={'contained'} color={'primary'} onClick={onSubmit}>Save</Button>
      </Grid>
      {
        notificationSettings &&
        <NotificationsTable
          notificationSettings={notificationSettings}
          setNotificationSettings={setNotificationSettings}
          useLocationRestrictions={useLocationRestrictions}
          setUseLocationRestrictions={setUseLocationRestrictions}
          account={account}
        />
      }
    </Grid>
  </>
}

export default withSnackbar(Notifications);
