import React, { useEffect, useState, useCallback } from 'react';
import { transformBooking } from '../../components/supplier-booking/data';
import { Container, Grid, Tab, Tabs, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TabContext, TabPanel } from "@mui/lab";
import type { Account } from "../../types/Account";
import BookingTab from '../../components/supplier-booking/BookingTab';
import { SupplierBookingFilter} from '../../components/supplier-booking';
import { withSnackbar } from '../../components/hocs/withSnackbar';
import { getErrorMessageForNonStandardAndStandardResponse } from '../../util/NetworkErrorUtil';
import { getSupplierBooking } from '../../components/supplier-booking/requests/supplier-booking-requests';

const useStyles: (theme: Theme) => { mainContainer: CSSStyleSheet, tabPanel: CSSStyleSheet } = makeStyles((theme) => ({
  mainContainer: {
    paddingTop: '2.5rem',
    paddingBottom: '2.86rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '8.04rem',
      paddingRight: '8.04rem',
    }
  },
  filterContainer: {
    marginTop: '2em'
  },
  tabPanel: {
    paddingLeft: '0',
    paddingRight: '0',
  },
}));

const ACTIVE_TAB = '0'
const PAYMENT_FAILED_TAB = '1';
const INACTIVE_TAB =  '2';

const statuses = {ACTIVE: 'ACTIVE', FAILED: 'FAILED', INACTIVE: 'INACTIVE'}

const SupplierBooking = (props: { account: Account, snackbarShowMessage: () => void }) => {
  const { account, snackbarShowMessage } = props;

  const urlParams = new URLSearchParams(window.location.search);
  const defaultBookingNumber =urlParams?.get("bookingNumber") ? urlParams.get("bookingNumber") : "";
  const urlStatus = urlParams?.get("status") ? urlParams.get("status") : "";

  const getDefaultTab = useCallback((status) => {
    if (status) {
        const formattedStatus = status.toUpperCase();
      switch (formattedStatus) {
        case statuses.ACTIVE:
          return ACTIVE_TAB;
        case statuses.FAILED:
          return PAYMENT_FAILED_TAB;
        case statuses.INACTIVE:
          return INACTIVE_TAB;
        default:
          return ACTIVE_TAB;
      }
    } else {
      return ACTIVE_TAB
    }
  }, []);
  
  const [currentTab, setCurrentTab] = useState(getDefaultTab());
  const [activeBookings, setActiveBookings] = useState([])
  const [inactiveBookings, setInactiveBookings] = useState([]);
  const [failedBookings: Booking[], setFailedBookings] = useState([]);
  const [activePageable, setActivePageable] = useState({page: 0, size: 50});
  const [failedPageable, setFailedPageable] = useState({page: 0, size: 50});
  const [inactivePageable, setInactivePageable] = useState({page: 0, size: 50});
  const [totalBookingCount, setTotalBookingCount] = useState({active: 0, failed: 0, inactive: 0})
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState({
      startDate: '',
      endDate: '',
      buyerName: '',
      bookingNumber: defaultBookingNumber,
      selectedLocation: null,
      sortBy: '',
      equipmentType: '',
      brokered: false,
      frequency: '',
      sortDir: ''
  });

  const classes = useStyles();

  const handleTabChange = (_event, value) => {
      if (value !== undefined) {
        switch(value) {
            case '0' :
                setCurrentTab(ACTIVE_TAB)
                break;
            case '1':
                setCurrentTab(PAYMENT_FAILED_TAB)
                break;
            case '2':
                setCurrentTab(INACTIVE_TAB)
                break;
            default:
                setCurrentTab(ACTIVE_TAB)
                break;
        }
    } else {
        setCurrentTab(ACTIVE_TAB)
    }
  };

  const setBookingCount = (activeStatus, count) => {
    switch (activeStatus) {
        case statuses.ACTIVE:
          setTotalBookingCount(prev => ({...prev, active: count}));
          break;
        case statuses.FAILED:
            setTotalBookingCount(prev => ({...prev, failed: count}));
            break;
        case statuses.INACTIVE:
            setTotalBookingCount(prev => ({...prev, inactive: count}));
            break;
      }
  }
  
  useEffect(() => {
    
      const getBookings = (activeStatus, setBookings, pageable) => {
        const { id } = account;
    
        if (id) {
            setLoading(true)
            const requestParams = {
                supplierAccountId: account.id,
                locationId: query ? query.selectedLocation?.id : '',
                status: activeStatus,
                page: pageable.page,
                size: pageable.size,
                ...query,
            };
    
            getSupplierBooking(requestParams)
                .then(({ body }) => {
                    setBookingCount(activeStatus, body.count)
                    const data = body.content?.map((booking) => {
                        return transformBooking(booking);
                    });
                    setBookings(data);
                })
                .catch(err => {
                    snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(err), "error", 15000)
                })
                .finally(() => {
                setLoading(false);
                });
            }
        }
        
        getBookings(statuses.ACTIVE, setActiveBookings, activePageable);
        getBookings(statuses.FAILED, setFailedBookings, failedPageable);
        getBookings(statuses.INACTIVE, setInactiveBookings, inactivePageable);
    }, [account, query, snackbarShowMessage, activePageable, failedPageable, inactivePageable]);

    useEffect(() => {
      if (urlStatus) {
          setCurrentTab(getDefaultTab(urlStatus))
      } else if(defaultBookingNumber) {
  
          if(activeBookings.length) {
              setCurrentTab(getDefaultTab(statuses.ACTIVE))
          }
          if(failedBookings.length) {
              setCurrentTab(getDefaultTab(statuses.FAILED))
          }
          if(inactiveBookings.length) {
              setCurrentTab(getDefaultTab(statuses.INACTIVE))
          }
      }
  
  }, [defaultBookingNumber, activeBookings, inactiveBookings, failedBookings, getDefaultTab]);

  return (
    <Container className={classes.mainContainer}>
      <Grid item>
        <Typography variant={'h5'} component={'h1'}>Bookings</Typography>
      </Grid>
      <Grid item className={classes.filterContainer}>
        <SupplierBookingFilter setQuery={setQuery}/>
      </Grid>
      <Grid item>
        <TabContext value={currentTab}>
          <Grid container justifyContent={'space-between'} alignItems={'center'}>
            <Grid item xs>
              <Tabs
                value={currentTab}
                onChange={handleTabChange}
                aria-label={'Bookings Tabs'}
                textColor={'secondary'}
                variant={'fullWidth'}
              >
                <Tab value={'0'} label={activeBookings.length ? `Active (${activeBookings.length})` : 'Active'} />
                <Tab value={'1'} label={failedBookings.length ? `Payment Failed (${failedBookings.length})` : 'Payment Failed'} />
                <Tab value={'2'} label={inactiveBookings.length ? `Inactive (${inactiveBookings.length})` : 'Inactive'} />
              </Tabs>
            </Grid>
          </Grid>

          <TabPanel value={'0'} className={classes.tabPanel}>
            <BookingTab bookings={activeBookings} loading={loading} pageable={activePageable} setPageable={setActivePageable} count={totalBookingCount.active} />
          </TabPanel>
          <TabPanel value={'1'} className={classes.tabPanel}>
            <BookingTab bookings={failedBookings} loading={loading} pageable={failedPageable} setPageable={setFailedPageable} count={totalBookingCount.failed} />
          </TabPanel>
          <TabPanel value={'2'} className={classes.tabPanel}>
            <BookingTab bookings={inactiveBookings} loading={loading} inactivePageable={inactivePageable} setPageable={setInactivePageable} count={totalBookingCount.inactive} />
          </TabPanel>
        </TabContext>
      </Grid>
    </Container>
  );
};

export default withSnackbar(SupplierBooking);
