import React, { useEffect, useState } from 'react';
import { requestBookingTransactionById } from "../service-fee-requests";
import {
  getErrorMessageForNonStandardAndStandardResponse,
  getErrorMessageForStandardResponse
} from "../../../util/NetworkErrorUtil";
import { toast } from "react-toastify";
import Busy from "../../Busy";
import { useNavigate } from "react-router";
import { requestPatchInvoiceStatus } from "../../admin/request/invoice-requests";
import SecurSpacePageTitle from "../../common/SecurSpacePageTitle";
import CostBreakdownContent from "./CostBreakdownContent";
import InvoiceInformationContent from "./InvoiceInformationContent";
import { BookingTransactionStatusType, BookingTransactionType, buyerServiceFeeStatusesThatArePending, buyerServiceFeeStatusesThatAreComplete } from "../../constants/securspace-constants";
import '../../../css/components/serviceFeeApproval.css'
import { useLocation } from "react-router-dom";
import { Box, Button, Grid } from '@mui/material';

export const styles = {
  outline_btn: (theme) => ({
    outline: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    padding: '0.8em 2em',
    borderRadius: '20em',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white
    }
  }),
  solid_btn: (theme) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: '1em 2em',
    borderRadius: '20em',
    '&:hover': {
      backgroundColor: 'transparent',
      outline: `2px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main
    }
  })
};


const SERVICE_FEE_TITLE = {
  DEFAULT: 'Service Fee',
  PENDING: 'Service Fee - Payment Pending',
  COMPLETED: 'Service Fee - Payment Completed',
  DECLINED: 'Service Fee - Payment Declined',
};

const ServiceFeeApproval = () => {
  const location = useLocation();
  const transactionId = location.pathname.replace('/buyer/service-fee/', '');

  const [bookingTransaction, setBookingTransaction] = useState({});
  const [disputeNotes, setDisputeNotes] = useState("");
  const [showNotes, setShowNotes] = useState(false);
  const [allowConfirmDispute, setAllowConfirmDispute] = useState(false);
  const [headerTitle, setHeaderTitle] = useState(SERVICE_FEE_TITLE.DEFAULT);
  const navigate = useNavigate();

  useEffect(() => {
    if (transactionId) {
      Busy.set(true);
      requestBookingTransactionById(transactionId).then((resp) => {
        setBookingTransaction(resp?.body);
      }).catch(error => {
        toast.error(getErrorMessageForStandardResponse(error));
      }).finally(() => {
        Busy.set(false);
      })
    }
  }, [transactionId]);

  useEffect(() => {
    if (bookingTransaction?.status) {
      if (bookingTransaction.status === BookingTransactionStatusType.DECLINED) {
        setHeaderTitle(SERVICE_FEE_TITLE.DECLINED);
      } else if (buyerServiceFeeStatusesThatArePending.includes(bookingTransaction.status)) {
        setHeaderTitle(SERVICE_FEE_TITLE.PENDING);
      } else if (buyerServiceFeeStatusesThatAreComplete.includes(bookingTransaction.status)) {
        setHeaderTitle(SERVICE_FEE_TITLE.COMPLETED);
      }
      if (bookingTransaction.type === BookingTransactionType.SERVICE_CHARGE
        && bookingTransaction.status === BookingTransactionStatusType.CHARGE_ON_HOLD) {
        setAllowConfirmDispute(true);
      }
    }
  }, [bookingTransaction]);

  const handleApproveServiceCharge = () => {
    if (!bookingTransaction?.id) {
      toast.error("Something went wrong, please return to the booking transaction page and try again.");
      return;
    }
    setShowNotes(false)
    setDisputeNotes("");

    requestPatchInvoiceStatus(
      bookingTransaction.id,
      { status: BookingTransactionStatusType.PAYMENT_NOT_SUBMITTED },
      () => {
        navigate('/buyer-invoices');
        toast.info("Service Charge Approved, the charge will take place as soon as the system processes it.");
      },
      (error) => {
        toast.info(getErrorMessageForNonStandardAndStandardResponse(error));
      });
  }

  const handleDisputeServiceCharge = () => {
    if (!bookingTransaction?.id) {
      toast.error("Something went wrong, please return to the booking transaction page and try again.");
      return;
    }
    if (disputeNotes === "") {
      toast.error("Please provide a reason for declining.")
      return;
    }

    requestPatchInvoiceStatus(
      bookingTransaction.id,
      { status: BookingTransactionStatusType.DECLINED, disputeNotes: disputeNotes },
      () => {
        navigate('/buyer-invoices');
        toast.info("Service Charge decline submitted, please reach out to the supplier for further action.");
      },
      (error) => {
        toast.info(getErrorMessageForNonStandardAndStandardResponse(error));
      });
  }

  const startDisputeProcess = () => {
    setShowNotes(true)
  }

  const handleChangeNotes = (e) => {
    setDisputeNotes(e.target.value)
  }

  return <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    p: 4
  }}>
    <Box sx={{
      maxWidth: "50em",
      width: '100%',
    }}>
      <Box>
        <Box>
          <SecurSpacePageTitle>{headerTitle}</SecurSpacePageTitle>
        </Box>
      </Box>
      <Grid container direction={'column'}>
        <Grid item>
          <CostBreakdownContent bookingTransaction={bookingTransaction} />
          {
            showNotes &&
            <div>
              <label htmlFor='disputeServiceChargeNotes'>
                <h3 className={'ss-title-no-image'}>
                  Please provide a reason for declining:
                </h3>
              </label>
              <input
                id="disputeServiceChargeNotes"
                type="text"
                name="disputeServiceChargeNotes"
                className='ss-dispute-service-charge-notes-input'
                value={disputeNotes}
                onChange={handleChangeNotes}
              />
            </div>
          }
        </Grid>
        <Grid item sx={{
          width: '100%'
        }}>
          <InvoiceInformationContent bookingTransaction={bookingTransaction} />
        </Grid>
      </Grid>
      {
        allowConfirmDispute &&
        <Grid mt={2} container justifyContent={'center'} spacing={4} alignItems={'center'}>
          <Grid item>
            <Button
              onClick={handleApproveServiceCharge}
              sx={styles.solid_btn}>
              Approve
            </Button>
          </Grid>
          <Grid item>
            {
              showNotes ?
                <Button
                  onClick={handleDisputeServiceCharge}
                  sx={styles.outline_btn}
                >
                  Submit
                </Button>
                :
                <Button
                  onClick={startDisputeProcess}
                  sx={styles.outline_btn}
                >
                  Decline
                </Button>
            }
          </Grid>
        </Grid>
      }
    </Box>
  </Box>;
}

export default ServiceFeeApproval;
