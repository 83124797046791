import React from "react";
import { Grid, Button } from "@mui/material";
import { Table } from '@securspace/securspace-ui-kit';
import { headerCells } from './data'
import { verbiage } from "./payouts-report-verbiage";

const PayoutsTable = ({
  items,
  handleReviewItem,
  handleSortChange,
  sortBy,
  sortDir
}) => {

  const actionButtons = (item) => {
    return <Grid container sx={{ fontSize:'1em' }}>
      <Button sx={{ fontSize:'1em' }} onClick={() => handleReviewItem(item?.item)} variant="outlined">{verbiage.buttonText}</Button>
    </Grid>
  }

  return <Table
      headCells={headerCells}
      rows={items}
      hasRowAction={true}
      actionType='button'
      actionButtons={actionButtons}
      isSortable={true}
      handleSortRequest={handleSortChange}
      sortBy={sortBy}
      sortDir={sortDir}
    />
}

export default PayoutsTable
