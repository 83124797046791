import React, { useState } from 'react';
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead, TableRow,
} from "@mui/material";
import BuyerTableCells from "./BuyerTableCells";
import PartnerTableCells from "./PartnerTableCells";
import SupplierAdministrativeTableRow from "./SupplierAdministrativeTableRow";
import PartnerYardManagementTableRow from "./PartnerYardManagementTableRow";
import BuyerAdministrativeTableRow from "./BuyerAdministrativeTableRow";
import FleetManagementTableRow from "./FleetManagementTableRow";
import CustomTooltip from "./CustomTooltip";
import { isBuyerAccount, isSupplierAccount } from "../../util/AccountUtils";

const ROLES = {
  owner: 'OWNER',
  accountOwner: 'ACCOUNTOWNER',
  gateManager: 'GATEMANAGER',
  gateClerk: 'GATECLERK',
  dispatcher: 'DISPATCHER'
};

const NotificationsTable = (props) => {
  const {
    account,
    notificationSettings,
    setNotificationSettings,
    useLocationRestrictions,
    setUseLocationRestrictions
  } = props;

  const [administrativeGateClerkRole, setAdministrativeGateClerkRole] =
    useState(notificationSettings ?
      notificationSettings?.values?.Administrative?.includes(ROLES.gateClerk) : false);
  const [partnerYardManagementGateClerkRole, setPartnerYardManagementGateClerkRole] =
    useState(notificationSettings ?
      notificationSettings?.values?.Partner_Yard_Management?.includes(ROLES.gateClerk) : false);
  const [administrativeAccountOwnerRole, setAdministrativeAccountOwnerRole] =
    useState(notificationSettings ?
      notificationSettings?.values?.Administrative?.includes(ROLES.accountOwner) : false);
  const [partnerYardManagementAccountOwnerRole, setPartnerYardManagementAccountOwnerRole] =
    useState(notificationSettings ?
      notificationSettings?.values?.Partner_Yard_Management?.includes(ROLES.accountOwner) : false);
  const [administrativeGateManagerRole, setAdministrativeGateManagerRole] =
    useState(notificationSettings ?
      notificationSettings?.values?.Administrative?.includes(ROLES.gateManager) : false);
  const [partnerYardManagementGateManagerRole, setPartnerYardManagementGateManagerRole] =
    useState(notificationSettings ?
      notificationSettings?.values?.Partner_Yard_Management?.includes(ROLES.gateManager) : false);
  const [administrativeAdminRole, setAdministrativeAdminRole] =
    useState(notificationSettings ?
      notificationSettings?.values?.Administrative?.includes(ROLES.owner) : false);
  const [partnerYardManagementAdminRole, setPartnerYardManagementAdminRole] =
    useState(notificationSettings ?
      notificationSettings?.values?.Partner_Yard_Management?.includes(ROLES.owner) : false);
  const [administrativeDispatcherRole, setAdministrativeDispatcherRole] =
    useState(notificationSettings ?
      notificationSettings?.values?.Administrative?.includes(ROLES.dispatcher) : false);
  const [fleetManagementAccountOwnerRole, setFleetManagementAccountOwnerRole] =
    useState(notificationSettings ?
      notificationSettings?.values?.Fleet_Management?.includes(ROLES.accountOwner) : false);
  const [fleetManagementDispatcherRole, setFleetManagementDispatcherRole] =
    useState(notificationSettings ?
      notificationSettings?.values?.Fleet_Management?.includes(ROLES.dispatcher) : false);
  const [fleetManagementAdminRole, setFleetManagementAdminRole] =
    useState(notificationSettings ?
      notificationSettings?.values?.Fleet_Management?.includes(ROLES.owner) : false);
  const gmsPro = account?.subscriptionType === "GMS_PRO" && isSupplierAccount(account);

  const handleAdministrativeGateClerkRoleChange = () => {
    setAdministrativeGateClerkRole(prevState => !prevState);
    setNotificationSettings(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          Administrative: prevState.values.Administrative.includes(ROLES.gateClerk) ?
            prevState.values.Administrative.filter(role => role !== ROLES.gateClerk) :
            [...prevState.values.Administrative, ROLES.gateClerk]
        }
      }
    })
  };

  const handlePartnerYardManagementGateClerkRoleChange = () => {
    setPartnerYardManagementGateClerkRole(prevState => !prevState);
    setNotificationSettings(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          Partner_Yard_Management: prevState.values.Partner_Yard_Management.includes(ROLES.gateClerk) ?
            prevState.values.Partner_Yard_Management.filter(role => role !== ROLES.gateClerk) :
            [...prevState.values.Partner_Yard_Management, ROLES.gateClerk]
        }
      }
    })
  };

  const handleAdministrativeAccountOwnerRoleChange = () => {
    setAdministrativeAccountOwnerRole(prevState => !prevState);
    setNotificationSettings(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          Administrative: prevState.values.Administrative.includes(ROLES.accountOwner) ?
            prevState.values.Administrative.filter(role => role !== ROLES.accountOwner) :
            [...prevState.values.Administrative, ROLES.accountOwner]
        }
      }
    })
  };

  const handlePartnerYardManagementAccountOwnerRoleChange = () => {
    setPartnerYardManagementAccountOwnerRole(prevState => !prevState);
    setNotificationSettings(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          Partner_Yard_Management: prevState.values.Partner_Yard_Management.includes(ROLES.accountOwner) ?
            prevState.values.Partner_Yard_Management.filter(role => role !== ROLES.accountOwner) :
            [...prevState.values.Partner_Yard_Management, ROLES.accountOwner]
        }
      }
    })
  };

  const handleAdministrativeGateManagerRoleChange = () => {
    setAdministrativeGateManagerRole(prevState => !prevState);
    setNotificationSettings(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          Administrative: prevState.values.Administrative.includes(ROLES.gateManager) ?
            prevState.values.Administrative.filter(role => role !== ROLES.gateManager) :
            [...prevState.values.Administrative, ROLES.gateManager]
        }
      }
    })
  };

  const handlePartnerYardManagementGateManagerRoleChange = () => {
    setPartnerYardManagementGateManagerRole(prevState => !prevState);
    setNotificationSettings(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          Partner_Yard_Management: prevState.values.Partner_Yard_Management.includes(ROLES.gateManager) ?
            prevState.values.Partner_Yard_Management.filter(role => role !== ROLES.gateManager) :
            [...prevState.values.Partner_Yard_Management, ROLES.gateManager]
        }
      }
    })
  };

  const handleAdministrativeAdminRoleChange = () => {
    setAdministrativeAdminRole(prevState => !prevState);
    setNotificationSettings(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          Administrative: prevState.values.Administrative.includes(ROLES.owner) ?
            prevState.values.Administrative.filter(role => role !== ROLES.owner) :
            [...prevState.values.Administrative, ROLES.owner]
        }
      }
    })
  };

  const handlePartnerYardManagementAdminRoleChange = () => {
    setPartnerYardManagementAdminRole(prevState => !prevState);
    setNotificationSettings(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          Partner_Yard_Management: prevState.values.Partner_Yard_Management.includes(ROLES.owner) ?
            prevState.values.Partner_Yard_Management.filter(role => role !== ROLES.owner) :
            [...prevState.values.Partner_Yard_Management, ROLES.owner]
        }
      }
    })
  };

  const handleAdministrativeDispatcherRoleChange = () => {
    setAdministrativeDispatcherRole(prevState => !prevState);
    setNotificationSettings(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          Administrative: prevState.values.Administrative.includes(ROLES.dispatcher) ?
            prevState.values.Administrative.filter(role => role !== ROLES.dispatcher) :
            [...prevState.values.Administrative, ROLES.dispatcher]
        }
      }
    })
  };

  const handleFleetManagementAccountOwnerRoleChange = () => {
    setFleetManagementAccountOwnerRole(prevState => !prevState);
    setNotificationSettings(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          Fleet_Management: prevState.values.Fleet_Management.includes(ROLES.accountOwner) ?
            prevState.values.Fleet_Management.filter(role => role !== ROLES.accountOwner) :
            [...prevState.values.Fleet_Management, ROLES.accountOwner]
        }
      }
    });
  };

  const handleFleetManagementDispatcherRoleChange = () => {
    setFleetManagementDispatcherRole(prevState => !prevState);
    setNotificationSettings(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          Fleet_Management: prevState.values.Fleet_Management.includes(ROLES.dispatcher) ?
            prevState.values.Fleet_Management.filter(role => role !== ROLES.dispatcher) :
            [...prevState.values.Fleet_Management, ROLES.dispatcher]
        }
      }
    });
  };

  const handleFleetManagementAdminRoleChange = () => {
    setFleetManagementAdminRole(prevState => !prevState);
    setNotificationSettings(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          Fleet_Management: prevState.values.Fleet_Management.includes(ROLES.owner) ?
            prevState.values.Fleet_Management.filter(role => role !== ROLES.owner) :
            [...prevState.values.Fleet_Management, ROLES.owner]
        }
      }
    });
  };

  const handleUseLocationRestrictionsChange = () => {
    setUseLocationRestrictions(prevState => !prevState);
    setNotificationSettings(prevState => {
      return {
        ...prevState,
        useLocationRestrictions: !prevState.useLocationRestrictions
      }
    });
  };

  const useLocationRestrictionsToolTip = 'If checked, only users who have access to the location associated with the ' +
    'notification will receive the notification where applicable. User location associations can be set up in the ' +
    'Users section of your account.';

  return <>
    <TableContainer>
      <Table>
        <TableHead>
          <TableCell>Notifications Category</TableCell>
          <TableCell>Account Owner</TableCell>
          {isBuyerAccount(account) ?
            <BuyerTableCells />
            : gmsPro ?
            <PartnerTableCells />
            : ""
          }
          <TableCell>Administrator</TableCell>
        </TableHead>
        <TableBody>
          {isBuyerAccount(account) ?
            <>
              <BuyerAdministrativeTableRow
                administrativeAccountOwnerRole={administrativeAccountOwnerRole}
                handleAdministrativeAccountOwnerRoleChange={handleAdministrativeAccountOwnerRoleChange}
                administrativeDispatcherRole={administrativeDispatcherRole}
                handleAdministrativeDispatcherRoleChange={handleAdministrativeDispatcherRoleChange}
                administrativeAdminRole={administrativeAdminRole}
                handleAdministrativeAdminRoleChange={handleAdministrativeAdminRoleChange}
              />
              <FleetManagementTableRow
                fleetManagementAccountOwnerRole={fleetManagementAccountOwnerRole}
                handleFleetManagementAccountOwnerRoleChange={handleFleetManagementAccountOwnerRoleChange}
                fleetManagementDispatcherRole={fleetManagementDispatcherRole}
                handleFleetManagementDispatcherRoleChange={handleFleetManagementDispatcherRoleChange}
                fleetManagementAdminRole={fleetManagementAdminRole}
                handleFleetManagementAdminRoleChange={handleFleetManagementAdminRoleChange}
              />
            </>
            :
            <>
              <SupplierAdministrativeTableRow
                administrativeAccountOwnerRole={administrativeAccountOwnerRole}
                handleAdministrativeAccountOwnerRoleChange={handleAdministrativeAccountOwnerRoleChange}
                gateClerkRole={administrativeGateClerkRole}
                handleAdministrativeGateClerkRoleChange={handleAdministrativeGateClerkRoleChange}
                administrativeGateManagerRole={administrativeGateManagerRole}
                handleAdministrativeGateManagerRoleChange={handleAdministrativeGateManagerRoleChange}
                administrativeAdminRole={administrativeAdminRole}
                handleAdministrativeAdminRoleChange={handleAdministrativeAdminRoleChange}
                gmsPro={gmsPro}
              />
              <PartnerYardManagementTableRow
                partnerYardManagementGateClerkRole={partnerYardManagementGateClerkRole}
                handlePartnerYardManagementGateClerkRoleChange={handlePartnerYardManagementGateClerkRoleChange}
                partnerYardManagementAccountOwnerRole={partnerYardManagementAccountOwnerRole}
                handlePartnerYardManagementAccountOwnerRoleChange={handlePartnerYardManagementAccountOwnerRoleChange}
                partnerYardManagementGateManagerRole={partnerYardManagementGateManagerRole}
                handlePartnerYardManagementGateManagerRoleChange={handlePartnerYardManagementGateManagerRoleChange}
                partnerYardManagementAdminRole={partnerYardManagementAdminRole}
                handlePartnerYardManagementAdminRoleChange={handlePartnerYardManagementAdminRoleChange}
                gmsPro={gmsPro}
              />
              <TableRow>
                <Box display="flex" alignItems="center" marginTop={'2em'}>
                  {'Use Location Restrictions?'}
                  <CustomTooltip title={useLocationRestrictionsToolTip} />
                  <Checkbox checked={useLocationRestrictions} onClick={handleUseLocationRestrictionsChange}/>
                </Box>
              </TableRow>
            </>
          }
        </TableBody>
      </Table>
    </TableContainer>
  </>
}

export default NotificationsTable;
