import { formatMoneyAddDecimalPoint } from "./PaymentUtils";
import { isSupplierAccount } from "./AccountUtils";

export const sendSignUpEvent = account => {
  window.dataLayer = window.dataLayer || [];
  if (isSupplierAccount(account)) {
    window.dataLayer.push({
      event: 'partnerSignUp'
    });
  } else {
    window.dataLayer.push({
      event: 'customerSignUp'
    });
  }
}

export const sendPurchaseEvent = booking => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'bookingPurchase',
    purchase: {
      value: formatMoneyAddDecimalPoint(booking.initialCharge),
      transaction_id: booking.orderNumber,
      number_of_spaces: booking.numberOfSpaces,
    }
  });

}
