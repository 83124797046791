import React from 'react';
import {Grid} from "@mui/material";
import {SortFilter} from "@securspace/securspace-ui-kit";
import {withSnackbar} from "../../hocs/withSnackbar";
import useAllLocationNames from "../../../hooks/components/location/useAllLocationNames";

const SpaceUsageFilterForm = (props: {
  filterByOptions?: {name: string, label: string, value: string}[],
  onChange: (event) => void,
  defaultStartDate: string,
  defaultEndDate: string,
  snackbarShowMessage: () => void
}) => {
  const {filterByOptions = [], onChange = () => {}, defaultStartDate, defaultEndDate, snackbarShowMessage = () => {}} = props;

  const locations = useAllLocationNames(snackbarShowMessage);

  const sortByOptions = [
    {value: 'calendarDate', label: 'Date'},
    {value: 'totalSpacesBooked', label: 'Booked Spaces'},
    {value: 'maxInventoryCount', label: 'Max Inventory'},
    {value: 'overageSpacesChargedCount', label: 'Charged Overages'},
    {value: 'buyerCompanyName', label: 'Buyer'},
    {value: 'locationName', label: 'Location'},
    {value: 'bookingNumbers', label: 'Bookings'},
    {value: 'overageInvoiceNumbers', label: 'Overage Invoices'},
  ];

  return (
    <Grid mb={2} item container direction={'column'} component={'section'}>
    <SortFilter
      filterCallback={onChange}
      sortByOption={sortByOptions}
      locations={locations}
      filterByOption={filterByOptions}
      defaultStartDate={defaultStartDate}
      defaultEndDate={defaultEndDate}
      defaultSortBy={'calendarDate'}
      sortDir={'asc'}
    />
    </Grid>
  );
};

export default withSnackbar(SpaceUsageFilterForm);
