import React, { useState, useEffect } from 'react'
import { Typography, Grid } from '@mui/material'
import { Pagination, InfoCardLoader } from '@securspace/securspace-ui-kit'
import moment from 'moment'
import SecurSpaceContainer from '../../components/common/SecurSpaceContainer'
import {
    SupplierInvoicesContent,
    SupplierInvoicesFilter
} from '../../components/invoices/supplier-invoice'
import { getServiceFeeDisplayValue } from '../../components/invoices/serviceFeeHelper.js';
import {
    getSupplierInvoices
} from '../../components/invoices/supplier-invoice/requests/supplier-invoices-requests'
import { DateFormats, BookingTransactionTypeLabel } from '../../components/constants/securspace-constants'
import { getErrorMessageForStandardResponse } from '../../util/NetworkErrorUtil'
import { withSnackbar } from '../../components/hocs/withSnackbar'
import { formatCurrencyValue } from '../../util/PaymentUtils'
import getDisplayValue from '../../components/SupplierTransactionStatus'
import { styles } from '../../components/invoices/styles';
import { SupplierBookingTitle } from '../../components/supplier-booking'
import { expandAllItems, toggleItem } from './BuyerInvoicesReport'
import useUrlQueryParams from "../../hooks/useUrlQueryParams";
import { formatDataForCSVDownload } from '../../components/invoices/supplier-invoice/data'

const SupplierInvoicesReport = ({ account, snackbarShowMessage }) => {
    const { invoiceNumber: urlQueryInvoiceNumber, startDate: urlQueryStartDate } = useUrlQueryParams();

    const [selectedLocation, setSelectedLocation] = useState();
    const [invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(50);
    const [totalNumberResults, setTotalNumberResults] = useState(0);
    const [startDate, setStartDate] = useState(
      moment(urlQueryStartDate).isValid ? moment(urlQueryStartDate).format(DateFormats.DAY) :
      moment().add(-1, 'months').format(DateFormats.DAY)
    );
    const [endDate, setEndDate] = useState(moment().format(DateFormats.DAY));
    const [bookingNumber, setBookingNumber] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState(urlQueryInvoiceNumber || '');
    const [sortDir, setSortDir] = useState('')
    const [invoiceType, setInvoiceType] = useState('');
    const [status, setStatus] = useState('');
    const [sortBy, setSortBy] = useState('');
    const currentDate = new Date();
    const [expandedItems, setExpandedItems] = useState({});
    const [expandedAll, setExpandedAll] = useState(false);
    const formattedMonth = String(currentDate.getMonth() + 1).padStart(2, '0');
    const formattedDate = `${formattedMonth}-${String(currentDate.getDate()).padStart(2, '0')}-${currentDate.getFullYear()}`;
    const formattedTime = `${String(currentDate.getHours()).padStart(2, '0')}_${String(currentDate.getMinutes()).padStart(2, '0')}`;

    const reportName = `${selectedLocation ? selectedLocation.locationName : 'All'}_Invoices_${formattedDate}_${formattedTime}`;

    const handlePageChange = (_, selectedPage) => {
        setPage(selectedPage)
    }

    const handleSizeChange = (e) => {
        setSize(e.target.value)
    }

    
const handleToggle = (id) => toggleItem(id, setExpandedItems);
const handleExpandAll = () => expandAllItems(invoices, 'id', setExpandedItems, expandedAll, setExpandedAll);

    useEffect(() => {
        const { id } = account;
        const checkLength = (number) => number?.length >= 3 ? number : "";

        if (id) {
            setLoading(true)
            const requestParams = {
                accountId: id,
                page,
                size,
                startDate: startDate,
                endDate: endDate,
                sortBy,
                locationId: selectedLocation?.id,
                bookingNumber: checkLength(bookingNumber),
                invoiceNumber,
                transactionType: invoiceType,
                status,
                sortDir
            }

            getSupplierInvoices(requestParams)
                .then(({ body }) => {
                    const { content, count } = body;
                    const data = content?.map((item) => {
                        return {
                            ...item,
                            supplierAmount: formatCurrencyValue(item.supplierAmount),
                            transactionType: BookingTransactionTypeLabel[item?.transactionType] || item?.transactionType,
                            status: getDisplayValue(item?.status),
                            id: item?.transactionId,
                            serviceFeeType: getServiceFeeDisplayValue(item?.serviceFeeType),
                        }
                    });
                    setTotalNumberResults(count);
                    setInvoices(data);
                    setLoading(false);
                    setExpandedItems({});
                })
                .catch(err => {
                    setLoading(false)
                    snackbarShowMessage(getErrorMessageForStandardResponse(err), "error", 15000)
                });
        }

    }, [account, page, size, startDate, endDate, selectedLocation, bookingNumber, invoiceNumber, invoiceType, status, sortBy, sortDir]);

    return (
        <SecurSpaceContainer styles={styles.appContainer}>
            <Typography variant='h5' component='h1' mb={4}>Invoices</Typography>
            <SupplierInvoicesFilter
                setSelectedLocation={setSelectedLocation}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setBookingNumber={setBookingNumber}
                setInvoiceNumber={setInvoiceNumber}
                setInvoiceType={setInvoiceType}
                setStatus={setStatus}
                setSortBy={setSortBy}
                setSortDir={setSortDir}
            />
            {
                invoices.length > 0 && 
                <SupplierBookingTitle
                title={selectedLocation?.locationName || 'All'}
                count={invoices?.length}
                data={invoices}
                expandedAll={expandedAll}
                setExpandedItems={setExpandedItems}
                setExpandedAll={setExpandedAll}
                isShowCollapseButton={true}
                handleExpandAll={handleExpandAll}
                reportName={reportName}
                expandedItems={expandedItems}
                reportFormatter={formatDataForCSVDownload}
            />
            }
            {
                loading ? [...Array(10).keys()].map((_, index) => (
                    <InfoCardLoader key={index} />
                )) : <SupplierInvoicesContent
                    loading={loading}
                    invoices={invoices}
                    locationId={selectedLocation?.id || ''}
                    invoiceNumber={invoiceNumber}
                    bookingNumber={bookingNumber}
                    invoiceType={invoiceType}
                    sortBy={sortBy}
                    handleToggle={handleToggle}
                    expandedItems={expandedItems}
                />
            }
            {
                invoices.length > 0 && <Grid item>
                    <Pagination
                        count={totalNumberResults}
                        rowsPerPage={size}
                        page={page}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleSizeChange}
                        variant="tablePagination"
                    />
                </Grid>
            }
        </SecurSpaceContainer>
    )
}

export default withSnackbar(SupplierInvoicesReport)
