import React from 'react';
import PropTypes from "prop-types";
import type {Account} from "../../types/Account";
import PartnerPaymentMethods from "./PartnerPaymentMethods";
import BuyerPaymentMethods from "./BuyerPaymentMethods";
import { isSupplierAccount } from "../../util/AccountUtils";

const PaymentMethods = (props: { account: Account, handleLogout: () => void, handleAccountUpdated: () => void}) => {
  const { account, handleLogout, handleAccountUpdated } = props;

  return (
    isSupplierAccount(account) ?
      <PartnerPaymentMethods account={account} handleLogout={handleLogout} handleAccountUpdated={handleAccountUpdated}/>
      : <BuyerPaymentMethods account={account} handleLogout={handleLogout}/>
  );
};

PaymentMethods.propTypes = {
  account: PropTypes.object.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

export default PaymentMethods;
