import React, {memo, useState} from 'react';
import {makeStyles} from "@mui/styles";
import {Box} from "@mui/material";
import PaymentMethodModal from './modals/PaymentMethodModal.js';
import ReviewPaymentModal from './modals/ReviewPaymentModal.js';
import CompleteBookingModal from './modals/CompleteBookingModal.js';
import CancelBookingModal from './modals/CancelBookingModal.js';
import { withSnackbar } from '../hocs/withSnackbar.js';
import { getErrorMessageForStandardResponse } from '../../util/NetworkErrorUtil.js';
import { getAgreementUrl } from '../../util/BookingUtil.js';
import { updatePaymentMethodForBooking, cancelBooking, completeBooking } from '../../views/requests/booking-requests.js';
import TextBox from './TextBox.js';
import { Theme } from "@mui/material";
import Busy from "../Busy";
import TermsAndConditionsModal from "./modals/TermsAndConditionsModal";
import BookingCardList from './BookingCardList.js';

const useStyles: (theme: Theme) => {
    radio: CSSStyleSheet,
    topMargin: CSSStyleSheet,
    mb: CSSStyleSheet
} = makeStyles(() => ({
    radio: {
        marginLeft: "1rem"
    },
    topMargin: {
        marginTop: "1rem"
    },
    mb: {
        marginBottom: '1rem'
    }
}));

const PendingBookings = ({bookings = [], account, onActionSuccess, snackbarShowMessage, headerCells}) => {

    const modalTypes = {complete: "COMPLETE", cancel: "CANCEL", payment: "PAYMENT", review: "REVIEW", termsOnly: "TERMS_ONLY"};
    const [bookingBeingActioned, setBookingBeingActioned] = useState({});
    const [showModal, setShowModal] = useState({ show: false, modal: modalTypes.payment });
    const [completeBookingSuccess, setCompleteBookingSuccess] = useState(false);
    const [calculateCostObject, setCalculateCostObject] = useState({});
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
    const classes = useStyles();


    const setError = (err) => {
        snackbarShowMessage(getErrorMessageForStandardResponse(err), "error", 1500)
    }

    const handleShowCancelModal = (booking) => {
        setBookingBeingActioned(booking)
        setShowModal({ show: true, modal: modalTypes.cancel })
    }

    const shouldShowTermsOnlyModal = (booking) => {
        return booking.manualPayment ||
          (booking.brokeredBuyerChargedPerOccurrence === 0 &&
          booking.brokeredSupplierPaidPerOccurrence === 0 &&
          booking.chargeOverages === false);
    }

    const handleShowPaymentModal = (booking) => {
        setBookingBeingActioned(booking);
        if (shouldShowTermsOnlyModal(booking)) {
            setShowModal({ show: true, modal: modalTypes.termsOnly })
        } else {
            setShowModal({ show: true, modal: modalTypes.payment });
        }
    }

    const handleShowReviewModal = () => {
        setShowModal({ show: true, modal: modalTypes.review });
    }

    const handleShowCompleteModal = () => {
        setShowModal({ show: true, modal: modalTypes.complete })
    }

    const handleCloseModals = () => {
        setShowModal({ ...showModal, show: false });
    }

    const sendComplete = () => {
        Busy.set(true);
        completeBooking(bookingBeingActioned)
          .then(() => {
            handleShowCompleteModal();
            setCompleteBookingSuccess(true)
        }).catch(setError)
          .finally(() => Busy.set(false));
    }

    const requestCompleteBooking = async () => {
        Busy.set(true);
        const data = {
            id: bookingBeingActioned.id,
            buyerAccountId: account.id,
            tosDocUrl: getAgreementUrl(),
            paymentMethodId: selectedPaymentMethod ? selectedPaymentMethod.id : ''
        };

        try {
            await updatePaymentMethodForBooking(data);
            await completeBooking(bookingBeingActioned);
            handleShowCompleteModal();
            setCompleteBookingSuccess(true);
        }
        catch(err) {
            setError(err);
        }

        Busy.set(false);
    }

    const handleCancelBooking = () => {
        const cancelObject = { id: bookingBeingActioned.id }
        cancelBooking(cancelObject).then(res => {
            setShowModal({ ...showModal, show: false });
            onActionSuccess();
            snackbarShowMessage(`Successfully cancelled booking ${res.body.orderNumber}`, "success", 1500);
        }).catch(setError);
    }

    const finishCompleteBooking = () => {
        handleCloseModals();
        onActionSuccess();
        setCompleteBookingSuccess(false);
        setBookingBeingActioned({});
    }

    return (
        <Box>
            <TextBox title="A booking request that is awaiting supplier approval or buyer to complete the booking." />
            <BookingCardList bookings={bookings} onShowPaymentModal={handleShowPaymentModal} onShowCancelModal={handleShowCancelModal} headerCells={headerCells} rootStyle={classes.mb} />

            {
                showModal.modal === modalTypes.payment ?
                    <PaymentMethodModal booking={bookingBeingActioned} account={account} open={showModal.show} handleNext={handleShowReviewModal} getAgreementUrl={getAgreementUrl} setCalculateCostObject={setCalculateCostObject} selectedPaymentMethod={selectedPaymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod} onClose={handleCloseModals} />
                : showModal.modal === modalTypes.review ?
                    <ReviewPaymentModal open={showModal.show} calculateCostObject={calculateCostObject} completeBooking={requestCompleteBooking} bookingToComplete={bookingBeingActioned} onClose={handleCloseModals} classes={classes} />
                : (showModal.modal === modalTypes.complete) && completeBookingSuccess ?
                    <CompleteBookingModal open={showModal.show} done={finishCompleteBooking} classes={classes} />
                : showModal.modal === modalTypes.cancel ?
                    <CancelBookingModal open={showModal.show} onClose={handleCloseModals} cancelBooking={handleCancelBooking} booking={bookingBeingActioned} classes={classes} />
                : showModal.modal === modalTypes.termsOnly ?
                    <TermsAndConditionsModal open={showModal.show} onClose={handleCloseModals} classes={classes} completeBooking={sendComplete}/>
                : ""
            }
        </Box>
    );
};

export default withSnackbar(memo(PendingBookings));
