import { verbiage } from "./payouts-report-verbiage";

const headerCells = [
  { id: 'payoutAmount', label: verbiage.fieldAmount },
  { id: 'locationName', label: verbiage.fieldLocation },
  { id: 'createdOn', label: verbiage.fieldPayoutInitiated },
  { id: 'completedOn', label: verbiage.fieldPayoutCompleted },
  { id: 'status', label: verbiage.fieldStatus }
];



const groupSummaryFields = [
  {
    title: verbiage.summaryTotalPayouts,
    value: 0,
  },
  {
    title: verbiage.summaryPayoutsCount,
    value: 0,
  },
  {
    title: verbiage.summaryTransactionCount,
    value: 0,
  },
];

export {
    headerCells,
    groupSummaryFields
}