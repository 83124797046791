import React, {useEffect, useState} from 'react';
import ReportBackgroundContainer from "../../report/ReportBackgroundContainer";
import ReportHeader from "../../report/ReportHeader";
import moment from "moment";
import {DateFormats} from "../../constants/securspace-constants";
import ReportFormContainer from "../../report/ReportFormContainer";
import ReportFormDateInput from "../../report/ReportFormDateInput";
import Filter from "../../report/Filter";
import {InvoicesItemsWithGroupAndSort} from "./InvoicesContent";
import {searchRecordsByKeyword} from "../../../util/SearchUtils";
import {
  requestAdminInvoices,
  requestCompleteRefund,
  requestDenyRefund,
  requestPutPayoutOnHold,
  requestRetryPayment,
  requestTakePayoutOffHold,
  requestToggleIncludeRefundInPayout
} from "../request/invoice-requests";
import {toast} from "react-toastify";
import Busy from "../../Busy";

const AdminInvoicesReport = (props) => {

  const {account} = props;

  const reportFields = [
    {label: "None", name: ""},
    {label: "Service Dates", name: "serviceDates"},
    {label: "Customer Amount", name: "buyerAmount"},
    {label: "Payment Processor Amount", name: "paymentProcessorFees"},
    {label: "Partner Amount", name: "supplierAmount"},
    {label: "Booking State", name: "locationState"},
    {label: "SecurSpace Amount", name: "securspaceFees"},
    {label: "Charge Type", name: "transactionType"},
    {label: "Created On", name: "createdOn"},
    {label: "Payment Type", name: "paymentType"},
    {label: "Payment Initiated", name: "paymentCreatedOn"},
    {label: "Process Completed", name: "paymentCompletedOn"},
    {label: "Payout Initiated", name: "payoutCreatedOn"},
    {label: "Payout Completed", name: "payoutCompletedOn"},
    {label: "Customer", name: "buyerCompanyName"},
    {label: "Location", name: "locationName"},
    {label: "Partner", name: "supplierCompanyName"},
    {label: "Booking", name: "bookingNumber"},
    {label: "Spaces", name: "numberOfSpaces"},
    {label: "Status", name: "status"},
    {label: "Contributes to Revenue", name: "contributesToRevenue"},
    {label: "Payout On Hold", name: "supplierPayoutOnHold"},
    {label: "Include Refund In Payout", name: "includeRefundInPayout"},
    {label: "Booking Create On", name: "bookingCreatedOn"},
    {label: "Booking Created By", name: "bookingCreatedBy"},
    {label: "Refund Requested By", name: "refundRequestedBy"},
    {label: "Refund Reason", name: "reasonForRefund"},
    {label: "Recoupable", name: "recoupable"},
    {label: "Recouped", name: "recouped"},
    {label: "Equipment Type", name: "assetType"},
    {label: "Supplier Payout Dwolla Transfer ID", name: "supplierPayoutDwollaTransferId"},
    {label: "SecurSpace Fee Dwolla Transfer ID", name: "securspaceFeeDwollaTransferId"},
  ];

  const defaultStartDate = moment().subtract(1, 'months').format(DateFormats.DAY);
  const defaultEndDate = moment().format(DateFormats.DAY);

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [searchBox, setSearchBox] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [addRefundCreditTransaction, setAddRefundCreditTransaction] = useState(false);
  const [addRefundTransaction, setAddRefundTransaction] = useState(false);

  const showAddRefundCreditView = () => {
    setAddRefundCreditTransaction(prevState => !prevState);
  };

  const showAddRefundTransactionView = () => {
    setAddRefundTransaction(prevState => !prevState);
  };

  const closeAllDialogs = () => {
    setAddRefundCreditTransaction(false);
    setAddRefundTransaction(false);
  };

  useEffect(() => {
    const adminInvoices = requestAdminInvoices(startDate, endDate);

    Busy.set(true);
    adminInvoices
      .then((response) => {
        setInvoices(response.body);
      }, (error) => {
        toast.error("Failed to load Transactions:  " + error);
      })
      .catch((error) => {
        toast.error("Failed to load Transactions:  " + error);
      })
      .finally(() => Busy.set(false));
  }, [startDate, endDate]);

  useEffect(() => {
    if (invoices && searchBox) {
      setFilteredData(searchRecordsByKeyword(searchBox, invoices));
    } else {
      setFilteredData(invoices);
    }
  }, [invoices, searchBox]);

  const searchChangeHandler = (event) => {
    let value = event.target.value;
    setSearchBox(value);
  };

  const handleStartDate = (date) => {
    setStartDate(date);
  }

  const handleEndDate = (date) => {
    setEndDate(date);
  }

  const viewInvoice = (item) => {
    window.open(account.baseUrl + "/invoice/" + item.transactionNumber);
  };

  const viewInventoryLog = (item) => {
    window.open(account.baseUrl + '/api/overage-daily-report-by-invoice-number/' + item.transactionNumber);
  };

  const handleCompleteRefund = (item, deductFromNextPayout) => {
    Busy.set(true);
    requestCompleteRefund(item, deductFromNextPayout)
      .then(() => {
        toast.success("Successfully completed refund!");
      }, (error) => {
        toast.error("Failed to complete refund:  " + error);
      })
      .catch((error) => {
        toast.error("Failed to complete refund:  " + error);
      })
      .finally(() => Busy.set(false));
  }

  const handleDenyRefund = (item) => {
    Busy.set(true);
    requestDenyRefund(item)
      .then(() => {
        toast.success("Successfully denied refund!");
      }, (error) => {
        toast.error("Failed to deny refund:  " + error);
      })
      .catch((error) => {
        toast.error("Failed to deny refund:  " + error);
      })
      .finally(() => Busy.set(false));
  }

  const handleRetryPayment = (item) => {
    Busy.set(true);
    requestRetryPayment(item)
      .then(() => {
        toast.success("Successfully retried charging failed payment!");
      }, (error) => {
        toast.error("Failed to retry payment:  " + error);
      })
      .catch((error) => {
        toast.error("Failed to retry payment:  " + error);
      })
      .finally(() => Busy.set(false));
  }

  const handleIncludeRefundInPayout = (item) => {
    Busy.set(true);
    const newIncludeValue = !item.includeRefundInPayout;
    requestToggleIncludeRefundInPayout(item)
      .then(() => {
        let newBookingTransactions = [...invoices];
        let foundIndex = newBookingTransactions.findIndex(x => x.transactionId === item.transactionId);
        newBookingTransactions[foundIndex].includeRefundInPayout = newIncludeValue;
        setInvoices(newBookingTransactions);
        if (newIncludeValue) {
          toast.success("Successfully included this invoice in the next payout");
        } else {
          toast.success("Successfully excluded this invoice from the next payout");
        }
      }, (error) => {
        toast.error("Failed to update the invoice:  " + error);
      })
      .catch((error) => {
        toast.error("Failed to update the invoice:  " + error);
      })
      .finally(() => Busy.set(false));
  }

  const handlePutPayoutOnHold = (item) => {
    Busy.set(true);
    requestPutPayoutOnHold(item)
      .then(() => {
        toast.success("Successfully put payout on hold!");
      }, (error) => {
        toast.error("Failed to put payout on hold:  " + error);
      })
      .catch((error) => {
        toast.error("Failed to put payout on hold:  " + error);
      })
      .finally(() => Busy.set(false));
  }

  const handleTakePayoutOffHold = (item) => {
    Busy.set(true);
    requestTakePayoutOffHold(item)
      .then(() => {
        toast.success("Successfully took payout off hold!");
      }, (error) => {
        toast.error("Failed to take payout off hold:  " + error);
      })
      .catch((error) => {
        toast.error("Failed to take payout off hold:  " + error);
      })
      .finally(() => Busy.set(false));
  }

  const addRefundCreditCompleted = (bookingTransaction) => {
    let bookingTransactions = invoices;
    let foundIndex = bookingTransactions.findIndex(x => x.id === bookingTransaction.id);
    bookingTransactions[foundIndex] = bookingTransaction;
    setInvoices(bookingTransactions);
    closeAllDialogs();
    toast.success('Successfully added refund credit to invoice!');
  };

  const addRefundTransactionCompleted = (refundBookingTransaction) => {
    let refundRequested = 'REFUND_REQUESTED' === refundBookingTransaction.status;
    closeAllDialogs();
    if (refundRequested) {
      toast.success('Successfully submitted refund request!  Refund Invoice:  ' + refundBookingTransaction.transactionNumber);
    } else {
      toast.success('Successfully created refund!  Refund Invoice:  ' + refundBookingTransaction.transactionNumber);
    }
  };

  return <ReportBackgroundContainer>
    <ReportHeader>Invoices</ReportHeader>
    <ReportFormContainer>
      <ReportFormDateInput label={"START DATE:"} date={startDate} onDateChange={handleStartDate}/>
      <ReportFormDateInput label={"END DATE:"} date={endDate} onDateChange={handleEndDate}/>
    </ReportFormContainer>
    <Filter
      searchBox={searchBox}
      searchChangeHandler={searchChangeHandler}
    />
    <InvoicesItemsWithGroupAndSort
      account={account}
      reportFields={reportFields}
      viewInvoice={viewInvoice}
      viewInventoryLog={viewInventoryLog}
      addRefundCreditCompleted={addRefundCreditCompleted}
      handleCompleteRefund={handleCompleteRefund}
      handleDenyRefund={handleDenyRefund}
      handleRetryPayment={handleRetryPayment}
      handleIncludeRefundInPayout={handleIncludeRefundInPayout}
      handlePutPayoutOnHold={handlePutPayoutOnHold}
      handleTakePayoutOffHold={handleTakePayoutOffHold}
      addRefundTransactionCompleted={addRefundTransactionCompleted}
      addRefundCreditTransaction={addRefundCreditTransaction}
      addRefundTransaction={addRefundTransaction}
      showAddRefundCreditView={showAddRefundCreditView}
      showAddRefundTransactionView={showAddRefundTransactionView}
      closeAllDialogs={closeAllDialogs}
      invoices={filteredData}
    />
  </ReportBackgroundContainer>
}

export default AdminInvoicesReport;
