import React, { useContext } from 'react'
import ManageUserItem from "./ManageUserItem";
import { AppContext } from "../../context/app-context";
import classNames from 'classnames'
import PropTypes from 'prop-types'
import _ from 'underscore'

import './style/manage-existing-users.css'
import { isSupplierAccount } from "../../util/AccountUtils";

const ManageExistingUsers = (props) => {
  const { users, locationOptions, onRemoveUser, onUpdateLocations } = props

  const { user } = useContext(AppContext)

  return (
    <div className="report-container">
      {
        users && users.length === 0 ?
          <h4>There are currently no users for this account.
            Use the button to the left to add your first one.</h4>
          :
          <div className={classNames(
            { "manage-existing-users-container-supplier" : isSupplierAccount(user) })}>

            <div className="manage-users-header">
              <div className="manage-users-header-item ss-existing-username">USERNAME</div>
              <div className="manage-users-header-item ss-existing-name">NAME</div>
              <div className="manage-users-header-item ss-existing-role">ROLE</div>
              <div className="manage-users-header-item ss-existing-phone-number">PHONE NUMBER</div>
            </div>

            <div className="existing-users-container">
              {
                _.map(users, (user, idx) => {
                  return (
                    <ManageUserItem
                      key={idx}
                      user={user}
                      locationOptions={locationOptions}
                      onRemoveUser={onRemoveUser}
                      onUpdateLocations={onUpdateLocations}
                    />
                  )
                })
              }
            </div>
          </div>
      }
    </div>
  )
}

ManageExistingUsers.propTypes = {
  users: PropTypes.array.isRequired,
  locationOptions: PropTypes.array.isRequired,
  onRemoveUser: PropTypes.func.isRequired,
  onUpdateLocations: PropTypes.func.isRequired
}

export default ManageExistingUsers;
