import * as React from 'react';
import {default as ReactCurrencyInput} from 'react-currency-input-field';

const CurrencyInput = ({
  ...inputProps
  }) => {

  return (
    <React.Fragment>
      <ReactCurrencyInput
        {...inputProps}
      />
    </React.Fragment>
  );
}
export default CurrencyInput;